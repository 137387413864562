import AuthCSS from '../../features/auth/Auth.module.css'
import {useEffect} from 'react'

const DisplayTimes = ({times}) => {

    return (
        <div className={`${AuthCSS['step-six']} ${AuthCSS['input-fields']} ${AuthCSS['edit-meetings']}`}>
            <div className={AuthCSS['schedule-meeting']}>
                <ul className={`${AuthCSS['hours-values']} ${AuthCSS['days-list-non-editable']}`}>
                    {Object.keys(times).map((day) => (
                        (times[day] && times[day].opening && times[day].closing) ? (
                            <li key={day}>
                                <p>{day}</p>
                                <div>
                                    <span>{times[day].opening}</span>
                                    <span>-</span>
                                    <span>{times[day].closing}</span>
                                </div>
                            </li>
                        ) : null
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default DisplayTimes