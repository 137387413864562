import Modal from 'react-modal'
import UserStoriesCSS from './UserStories.module.css'
import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'

Modal.setAppElement('#root')

const LoginRedirection = ({redirectOpened, closeModal}) => {

    useEffect(() => {
        console.log(redirectOpened)
    }, [redirectOpened])

    const navigate = useNavigate()

    return(
        <Modal
            isOpen={redirectOpened}
            onRequestClose={closeModal}
            className={
                {
                    base: `modal-content ${UserStoriesCSS['please-login']}`,
                    afterOpen: 'modal-content--after-open',
                    beforeClose: 'modal-content--before-close'
                }
            }
            overlayClassName={
                {
                    base: 'modal-overlay',
                    afterOpen: 'modal-overlay--after-open',
                    beforeClose: 'modal-overlay--before-close'
                }
            }
            closeTimeoutMS={300}
        >
            <div className='modal-close'>
                <button
                    onClick={closeModal}
                    className='close-button'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path
                            d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/>
                    </svg>
                </button>
            </div>
            <h4>Please log in to be able to interact.</h4>
            <div className={`${UserStoriesCSS['confirmation-buttons']} ${UserStoriesCSS['login-button']}`}>
                <button onClick={() => {
                    closeModal()
                    navigate('/dash/login')
                }}>Log in</button>
            </div>
        </Modal>
    )
}

export default LoginRedirection