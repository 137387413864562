import {Link} from 'react-router-dom'
import Person3Icon from '@mui/icons-material/Person3'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'

const DashNavManager = () => {
    return (
        <header className='dash-nav'>
            <nav className='dash-nav__container'>
                <Link to='/dash/girl-list'>
                    <Person3Icon fontSize="large"/>
                </Link>
                <Link to='/dash/stories'>
                    <FmdGoodIcon fontSize="large"/>
                </Link>
                <Link to='/dash/statistics'>
                    <EqualizerIcon fontSize="large"/>
                </Link>
                <Link to='/dash/ads'>
                    <ScreenshotMonitorIcon fontSize="large"/>
                </Link>
            </nav>
        </header>
    )
}

export default DashNavManager