import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import EmailVerifyCSS from './EmailVerify.module.css'
import CheckIcon from '@mui/icons-material/Check'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
    useCreateTokenMutation, useInvalidateTokenMutation
} from '../../features/managingRequestToken/managingRequestTokenApiSlice'

const ManagingRequest = () => {

    const [createManagingToken] = useCreateTokenMutation()
    const [invalidateManagingToken] = useInvalidateTokenMutation()

    const { email,clubName,address, accepted } = useParams()

    useEffect(() => {
        const createToken = async () => {
            try {
                await createManagingToken({email,clubName,address})
            }
            catch (err) {
                console.error('Failed to send response:', err)
            }
        }

        const invalidateToken = async () => {
            try {
                await invalidateManagingToken(email)
            }
            catch (err) {
                console.error('Failed to send response:', err)
            }
        }

        if(accepted === 'accept') createToken()
        else if(accepted === 'decline') invalidateToken()
    }, [email])

    return(
        <div className={EmailVerifyCSS['verify-container']}>
            <span className={EmailVerifyCSS['icon-span']}><EmailOutlinedIcon /></span>
            {accepted === 'accept' ?
                <>
                    <h3>You've approved managing account request for <i style={{ color: 'gray', fontSize: '15px' }}>{email}</i></h3>
                    <CheckIcon className='icon green-icon' style={{ backgroundColor: 'limegreen' }}/>
                </>
                :
                <>
                    <h3>You've dismissed managing account request for <i>{email}</i>!</h3>
                    <CloseOutlinedIcon className='icon red-icon' style={{ backgroundColor: 'red' }} />
                </>
            }
        </div>
    )
}

export default ManagingRequest