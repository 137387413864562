import EmailVerifyCSS from './EmailVerify.module.css'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useCheckTokenMutation } from '../../features/user/usersApiSlice'
import PulseLoader from "react-spinners/PulseLoader"

const EmailVerify = () => {
    const [checkToken, { isSuccess, isLoading, isError, error }] = useCheckTokenMutation()
    const navigate = useNavigate()
    const { id, token } = useParams()
    const [validUrl, setValidUrl] = useState(false)

    useEffect(() => {
        const verifyToken = async () => {
            await checkToken({ userId: id, emailToken: token })
        }

        verifyToken()
    }, [checkToken, id, token])

    let content

    if(isLoading) {
        content = (
            <div className={EmailVerifyCSS['verify-container']}>
                <div><PulseLoader color={'#F92E85FF'}/></div>
            </div>
        )
    }

    if(isError){
        content = (
            <div className={EmailVerifyCSS['verify-container']}>
                <h3>404 Not Found</h3>
            </div>
        )
    }

    if (isSuccess) {
        content = (
            <div className={EmailVerifyCSS['verify-container']}>
                <span className={EmailVerifyCSS['icon-span']}><FontAwesomeIcon icon={faEnvelope}/></span>
                <h3>Email verified successfully!</h3>
                <button onClick={() => navigate('/dash/login')}>Login</button>
            </div>
        )
    }

    return content
}

export default EmailVerify
