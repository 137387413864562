import ContentSectionCSS from '../Subscription/ContentSection.module.css'
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined'
import GridOnIcon from '@mui/icons-material/GridOn'
import {useEffect, useState} from 'react'
import PremiumPostFeed from "./PremiumPostFeed";
import GridPremiumPost from "./GridPremiumPost";
const PremiumContentSection = ({paramUser,loggedUser}) => {


    const [isFeed, setIsFeed] = useState(false)
    const [isImage, setIsImage] = useState(false)
    const [isVideo, setIsVideo] = useState(false)
    const [posts, setPosts] = useState(paramUser?.premiumPosts)

    useEffect(() => {
        if(paramUser) setPosts(paramUser.premiumPosts)
    }, [paramUser])

    const handleVideoFilter = () => {
        if(!isVideo){
            setIsVideo(true)
            setIsImage(false)
        } else setIsVideo(false)
    }

    const handleImageFilter = () => {
        if(!isImage){
            setIsImage(true)
            setIsVideo(false)
        } else setIsImage(false)
    }

    let content;


        const galleryContent = () => {
            const images = [];
            const totalSlots = 12;
            const postCount = posts?.length || 0;

            for (let i = postCount - 1; i >= 0; i--) {
                images.push(<GridPremiumPost key={postCount - i} loggedUser={loggedUser} postId={posts[i]} isImage={isImage} isVideo={isVideo} />);
            }
            if (postCount < totalSlots) {
                for (let i = 0; i < totalSlots - postCount; i++) {
                    images.push(<div key={`empty-${i}`} className={ContentSectionCSS['empty-slot']} />);
                }
            }

            return images;
        };

        content = (
            <div className={ContentSectionCSS['gallery-container']}>
                <div className={ContentSectionCSS['gallery-header']}>
                    <div>
                        <SmartDisplayOutlinedIcon onClick={handleVideoFilter} className={`${isVideo ? ContentSectionCSS['active'] : ''}`} />
                        <ImageOutlinedIcon onClick={handleImageFilter} className={`${isImage ? ContentSectionCSS['active'] : ''}`} />
                        <div>
                            <span>120 videos</span>
                            <span>230 pictures</span>
                        </div>
                    </div>
                    <div>
                        <SquareOutlinedIcon className={`${isFeed ? ContentSectionCSS['active'] : ''}`}
                                            onClick={() => setIsFeed(true)} />
                        <GridOnIcon className={`${!isFeed ? ContentSectionCSS['active'] : ''}`}
                                    onClick={() => setIsFeed(false)} />
                    </div>
                </div>
                {isFeed ?
                    <PremiumPostFeed posts={posts} isVideo={isVideo} isImage={isImage} loggedUser={loggedUser} />
                    :
                    <div className={ContentSectionCSS['gallery-grid']}>
                        {galleryContent()}
                    </div>
                }
            </div>
        );

    return content;
}

export default PremiumContentSection