import { useEffect, useRef, useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import VerticalStoriesCSS from './VerticalStories.module.css'
import UserStories from '../UserStories/UserStories'
import { useGetUsersQuery } from '../../features/user/usersApiSlice'
import useAuth from '../../hooks/useAuth'
import {useGetAdvertisersQuery} from '../../features/advertiser/advertiserApiSlice'

const VerticalSlider = ({activeIndex, setActiveIndex}) => {

    const { email } = useAuth()

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const {
        data: ads,
        isLoadingAds,
        isSuccessAds,
        isErrorAds,
        errorAds
    } = useGetAdvertisersQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const carouselRef = useRef(null);
    const [transitionTime, setTransitionTime] = useState(0)
    const [isMuted, setIsMuted] = useState(true); // Start with muted by default

    const beforeChange = (index, item) => {
        setActiveIndex(index)
    }

    const interleaveAds = (following, ads) => {
        const combined = [];
        let adIndex = 0;

        for (let i = 0; i < following.length; i++) {
            combined.push(following[i]); // Add the user ID to the combined array
            if ((i + 1) % 2 === 0 && adIndex < ads.length) {
                combined.push(ads[adIndex]); // Add an ad after every second user ID
                adIndex++;
            }
        }

        return combined;
    };

    useEffect(() => {
        if (transitionTime===0){
            setTransitionTime(500)
        }
    }, [transitionTime])

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.selectedItem = activeIndex
        }
    }, [])

    if(isSuccess) {
        const userArray = users? Object.values(users.entities) : []
        const loggedUser = userArray.filter(user => user.email === email)

        let following

        if(!loggedUser[0]?.following?.length) following = ['659bdaf4eb19ce78a32c2779', '65764b36325b4bd94dcac732','659bdb1deb19ce78a32c277c']
        else following =  loggedUser[0].following

        //THIS IS FOR NOW! CHANGE IT!!
        following = userArray
            .filter(user => user.roles && user.roles.includes('provider') && user.email!==email && user?.stories?.length && !user.deactivated)
            .map(user => user._id)

        const combinedItems = interleaveAds(following, ads?.ids || [])

        return (
            <div className={VerticalStoriesCSS['slider-container']}>
                <Carousel
                    selectedItem={activeIndex}
                    onChange={beforeChange}
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                    emulateTouch={true}
                    transitionTime={transitionTime}
                    interval={null}
                    infiniteLoop={true}
                    axis={'vertical'}
                    ref={carouselRef}
                >
                    {combinedItems.map((follower, index) => (
                        <UserStories
                            key={index}
                            follower={follower}
                            loggedUser={loggedUser}
                            isActive={index === activeIndex}
                            isNext={index - 1 === activeIndex || (activeIndex === following?.length - 1)}
                            isPrevious={index + 1 === activeIndex || (index === following?.length - 1 && activeIndex === 0)}
                            isMuted={isMuted}
                            setIsMuted={setIsMuted}
                        />
                    ))}
                </Carousel>
            </div>
        )
    }
    return <p>Loading.....</p>
}

export default VerticalSlider