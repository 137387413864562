import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const premiumPostsAdapter = createEntityAdapter({});

const initialState = premiumPostsAdapter.getInitialState();

export const premiumPostsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPremiumPosts: builder.query({
            query: () => ({
                url: '/premium-posts',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            transformResponse: (responseData) => {
                const loadedContent = responseData.map((content) => {
                    content.id = content._id;
                    return content;
                });
                return premiumPostsAdapter.setAll(initialState, loadedContent);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'PremiumPost', id: 'LIST' },
                        ...result.ids.map((id) => ({ type: 'PremiumPost', id }))
                    ];
                } else return [{ type: 'PremiumPost', id: 'LIST' }];
            }
        }),
        addNewPremiumPost: builder.mutation({
            query: (initialContentData) => ({
                url: '/premium-posts',
                method: 'POST',
                body: {
                    ...initialContentData,
                }
            }),
            invalidatesTags: [
                { type: 'PremiumPost', id: "LIST" }
            ]
        }),
        updatePremiumPost: builder.mutation({
            query: (initialContentData) => ({
                url: `/premium-posts`,
                method: 'PATCH',
                body: {
                    ...initialContentData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'PremiumPost', id: arg.id }
            ]
        }),
        deletePremiumPost: builder.mutation({
            query: ({ id }) => ({
                url: `/premium-posts`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'PremiumPost', id: arg.id }
            ]
        }),
        checkPurchaseStatus: builder.query({
            query: initialContentData => ({
                url: `/premium-posts/purchase/check`, // Replace with your actual endpoint
                method: 'POST',
                body: {
                    ...initialContentData,
                }
            }),
            providesTags: (result, error, arg) => [
                { type: 'PremiumPost', id: arg.premiumPostId }
            ]
        }),
        createPurchase: builder.mutation({
            query: initialContentData => ({
                url: '/premium-posts/purchase',
                method: 'POST',
                body: {
                    ...initialContentData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'PremiumPost', id: arg.premiumPostId },
                { type: 'PremiumPost', id: 'LIST' }
            ]
        }),
    }),
});

export const {
    useGetPremiumPostsQuery,
    useAddNewPremiumPostMutation,
    useUpdatePremiumPostMutation,
    useDeletePremiumPostMutation,
    useCreatePurchaseMutation,
    useCheckPurchaseStatusQuery// New hook for creating a purchase
} = premiumPostsApiSlice;

// returns the query result object
export const selectPremiumPostsResult = premiumPostsApiSlice.endpoints.getPremiumPosts.select()

// creates memoized selector
const selectPremiumPostsData = createSelector(
    selectPremiumPostsResult,
    contentResult => contentResult.data // normalized state object with ids & entities
)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllPremiumPosts,
    selectById: selectPremiumPostById,
    selectIds: selectPremiumPostIds
    // Pass in a selector that returns the private content slice of state
} = premiumPostsAdapter.getSelectors(state => selectPremiumPostsData(state) ?? initialState)
