import ContentSectionCSS from './ContentSection.module.css'
import Navigation from './Navigation'

const ContentSection = ({ loggedUser }) => {
    return(
        <div className={ContentSectionCSS['content-section_container']}>
            <Navigation loggedUser={loggedUser}/>
        </div>
    )
}

export default ContentSection