import React, { useState, useEffect } from 'react';
import ManagerSettingsCSS from './ManagerSettings.module.css';
import {
    useCheckUserManagerQuery,
    useUpdatePermissionMutation,
    useUnfollowUserMutation
} from '../../features/managerFollowingUser/managerFollowingUserApiSlice';

const ManagerSettings = ({ loggedUser }) => {
    const [isInviteAccepted, setIsInviteAccepted] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState('');
    const [connectedManager, setConnectedManager] = useState('');
    const [managerId, setManagerId] = useState(null);
    const [inviteSent, setInviteSent] = useState(false);
    const [managerActivity, setManagerActivity] = useState([]);

    const { data: managerRelationship, error: followedError, isLoading } = useCheckUserManagerQuery(loggedUser._id);

    const [updatePermission, { isLoading: isUpdatingPermission }] = useUpdatePermissionMutation();
    const [unfollowUser, { isLoading: isUnfollowing }] = useUnfollowUserMutation();

    useEffect(() => {
        if (managerRelationship) {
            console.log(managerRelationship)
            if (managerRelationship?.permissionLevel === 0) {
                setInviteSent(true);
                setManagerId(managerRelationship.manager._id);
                setConnectedManager(managerRelationship.manager.username);
            } else {

                setIsInviteAccepted(true);
                setManagerId(managerRelationship?.manager?._id);
                setConnectedManager(managerRelationship?.manager?.username);
            }
        }
    }, [managerRelationship]);

    const handleAcceptInvite = async () => {
        try {
            console.log("mikac")
            console.log(managerId)
            console.log(loggedUser._id)
            await updatePermission({ managerId, userId: loggedUser._id, permissionLevel: 1 }).unwrap();
            setIsInviteAccepted(true);
            setInviteSent(false);
        } catch (err) {
            console.error('Failed to accept invite:', err);
        }
    };

    const handleDenyInvite = async () => {
        try {
            await unfollowUser({ managerId, userId: loggedUser._id }).unwrap();
            alert('Invite denied. Manager settings are not available.');
            setInviteSent(false);
        } catch (err) {
            console.error('Failed to deny invite:', err);
        }
    };

    const handleDisconnectManager = async () => {
        try {
            await unfollowUser({ managerId, userId: loggedUser._id }).unwrap();
            setIsInviteAccepted(false);
            setConnectedManager('');
            alert('Manager has been disconnected.');
        } catch (err) {
            console.error('Failed to disconnect manager:', err);
        }
    };

    const handlePermissionChange = (event) => {
        setSelectedPermission(event.target.value);
    };

    const handleUpdatePermission = async () => {
        try {
            await updatePermission({ managerId, userId: loggedUser._id, permissionLevel: selectedPermission }).unwrap();
            alert(`Permission updated to: ${selectedPermission}`);
        } catch (err) {
            console.error('Failed to update permission:', err);
        }
    };


    if (isLoading) {
        return <p>Loading manager settings...</p>;
    }

    if (followedError) {
        return <p>Error fetching manager settings: {followedError.message}</p>;
    }

    return (
        <div className={ManagerSettingsCSS['manager-settings-container']}>
            {inviteSent && (
                <div className={ManagerSettingsCSS['invite-section']}>
                    <p>{connectedManager} has sent you a manager invite. Please accept or deny to proceed.</p>
                    <button onClick={handleAcceptInvite} className={ManagerSettingsCSS['accept-button']} disabled={isUpdatingPermission}>
                        {isUpdatingPermission ? 'Accepting...' : 'Accept'}
                    </button>
                    <button onClick={handleDenyInvite} className={ManagerSettingsCSS['deny-button']} disabled={isUnfollowing}>
                        {isUnfollowing ? 'Denying...' : 'Deny'}
                    </button>
                </div>
            )}

            {isInviteAccepted && (
                <div className={ManagerSettingsCSS['settings-section']}>
                    <p>Manager Settings:</p>
                    <div className={ManagerSettingsCSS['manager-info']}>
                        <label>Currently Connected Manager:</label>
                        <span>{connectedManager || 'No manager connected'}</span>
                    </div>

                    <div className={ManagerSettingsCSS['permission-section']}>
                        <label>Select Manager's Permission:</label>
                        <select value={selectedPermission} onChange={handlePermissionChange}>
                            <option value="">Select Permission</option>
                            <option value="1">Read Permission</option>
                            <option value="2">Full Permission</option>
                            <option value="3">Content Permission</option>
                            <option value="4">Profile Permission</option>
                        </select>
                    </div>

                    <div className={ManagerSettingsCSS['permission-description']}>
                        <h4>Permissions Legend:</h4>
                        <ul>
                            <li><strong>Read Permission:</strong> Can see information and content but cannot create/edit/delete.</li>
                            <li><strong>Full Permission:</strong> Can see, create, edit, and delete all information & content.</li>
                            <li><strong>Content Permission:</strong> Can see all information and content but can only create/edit/delete content.</li>
                            <li><strong>Profile Permission:</strong> Can see all information and content but can only edit profile information (stories included).</li>
                        </ul>
                    </div>

                    <button onClick={handleUpdatePermission} className={ManagerSettingsCSS['update-button']} disabled={isUpdatingPermission}>
                        {isUpdatingPermission ? 'Updating...' : 'Update Permission'}
                    </button>

                    <button onClick={handleDisconnectManager} className={ManagerSettingsCSS['disconnect-button']} disabled={isUnfollowing}>
                        {isUnfollowing ? 'Disconnecting...' : 'Disconnect Manager'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default ManagerSettings;
