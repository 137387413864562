import ContentSectionCSS from './ContentSection.module.css'
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined'
import { useSelector } from 'react-redux'
import { selectPostById } from '../../../features/post/postsApiSlice'
import { formatDate } from '../../../common/utils'
import {useEffect, useRef, useState} from 'react'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import FullScreenModal from '../FullScreenModal'
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded'

const FeedPost = ({ postId, isImage, isVideo}) => {

    const videoRef = useRef()
    const [showPlayIcon, setShowPlayIcon] = useState(false)
    const [showPauseIcon, setShowPauseIcon] = useState(false)
    const [fullscreenOpened, setFullscreenOpened] = useState(false)

    const post = useSelector(state => selectPostById(state, postId))

    const handleVideoPlay = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play()
                setShowPlayIcon(true)
                setTimeout(() => setShowPlayIcon(false), 300) // Hide after 300ms
            } else {
                videoRef.current.pause()
                setShowPauseIcon(true)
                setTimeout(() => setShowPauseIcon(false), 300) // Hide after 300ms
            }
        }
    }

    useEffect(() => {
        if(fullscreenOpened && post.type === 'video') videoRef.current.pause()
    }, [fullscreenOpened, post])

    if((post.type === 'image' && !isVideo) || (post.type === 'video' && !isImage)) {
        return (
            <div className={`${ContentSectionCSS['single-post']} ${ContentSectionCSS['single-post-square']}`}>
                <div className={ContentSectionCSS['media-container']}>
                    {post.type === 'image' ? (
                        <img src={post?.url} alt="gallery-placeholder"/>
                    ) : (
                        <video ref={videoRef} onClick={handleVideoPlay}>
                            <source src={post.url}/>
                            Your browser does not support the video tag.
                        </video>
                    )}
                    <PlayArrowRoundedIcon
                        className={`${ContentSectionCSS['play-icon']} ${showPlayIcon ? ContentSectionCSS['show'] : ContentSectionCSS['hide']}`}
                    />
                    <PauseRoundedIcon
                        className={`${ContentSectionCSS['pause-icon']} ${showPauseIcon ? ContentSectionCSS['show'] : ContentSectionCSS['hide']}`}
                    />
                    <FullscreenRoundedIcon className={ContentSectionCSS['fullScreen']} onClick={() => setFullscreenOpened(true)}/>
                </div>
                <div className={ContentSectionCSS['text-content']}>
                    <div>
                        <p>{post?.description ? post.description : '.'}</p>
                        <span>{formatDate(post?.createdAt)}</span>
                    </div>
                    <div>
                        <LocalFireDepartmentOutlinedIcon/>
                        <span>{post?.likes?.length}</span>
                    </div>
                </div>
                <FullScreenModal fullscreenOpened={fullscreenOpened} postUrl={post?.url} closeModal={() => setFullscreenOpened(false)} postType={post?.type}/>
            </div>
        )
    }
    else return null
}

export default FeedPost

