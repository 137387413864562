import ContentSectionCSS from './ContentSection.module.css'
import FeedPost from './FeedPost'
const SubscriptionFeed = ({posts, isVideo, isImage}) => {

    if(posts){
        const galleryContent = () => {
            const images = [];
            for (let i = posts?.length - 1; i >= 0; i--) {
                images.push(
                    <FeedPost key={posts?.length - i} postId={posts[i]} isImage={isImage} isVideo={isVideo}/>
                )
            }
            return images
        }

        return (
            <div className={ContentSectionCSS['feed-container']}>
                <div className={ContentSectionCSS['post-feed']}>
                    {galleryContent()}
                </div>
            </div>
        )
    } else return null
}

export default SubscriptionFeed