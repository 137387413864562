import Modal from 'react-modal'
import { Document, Page, pdfjs } from 'react-pdf'
import {useState} from 'react'
import HomePageCSS from './HomePage.module.css'

Modal.setAppElement('#root')

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const LoginRedirection = ({tosIsOpened, closeModal}) => {

    const [numPages, setNumPages] = useState()
    const [pageNumber, setPageNumber] = useState(1)

    function onDocumentLoadSuccess({ numPages }){
        setNumPages(numPages);
    }

    return(
        <Modal
            isOpen={tosIsOpened}
            onRequestClose={closeModal}
            className={
                {
                    base: `modal-content ${HomePageCSS['tos-modal']}`,
                    afterOpen: 'modal-content--after-open',
                    beforeClose: 'modal-content--before-close'
                }
            }
            overlayClassName={
                {
                    base: 'modal-overlay',
                    afterOpen: `modal-overlay--after-open ${HomePageCSS['tos-modal--after-open']}`,
                    beforeClose: 'modal-overlay--before-close'
                }
            }
            closeTimeoutMS={300}
        >
            <div className={`modal-close ${HomePageCSS['modal-close']}`}>
                <button
                    onClick={closeModal}
                    className='close-button'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path
                            d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/>
                    </svg>
                </button>
            </div>
            <Document file='/terms_of_service.pdf' onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
                <div className={HomePageCSS['pdf-viewer']}>
                    {Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={Math.min(document.body.clientWidth * 0.8, 400)}
                            />
                        ),
                    )}
                </div>
            </Document>
        </Modal>
    )
}

export default LoginRedirection