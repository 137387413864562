import {useSelector} from 'react-redux'
import {selectPostById} from '../../../features/post/postsApiSlice'
import ContentSectionCSS from './ContentSection.module.css'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

const GridPost = ({ postId, isVideo, isImage}) => {

    const videoRef = useRef()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        const handleLoadedMetadata = () => {
            if (videoRef?.current) {
                setDuration(Math.round(videoRef.current.duration))
            }
        }

        const videoElement = videoRef.current
        if (videoElement) {
            videoElement.addEventListener('loadedmetadata', handleLoadedMetadata)
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
            }
        }
    }, [])

    const post = useSelector(state => selectPostById(state, postId))

    if(post) {
        if (post.type === 'image' && !isVideo) return (
            <div className={ContentSectionCSS['gallery-item']} onClick={() => navigate(`post/${postId}`, { state: { from: pathname } })}>
                <img src={post.url} alt='gallery-placeholder'/>
            </div>
        )
        else if(post.type === 'video' && !isImage) return (
            <div className={ContentSectionCSS['gallery-item']} onClick={() => navigate(`post/${postId}`, { state: { from: pathname } })}>
                <video ref={videoRef}>
                    <source src={post.url}/>
                    Your browser does not support the video tag.
                </video>
                <PlayArrowRoundedIcon className={ContentSectionCSS['play-button']} onClick={() => navigate(`${postId}`)}/>
                <span>{duration} sec</span>
            </div>
        )
    } else return null

}

export default GridPost