import ManagerDashCSS from './ManagerDash.module.css'
import {useFindUserByEmailQuery} from '../../features/user/usersApiSlice'
import {useEffect, useRef, useState} from 'react'
import {useDebounce} from 'use-debounce'
import RemoveGirl from './RemoveGirl'
import { useFollowUserMutation, useGetFollowedUsersQuery } from '../../features/managerFollowingUser/managerFollowingUserApiSlice';
import ClipLoader from 'react-spinners/ClipLoader'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import {Link, useNavigate} from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'

const GirlList = ({ loggedUser }) => {

    const [email, setEmail] = useState('')
    const [debouncedEmail] = useDebounce(email, 800)
    const [user, setUser] = useState(null)
    const [isDebouncing, setIsDebouncing] = useState(false)
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
    const [chosenUser, setChosenUser] = useState('')

    const inputRef = useRef(null)
    const navigate = useNavigate()

    const { data: followedUsersData, isLoading: isFetchingUsers, error: fetchError } = useGetFollowedUsersQuery(loggedUser?._id);
    const [followUser, { isLoading: isFollowing, isError: followError }] = useFollowUserMutation();

    const { data, error, isLoading } = useFindUserByEmailQuery(debouncedEmail, {
        skip: !debouncedEmail,
    })

    useEffect(() => {
        if (email && !debouncedEmail) {
            setIsDebouncing(true)
        } else {
            setIsDebouncing(false)
        }

        if (error) {
            setUser(null)
        } else if (data) {
            console.log('User found:', data)
            setUser(data)
        } else if (!isLoading && debouncedEmail) {
            console.log('No user found with email:', debouncedEmail)
            setUser(null)
        }

        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [data, error, isLoading, debouncedEmail, email])

    const handleInputChange = (e) => {
        setEmail(e.target.value)
    }

    const handleInviteClick = async () => {
        if (!user?._id) {
            alert('User not found');
            return;
        }

        try {
            console.log(user._id);
            await followUser({ managerId: loggedUser?._id, userId: user._id, permissionLevel: 0 }).unwrap();
        } catch (err) {
            console.error('Failed to follow user:', err);
        }
    };
    const managedUsers = followedUsersData
        ? Object.values(followedUsersData.entities).filter(user => user.permissionLevel >= 1)
        : [];

    const pendingUsers = followedUsersData
        ? Object.values(followedUsersData.entities).filter(user => user.permissionLevel === 0)
        : [];

    return (
        <div className="page-container page-container-20">
            <h2>Overview</h2>
            <div className={ManagerDashCSS['girl-list']}>
                <div className={ManagerDashCSS['invite-section']}>
                    <div className={ManagerDashCSS['input-container']}>
                        <input
                            type="text"
                            ref={inputRef}
                            placeholder="Enter E-Mail"
                            value={email}
                            onChange={handleInputChange}
                            disabled={isLoading}
                        />
                        <div className={ManagerDashCSS['search-status']}>
                            {user
                                ? <CheckIcon className={ManagerDashCSS['check-icon']}/>
                                : error
                                    ? <CloseIcon className={ManagerDashCSS['x-icon']}/>
                                    : isLoading || isDebouncing &&
                                    <ClipLoader size="13px" color="#F92E85FF"/>
                            }
                        </div>
                    </div>
                    <button className='submit-button no-margin' type="submit" onClick={handleInviteClick} disabled={isLoading || isFollowing}>
                        {isFollowing ? 'Following...' : 'Invite'}
                    </button>
                </div>
                <div className={ManagerDashCSS['managed-section']}>
                    <h3>Pending Invitations</h3>
                    <ul>
                        {pendingUsers.length > 0 ? (
                            pendingUsers.map((managedUser) => (
                                <li key={managedUser._id}>
                                    <div className={`${ManagerDashCSS['girl-item']} ${ManagerDashCSS['girl-item-pending']}`}>
                                        <div className="opacity-50">
                                            <img src={managedUser?.userId?.profileImage} alt="user-img"/>
                                            <h4>{managedUser.userId.username}</h4>
                                        </div>
                                        <div>
                                            <p className="opacity-50">Permission Level: {managedUser.permissionLevel}</p>
                                            <button
                                                onClick={() => {
                                                    setIsConfirmationOpen(true)
                                                    setChosenUser(managedUser.userId._id)
                                                }
                                                }>
                                                <DeleteIcon className='delete-icon-round'/>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <p>No pending requests.</p>
                        )}
                    </ul>
                </div>
                <div className={ManagerDashCSS['managed-section']}>
                    <h3>Managed Accounts</h3>
                    {isFetchingUsers ? (
                        <p>Loading managed users...</p>
                    ) : fetchError ? (
                        <p>Error fetching managed users: {fetchError.message}</p>
                    ) : (
                        <ul>
                            {managedUsers.length > 0 ? (
                                managedUsers.map((managedUser) => (
                                        <li key={managedUser._id}>
                                            <div className={ManagerDashCSS['girl-item']}>
                                                <div onClick={()=>navigate(`/dash/user/provider/${managedUser.userId._id}`)}>
                                                    <img src={managedUser?.userId?.profileImage} alt='user-img' />
                                                    <h4>{managedUser.userId.username}</h4>
                                                </div>
                                                <div>
                                                    <p>Level: {managedUser.permissionLevel}</p>
                                                    <button onClick={(e) => {
                                                        setIsConfirmationOpen(true)
                                                        setChosenUser(managedUser.userId._id)
                                                    }
                                                    }>
                                                        <DeleteIcon className='delete-icon-round'/>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                ))
                            ) : (
                                <p>No managed users found</p>
                            )}
                        </ul>
                    )}
                </div>
                    {isLoading && <p>Loading...</p>}
                    {error && <p>Error: {error.message}</p>}
                    {followError && <p>Error following user</p>}
                </div>
                <RemoveGirl isConfirmationOpen={isConfirmationOpen} closeModal={() => setIsConfirmationOpen(false)} managerId={loggedUser?._id} chosenUser={chosenUser}/>
            </div>
            )
            }

            export default GirlList