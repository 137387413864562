import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import { jwtDecode } from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isProvider = false
    let isManager = false
    let status = "user"

    if (token) {
        const decoded = jwtDecode(token)
        const { email, roles } = decoded.UserInfo

        isProvider = roles.includes('provider')
        isManager = roles.includes('manager')

        if (isProvider) status = "provider"
        else if (isManager) status = "manager"

        return { email, roles, status, isProvider, isManager }
    }

    return { email: '', roles: [], isProvider, status }
}
export default useAuth