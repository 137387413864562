import React, { useState } from 'react';
import AdsCSS from './Ads.module.css';
import addImage from '../../assets/img/gallery-placeholder.jpg';
import {useNavigate, useParams} from 'react-router-dom'
import {
    selectAdvertiserById,
    useAddNewAdvertiserMutation
} from '../../features/advertiser/advertiserApiSlice'
import {useSelector} from 'react-redux' // Adjust the import based on your directory structure

const EditAd = ({loggedUser}) => {
    const navigate = useNavigate();
    const [adName, setAdName] = useState('');
    const [finalURL, setFinalURL] = useState('');
    const [duration, setDuration] = useState('7 days');
    const [mediaType, setMediaType] = useState('image');
    const [mediaURL, setMediaURL] = useState(addImage);

    const { adId } = useParams()

    const [addNewAdvertiser] = useAddNewAdvertiserMutation();

    const selectedAd = useSelector(state => selectAdvertiserById(state, adId))

    console.log('selectedAd', selectedAd);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newAd = {
            managerId: loggedUser._id,
            name: adName,
            finalURL: finalURL,
            mediaType: mediaType,
            mediaURL: mediaURL,
            expiresAt: new Date(Date.now() + getDurationInMs(duration)),
        };

        try {
            await addNewAdvertiser(newAd).unwrap();
            navigate('/dash/ads');
        } catch (error) {
            console.error('Failed to save the ad: ', error);
        }
    };

    const getDurationInMs = (duration) => {
        switch (duration) {
            case '7 days':
                return 7 * 24 * 60 * 60 * 1000;
            case '15 days':
                return 15 * 24 * 60 * 60 * 1000;
            case '30 days':
                return 30 * 24 * 60 * 60 * 1000;
            default:
                return 0;
        }
    };

    return (
        <div className="page-container">
            <h2>Edit Ad:</h2>
            <form className={AdsCSS['create-ad']} onSubmit={handleSubmit}>
                <div className={AdsCSS['first-row']}>
                    <div>
                        <button type="button" onClick={() => setDuration('7 days')}>
                            7 days <span>100 CHF</span>
                        </button>
                        <button type="button" onClick={() => setDuration('15 days')}>
                            15 days <span>200 CHF</span>
                        </button>
                        <button type="button" onClick={() => setDuration('30 days')}>
                            30 days <span>350 CHF</span>
                        </button>
                    </div>
                    <div className={AdsCSS['image-section']}>
                        <p>Add your Creative</p>
                        <p>
                            Video or image <br />
                            Format: 1920x1080px<br />
                            Video: 5 - 15 sec
                        </p>
                        <img src={mediaURL} alt="Ad creative" className={AdsCSS['creative-image']} />
                    </div>
                </div>
                <div className={AdsCSS['second-row']}>
                    <input
                        type='text'
                        placeholder='Ad Name'
                        value={selectedAd?.name}
                        onChange={(e) => setAdName(e.target.value)}
                        required
                    />
                    <input
                        type='text'
                        placeholder='Final URL'
                        value={finalURL}
                        onChange={(e) => setFinalURL(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className={AdsCSS['submit-button']}>Add New Ad</button>
            </form>
        </div>
    );
};

export default EditAd
