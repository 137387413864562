import ContentSectionCSS from '../Subscription/ContentSection.module.css'
import FeedPremiumPost from "./FeedPremiumPost";

const PremiumPostFeed = ({posts, isVideo, isImage,loggedUser}) => {

    if(posts){
        const galleryContent = () => {
            const images = [];
            for (let i = posts?.length - 1; i >= 0; i--) {
                images.push(
                    <FeedPremiumPost key={posts?.length - i} postId={posts[i]} isImage={isImage} isVideo={isVideo} loggedUser={loggedUser}/>
                )
            }
            return images
        }

        return (
            <div className={ContentSectionCSS['feed-container']}>
                <div className={ContentSectionCSS['post-feed']}>
                    {galleryContent()}
                </div>
            </div>
        )
    } else return null
}

export default PremiumPostFeed