import ClicksAndImpressionsCSS from './ClicksAndImpressions.module.css'
import useAuth from '../../hooks/useAuth'

const ClicksAndImpressions = ({loggedUser}) => {

    const { isProvider } = useAuth()

    return (
        <div className={ClicksAndImpressionsCSS['impressions-container']}>
            { isProvider && <span>{loggedUser?.impressions} Total Story Views</span>}
            <span>{loggedUser?.clicks} Total Profile Visitors</span>
        </div>
    )
}

export default ClicksAndImpressions