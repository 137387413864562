import galleryPlaceholder from '../../assets/img/gallery-placeholder.jpg'
import StandardUserCSS from './StandardUser.module.css'
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined'
import GridOnIcon from '@mui/icons-material/GridOn'
import {useState} from 'react'
import FeedSection from './PremiumContentFeed'
const PremiumContent = () => {

    const [isFeed, setIsFeed] = useState(false)
    const galleryContent = () => {
        const images = [];
        for (let i = 0; i < 8; i++) {
            images.push(<img key={i} src={galleryPlaceholder} alt='gallery-placeholder' className={StandardUserCSS['gallery-item']} />);
        }
        return images;
    }


    return (
        <div className={StandardUserCSS['gallery-container']}>
            <div className={StandardUserCSS['gallery-header']}>
                <div>
                    <SmartDisplayOutlinedIcon/>
                    <ImageOutlinedIcon/>
                    <div>
                        <span>0 videos</span>
                        <span>0 pictures</span>
                    </div>
                </div>
                <div>
                    <SquareOutlinedIcon className={`${isFeed ? StandardUserCSS['active'] : ''}`} onClick={() => setIsFeed(true)}/>
                    <GridOnIcon className={`${!isFeed ? StandardUserCSS['active'] : ''}`} onClick={() => setIsFeed(false)}/>
                </div>
            </div>
            {isFeed ?
                <FeedSection />
                :
                // <div className={StandardUserCSS['gallery-grid']}>
                <div>
                    <h4>You have no premium content yet.</h4>
                </div>
            }
        </div>
    )
}

export default PremiumContent