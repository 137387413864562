import React, {useEffect, useRef, useState} from 'react'
import CaptureDocumentCSS from './CaptureDocument.module.css'
import Webcam from "react-webcam"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faPaperPlane} from "@fortawesome/free-solid-svg-icons"
import {useUploadDocumentsForVerificationMutation} from "../../features/story/imageApiSLice"
import {useEmailDocumentsMutation} from "../../features/user/usersApiSlice"
import PulseLoader from "react-spinners/PulseLoader"
import CachedIcon from '@mui/icons-material/Cached'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'
import {useNavigate} from 'react-router-dom'

function CaptureDocument({user}) {

    const [uploadImageForVerification,{
        isSuccess: isUploadSuccess,
        isLoading: isUploadLoading,
        isError: isUploadError,
        error: uploadingError
    }] = useUploadDocumentsForVerificationMutation()

    const [verifyDocuments,{
        isSuccess: isVerifySuccess,
        isLoading: isVerifyLoading,
        isError: iseVerifyError,
        error: verifyError
    }] = useEmailDocumentsMutation()

    const webcamRef = useRef(null)

    const [image1, setImage1] = useState('')
    const [image2, setImage2] = useState('')
    const [cameraLoaded, setCameraLoaded] = useState(false)
    const [uploadError, setUploadError] = useState('')
    const [fileUrl, setFileUrl] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)

    const navigate = useNavigate()
    const galleryInputRef = useRef()

    const videoConstraints = {
        facingMode: 'environment',
    }

    const handleCameraLoad = () => {
        setCameraLoaded(true)
    }

    const captureImage = () => {
        const imageSrc = webcamRef.current.getScreenshot()
        if(image1 === '') setImage1(imageSrc)
        else if(image2 === '') setImage2(imageSrc)
    }

    const discardImage = (index) => {
        if(index === 0) setImage1('')
        else if(index === 1) setImage2('')
        else if(index === 2) setSelectedFile(null)
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0]
        if (file) {
            setSelectedFile(file)
        }
    }

    const base64ToFile = (base64String, fileName) => {
        let arr = base64String.split(',')
        let mime = arr[0].match(/:(.*?)/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], fileName, { type: mime })
    }

    const handleSendImage = async (img) => {

        const file = base64ToFile(img, 'captured_image.jpg')
        const formData = new FormData()
        formData.append('file', file)
        formData.append('email', user?.email)
        formData.append('userId', user?._id)

        try {
            const result = await uploadImageForVerification(formData)
            setFileUrl(result.data.fileUrl)
            return result.data.fileUrl
        } catch (error) {
            setUploadError('Error uploading image: ' + error.message)
            throw error
        }
    }

    const handleSendProfileImage = async () => {
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('email', user?.email)
        formData.append('userId', user?._id)

        try {
            const result = await uploadImageForVerification(formData)
            setFileUrl(result.data.fileUrl)
            return result.data.fileUrl
        } catch (error) {
            setUploadError('Error uploading image: ' + error.message)
            throw error
        }
    }

    const handleSendVerification = async () => {
        try {
            const imageUrl1 = await handleSendImage(image1)
            const imageUrl2 = await handleSendImage(image2)
            const imageUrl3 = await handleSendProfileImage()
            await verifyDocuments({ userId: user?._id, imageUrl1: imageUrl1, imageUrl2: imageUrl2, imageUrl3: imageUrl3 })
            console.log('Documents sent successfully')
        } catch (error) {
            console.error('Failed to submit report:', error)
        }
    }

    const [buttonContent, setButtonContent] = useState(
        <button
            className={CaptureDocumentCSS['capture-btn']}
            onClick={captureImage}
        >
            <span></span>
        </button>
    )

    useEffect(() => {
        if (isVerifyLoading || isUploadLoading) {
            console.log('Upload and verification lodaing')
            setButtonContent(
                <button className={CaptureDocumentCSS['send-btn']}>
                    <PulseLoader color={'#F92E85FF'}/>
                </button>
            )
        }
    }, [isVerifyLoading, isUploadLoading])

    useEffect(() => {
        if (isUploadSuccess && isVerifySuccess) {
            console.log(isUploadSuccess)
            console.log(isVerifySuccess)
            console.log('Upload and verification successful')
            setButtonContent(
                <button className={CaptureDocumentCSS['sent-btn']}>
                    <FontAwesomeIcon icon={faCheck}/>
                </button>
            )
            setImage1('')
            setImage2('')
            setSelectedFile(null)
            navigate('/dash/verify')
        }
    }, [isUploadSuccess, isVerifySuccess])

    useEffect(() => {
        if(image1 !== '' && image2 !== '' && !selectedFile) {
            setButtonContent(
                <button className={CaptureDocumentCSS['send-btn']} onClick={handleSendVerification}>
                    <FontAwesomeIcon icon={faPaperPlane}/>
                </button>
            )
        }
        else{
            setButtonContent(
                <button
                    className={CaptureDocumentCSS['capture-btn']}
                    onClick={captureImage}
                >
                </button>
            )
        }
    }, [image1, image2, selectedFile])

    return (
        <div className={CaptureDocumentCSS['camera-container']}>
            <>
                {image1 === '' ? <h3>1. Make a Picture of your <u>ID Front</u></h3> : null}
                {image2 === '' && image1 !== '' ? <h3>2. Make a Picture of your <u>ID Back</u></h3> : null}
                {!selectedFile && image1 !== '' && image2 !== '' ? <h3>3. Upload your Profile Picture</h3> : null}
                {selectedFile && image1 !== '' && image2 !== '' ? <h3></h3> : null}

                {image1 === '' || image2 === '' ?
                    <div className={CaptureDocumentCSS['webcam-container']}>
                        <Webcam
                            videoConstraints={videoConstraints}
                            ref={webcamRef}
                            onUserMedia={handleCameraLoad}
                            screenshotFormat="image/jpeg"
                        />
                        {buttonContent}
                    </div> :
                    image1 !== '' && image2 !== '' && selectedFile ?
                        <div className={CaptureDocumentCSS['verification-fulfilled']}>
                            <h2>Thank you!</h2>
                            <h4>Send now your data for verification:</h4>
                            <button disabled={isVerifyLoading || isUploadLoading} onClick={handleSendVerification}>
                                {isVerifyLoading || isUploadLoading
                                ? <PulseLoader color='#fff' />
                                    : 'Verify my Profile'
                                }
                            </button>
                        </div>
                    :
                    <div className={CaptureDocumentCSS['gallery-input']}>
                        <CollectionsOutlinedIcon onClick={() => galleryInputRef.current.click()}/>
                    </div>
                }
                <div className={CaptureDocumentCSS['image-preview']}>
                    <div className={CaptureDocumentCSS['single-image']}>
                        {image1 !== '' ?
                            <div className={CaptureDocumentCSS['captured']}>
                                <CachedIcon onClick={() => discardImage(0)}/>
                                <p>ID Front</p>
                            </div>
                            : <div className={CaptureDocumentCSS['empty']}><p>ID Front</p></div>}
                    </div>
                    <div className={CaptureDocumentCSS['single-image']}>
                        {image2 !== '' ?
                            <div className={CaptureDocumentCSS['captured']}>
                                <CachedIcon onClick={() => discardImage(1)}/>
                                <p>ID Back</p>
                            </div>
                            : <div className={CaptureDocumentCSS['empty']}><p>ID Back</p></div>}
                    </div>
                    <div className={CaptureDocumentCSS['single-image']}>
                        {selectedFile ?
                            <div className={CaptureDocumentCSS['captured']}>
                                <CachedIcon onClick={() => discardImage(2)}/>
                                <p>Profile Picture</p>
                            </div>
                            : <div className={CaptureDocumentCSS['empty']}><p>Profile Picture</p></div>}
                    </div>
                </div>
            </>
            <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                style={{display: 'none'}}
                ref={galleryInputRef}
            />
        </div>
    )
}

export default CaptureDocument