import { useState } from 'react';

const SearchFilters = () => {
    const [username, setUsername] = useState('');
    const [gender, setGender] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [age, setAge] = useState(0);
    const [minAge, setMinAge] = useState(20);
    const [maxAge, setMaxAge] = useState(80);
    const [price, setPrice] = useState(0);
    const [location, setLocation] = useState('');
    const [distance, setDistance] = useState(0);
    const [isToggled, setIsToggled] = useState(false);
    const [value, setValue] = useState([20, 37]);

    return {
        username,
        setUsername,
        gender,
        setGender,
        selectedGenders,
        setSelectedGenders,
        selectedCategories,
        setSelectedCategories,
        selectedAttributes,
        setSelectedAttributes,
        selectedServices,
        setSelectedServices,
        age,
        setAge,
        minAge,
        setMinAge,
        maxAge,
        setMaxAge,
        price,
        setPrice,
        location,
        setLocation,
        distance,
        setDistance,
        isToggled,
        setIsToggled,
        value,
        setValue
    };
};

export default SearchFilters;
