import {useSelector} from 'react-redux'
import {selectUserById} from '../../features/user/usersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import UserFollowingCSS from '../UserProfile/UserFollowing.module.css'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {haversineDistance, isProviderAvailable} from '../../common/utils'
import ProfilePlaceholder from '../../assets/img/profile-placeholder.jpg'

const Provider = ({providerId, loggedUser}) => {

    const user = useSelector(state => selectUserById(state, providerId))
    const navigate = useNavigate()

    if(user){
        const availabilityStatus = isProviderAvailable(user?.availability)

        return (
            <div
                onClick={() => navigate(`/dash/user/${providerId}`, { state: { from: '/dash/user/content-section' } })}
                className={UserFollowingCSS['single-provider']}
            >
                <img src={user.profileImage ? user.profileImage : ProfilePlaceholder} alt='profile-image' />
                <div className={UserFollowingCSS['provider-name']}>
                    <h3>{user.username}</h3>
                    <p>{user?.location ? ` ${haversineDistance(user?.location?.coordinates?.coordinates, loggedUser?.location?.coordinates?.coordinates)}` : 'No location'} <span className={`${UserFollowingCSS['dot']} ${availabilityStatus ? UserFollowingCSS['available'] : UserFollowingCSS['unavailable']}`}></span></p>
                </div>
            </div>
        )
    } else {
        return <PulseLoader color='#F92E85FF' className='pulse-loader'/>
    }
}

export default Provider