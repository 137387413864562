import React, { useRef, useState, useEffect } from 'react'
import CameraRecordingCSS from "../CameraRecording/CameraRecording.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons"
import {useAddNewStoryMutation, useUpdateStoryMutation} from '../../features/story/storiesApiSlice'
import {useUploadImageMutation} from '../../features/story/imageApiSLice'
import {useUpdateUserStoriesMutation} from '../../features/user/usersApiSlice'
import UploadModal from './UploadModal'
import PulseLoader from "react-spinners/PulseLoader"

const CameraRecording = ({loggedUser, selectedFile, videoSrc, setVideoSrc}) => {

    //region Mutations
    const [addNewStory,{
        isLoading: isLoadingNewStory,
        isSuccess: isSuccessNewStory,
        isError: isErrorNewStory,
        error: errorNewStory
    }] = useAddNewStoryMutation()

    const [updateStory, {
        isLoading: isLoadingUpdateStory,
        isSuccess: isSuccessUpdateStory,
        isError: isErrorUpdateStory,
        error: errorUpdateStory
    }] = useUpdateStoryMutation()

    const [updateUser, {
        isLoading: isLoadingUpdateUser,
        isSuccess: isSuccessUpdateUser,
        isError: isErrorUpdateUser,
        error: errorUpdateUser
    }] = useUpdateUserStoriesMutation()
    //endregion

    //Image upload
    const [uploadError, setUploadError] = useState('')
    const [uploadImageToS3,{
        isUploadLoading,
        isUploadError,
        isUploadSuccess
    }] = useUploadImageMutation()

    const textRef  = useRef()
    const [storyText,setStoryText] = useState('')
    const [isUploading, setIsUploading] = useState(false)
    const [file, setFile] = useState(null)

    const videoRef = useRef(null)

    const handleTextChange = (e) => {
        if(textRef.current.scrollHeight < 170) {
            setStoryText(e.target.value)
            adjustTextareaHeight();
        }
    }

    useEffect(() => {
        videoRef.current = document.createElement('video')
    }, [])

    function handleDiscard() {
        setVideoSrc('')
    }

    const validFileTypes = ['video/webm','video/mp4']

    const handleImageUpload = async () => {
        if (!selectedFile) {
            console.error('No file selected')
            return
        }

        if (!validFileTypes.includes(selectedFile.type)) {
            setUploadError('File must be in JPG/PNG format')
            return
        }

        setIsUploading(true)

        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('restaurantName', loggedUser.email)
        formData.append('userId', loggedUser._id)
        formData.append('username', loggedUser.username)

        try {
            const result = await uploadImageToS3(formData)
            setIsUploading(false)
            return result.data.fileUrl
        } catch (error) {
            setUploadError('Error uploading image: ' + error.message)
            setIsUploading(false)
            throw error
        }
    }

    const handlePostStory = async (e) => {
        e.preventDefault()

        setIsUploading(true)

        try{
            const storyObject = {
                userId: loggedUser._id,
                storyText
            }
            const result = await addNewStory(storyObject).unwrap()
            if (result && result.id) {
                const resultId = result.id
                const fileUrl = await handleImageUpload()
                await updateStory({ id: resultId, url: fileUrl })
                await updateUser({id: loggedUser._id, storyId: resultId})
                setVideoSrc('')
            } else {
                console.error('Category ID not returned by the server')
            }
        }
        catch (error){
            console.error('Error in onSaveCategoryClicked:', error)
        }
        setIsUploading(false)
    }

    useEffect(() => {
        adjustTextareaHeight()
    }, [storyText])

    useEffect(() => {
        const textarea = textRef.current
        if(textarea) {
            textarea.style.height = `${textarea.scrollHeight}px`
        }
    }, []);

    const adjustTextareaHeight = () => {
        const textarea = textRef.current
        if(textarea) {
            textarea.style.height = '48px' // Reset height to recalculate
            textarea.style.height = `${textarea.scrollHeight}px` // Set new height
            console.log(textarea.scrollHeight)
        }
    }

    return (
        <div className={CameraRecordingCSS['story-container']}>
            <div className={`${CameraRecordingCSS['video-container']}`}
                 style={{height: '100dvh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {videoSrc ? (
                    <div style={{height: '100%', textAlign: 'center'}}>
                        <video
                            onClick={() => textRef.current.focus()}
                            src={videoSrc}
                            ref={videoRef}
                            playsInline
                            autoPlay={true}
                            style={{height: '100%'}}
                            loop
                        />

                        <button className={CameraRecordingCSS['x-button']} onClick={handleDiscard}>
                            <FontAwesomeIcon icon={faXmark}
                                             style={{
                                                 filter: 'drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 2px)'
                                             }}
                            />
                        </button>
                        <button disabled={isUploading} className={CameraRecordingCSS['check-button']} onClick={handlePostStory}>
                            {isUploading ?
                                <PulseLoader color={'#ffffff'} className={CameraRecordingCSS['check-button']}/>
                                : <FontAwesomeIcon icon={faCheck} style={{ filter: 'drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 2px)'}} />
                            }
                        </button>
                        {storyText === '' && (
                            <span
                                className={CameraRecordingCSS['click-to-add-text']}
                                onClick={() => textRef.current && textRef.current.focus()}
                            >
                                Click to add Text
                            </span>
                        )}
                        <textarea
                            value={storyText}
                            ref={textRef}
                            onChange={handleTextChange}
                            className={storyText !== '' ? `${CameraRecordingCSS['black-background']}` : ''}
                            type="text"
                        />

                    </div>
                ) : null}
            </div>
            <UploadModal/>
        </div>
    )
}

export default CameraRecording
