import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import HomePageCSS from './HomePage.module.css'
import logo from '../../assets/img/watermark-pink.png'
import SearchLocation from './SearchLocation'
import TermsOfServiceViewer from './TermsOfServiceViewer'

const InfoPage = () => {

    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState(true)
    const [baseUrl, setBaseUrl] = useState('')
    const [tosIsOpened, setTosIsOpened] = useState(false)

    const onConfirmSubmit = (e) => {
        e.preventDefault()
        localStorage.setItem('isEighteen', 'true')
        navigate('/dash/stories')
    }

    const setLocation = (location) => {
        localStorage.setItem('location', JSON.stringify(location))
    }

    useEffect(() => {
        if (process.env.REACT_ENV === 'development') setBaseUrl(process.env.DEV_URL)
        else setBaseUrl(process.env.PROD_URL)
    }, [])

    const content = (
        <div className={HomePageCSS['image-background-home']}>
            <div className={`${HomePageCSS['home-container']}`}>
                <div className={`container ${HomePageCSS['presentation']}`}>
                    <div className={HomePageCSS['presentation-container']}>
                        <div className={HomePageCSS['logo-container']}>
                            <img src={logo} alt='CamNextDoor Logo' />
                            <p>Makes your fantasies real</p>
                        </div>
                        <div className={HomePageCSS['video-container']}>
                            <video autoPlay loop muted playsInline src='https://camnextdoor-assets.s3.eu-central-1.amazonaws.com/home-anim.mp4'></video>
                        </div>
                    </div>
                </div>
                <div className={`container ${HomePageCSS['user-section']}`}>
                    <SearchLocation setIsDisabled={setIsDisabled} setLocation={setLocation}/>
                    <button disabled={isDisabled} onClick={onConfirmSubmit}>I am over 18 <span>& accept terms of service</span></button>
                    <div className={HomePageCSS['home-links']}>
                        <Link to='/dash/login' className={HomePageCSS['sign-up-link']}>Sign up for free</Link>
                    </div>
                </div>
                <button
                    className={`${HomePageCSS['tos-link']} ${HomePageCSS['terms-of-service']}`}
                    onClick={()=>setTosIsOpened(true)}
                >
                    Terms of Service
                </button>
            </div>
            <TermsOfServiceViewer tosIsOpened={tosIsOpened} closeModal={()=>setTosIsOpened(false)}/>
        </div>
    )
    return content
}

export default InfoPage
