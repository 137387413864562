import EmailVerifyCSS from "./EmailVerify.module.css"
import {useVerifyDocumentsMutation} from "../../features/user/usersApiSlice"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons"
import PulseLoader from "react-spinners/PulseLoader"
import {useNavigate, useParams} from "react-router-dom"
import {useEffect, useState} from "react"

const DocumentsVerification = () => {

    const [verifyDocuments, {isSuccess, isLoading, isError, Error}] = useVerifyDocumentsMutation()
    const navigate = useNavigate()
    const { id, token, accepted } = useParams()
    const [comment, setComment] = useState("")
    const [isCommentSubmitted, setIsCommentSubmitted] = useState(false)

    const handleSubmitComment = async () => {
        await verifyDocuments({userId: id, documentsToken: token, accepted: false, comment})
        setIsCommentSubmitted(true)
    }

    useEffect(() => {
        const verifyToken = async () => {
            if (accepted === 'accept') {
                await verifyDocuments({userId: id, documentsToken: token, accepted: true, comment})
            }
        }

        verifyToken()
    }, [])

    return (
        <div className={EmailVerifyCSS['verify-container']}>
            {isSuccess && accepted === 'accept' ? (
                <>
                    <span className={`${EmailVerifyCSS['icon-span']} ${EmailVerifyCSS['accepted']}`}><FontAwesomeIcon icon={faCheck} /></span>
                    <h3>Profile image verified successfully!</h3>
                </>
            ) : accepted === 'decline' ? (
                <>
                    {isCommentSubmitted ? (
                        <>
                            <span className={`${EmailVerifyCSS['icon-span']} ${EmailVerifyCSS['decline']}`}><FontAwesomeIcon icon={faXmark}/></span>
                            <h3>Profile image verification declined!</h3>
                        </>
                    ) : (
                        <>
                            <textarea
                                placeholder="Enter the reason for declining"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                className={EmailVerifyCSS['comment-textarea']}
                                maxLength='200'
                            />
                            <button onClick={handleSubmitComment} className={EmailVerifyCSS['submit-button']}>Submit Comment</button>
                        </>
                    )}
                </>
            ) : isLoading ? (
                <div><PulseLoader color={'#F92E85FF'}/></div>
            ) : (
                <h3>Link expired</h3>
            )}
        </div>
    )
}

export default DocumentsVerification
