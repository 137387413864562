import AuthCSS from '../../features/auth/Auth.module.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faXmark} from '@fortawesome/free-solid-svg-icons'
import React, {useState} from 'react'

const EditPrices = ({timeAndPrice, setTimeAndPrice, editing = true}) => {

    const [showingFields, setShowingFields] = useState(3)

    const handleTimeDurationChange = (key, newValue) => {
        setTimeAndPrice((prevTimeAndPrice) => ({
            ...prevTimeAndPrice,
            [key]: {
                ...prevTimeAndPrice[key],
                duration: newValue
            }
        }))
    }
    const handleTimePriceChange = (key, newValue) => {
        setTimeAndPrice((prevTimeAndPrice) => ({
            ...prevTimeAndPrice,
            [key]: {
                ...prevTimeAndPrice[key],
                price: newValue
            }
        }))
    }
    const handleTimeUnitChange = (key, newValue) => {
        setTimeAndPrice((prevTimeAndPrice) => ({
            ...prevTimeAndPrice,
            [key]: {
                ...prevTimeAndPrice[key],
                unit: newValue
            }
        }))
    }

    if(!timeAndPrice) return <p>No prices available.</p>

    return (
        <div className={`${AuthCSS['step-six']} ${AuthCSS['input-fields']} ${AuthCSS['edit-prices']}`}>
            <ul className={AuthCSS['price-list']}>
                {editing || timeAndPrice.FirstPrice.duration !== '' ? <li>
                    <div>
                        <input className={`${!editing ? AuthCSS['no-border-bottom'] : ''}`} type='number'
                               readOnly={!editing} value={timeAndPrice.FirstPrice.duration}
                               onChange={(e) => handleTimeDurationChange('FirstPrice', e.target.value)}/>
                        <select className={`${!editing ? AuthCSS['no-caret'] : ''}`} disabled={!editing} name='fistPrice'
                                value={timeAndPrice.FirstPrice.unit}
                                onChange={(e) => handleTimeUnitChange('FirstPrice', e.target.value)}>
                            <option>min</option>
                            <option>h</option>
                        </select>
                    </div>
                    <div><input
                        type='number'
                        name='thirtyMin'
                        id='thirtyMin'
                        readOnly={!editing}
                        onChange={(e) => handleTimePriceChange('FirstPrice', e.target.value)}
                        value={timeAndPrice.FirstPrice.price}
                        className={`${!editing ? AuthCSS['no-border-bottom'] : ''}`}
                    /><span>CHF</span></div>
                </li>:null}
                {editing || timeAndPrice.SecondPrice.duration !== '' ? <li>
                    <div>
                        <input className={`${!editing ? AuthCSS['no-border-bottom'] : ''}`} type='number' readOnly={!editing} value={timeAndPrice.SecondPrice.duration}
                               onChange={(e) => handleTimeDurationChange('SecondPrice', e.target.value)}/>
                        <select className={`${!editing ? AuthCSS['no-caret'] : ''}`} name='secondPrice' disabled={!editing} value={timeAndPrice.SecondPrice.unit}
                                onChange={(e) => handleTimeUnitChange('SecondPrice', e.target.value)}>
                            <option>min</option>
                            <option>h</option>
                        </select>
                    </div>
                    <div><input
                        type='number'
                        name='oneHour'
                        id='oneHour'
                        readOnly={!editing}
                        onChange={(e) => handleTimePriceChange('SecondPrice', e.target.value)}
                        value={timeAndPrice.SecondPrice.price}
                        className={`${!editing ? AuthCSS['no-border-bottom'] : ''}`}
                    /><span>CHF</span></div>
                </li>:null}
                {editing || timeAndPrice.ThirdPrice.duration !== '' ? <li>
                    <div>
                        <input className={`${!editing ? AuthCSS['no-border-bottom'] : ''}`}  type='number' readOnly={!editing} value={timeAndPrice.ThirdPrice.duration}
                               onChange={(e) => handleTimeDurationChange('ThirdPrice', e.target.value)}/>
                        <select className={`${!editing ? AuthCSS['no-caret'] : ''}`}  name='thirdPrice' disabled={!editing} value={timeAndPrice.ThirdPrice.unit}
                                onChange={(e) => handleTimeUnitChange('ThirdPrice', e.target.value)}>
                            <option>min</option>
                            <option>h</option>
                        </select>
                    </div>
                    <div><input
                        type='number'
                        name='twoHours'
                        id='twohours'
                        readOnly={!editing}
                        onChange={(e) => handleTimePriceChange('ThirdPrice', e.target.value)}
                        value={timeAndPrice.ThirdPrice.price}
                        className={`${!editing ? AuthCSS['no-border-bottom'] : ''}`}
                    /><span>CHF</span></div>
                </li>:null}
                {editing || timeAndPrice.FourthPrice.duration !== '' ? <li>
                    <div>
                        <input className={`${!editing ? AuthCSS['no-border-bottom'] : ''}`}  type='number' readOnly={!editing} value={timeAndPrice.FourthPrice.duration}
                               onChange={(e) => handleTimeDurationChange('FourthPrice', e.target.value)}/>
                        <select className={`${!editing ? AuthCSS['no-caret'] : ''}`}  name='fourthPrice' disabled={!editing} value={timeAndPrice.FourthPrice.unit}
                                onChange={(e) => handleTimeUnitChange('FourthPrice', e.target.value)}>
                            <option>min</option>
                            <option>h</option>
                        </select>
                    </div>
                    <div><input
                        type='number'
                        name='twoHours'
                        id='twohours'
                        readOnly={!editing}
                        onChange={(e) => handleTimePriceChange('FourthPrice', e.target.value)}
                        value={timeAndPrice.FourthPrice.price}
                        className={`${!editing ? AuthCSS['no-border-bottom'] : ''}`}
                    /><span>CHF</span></div>
                    {showingFields === 4 ?
                        <button
                            className={AuthCSS['delete-field']}
                            onClick={() => setShowingFields(3)}
                        >
                            <FontAwesomeIcon icon={faXmark} color='red'/>
                        </button> : null}
                </li>:null}
                {editing || timeAndPrice.FifthPrice.duration !== '' ? <li>
                    <div>
                        <input className={`${!editing ? AuthCSS['no-border-bottom'] : ''}`}  type='number' readOnly={!editing} value={timeAndPrice.FifthPrice.duration}
                               onChange={(e) => handleTimeDurationChange('FifthPrice', e.target.value)}/>
                        <select className={`${!editing ? AuthCSS['no-caret'] : ''}`}  name='fifthPrice' disabled={!editing} value={timeAndPrice.FifthPrice.unit}
                                onChange={(e) => handleTimeUnitChange('FifthPrice', e.target.value)}>
                            <option>min</option>
                            <option>h</option>
                        </select>
                    </div>
                    <div>
                        <input
                            type='number'
                            name='twoHours'
                            id='twohours'
                            readOnly={!editing}
                            onChange={(e) => handleTimePriceChange('FifthPrice', e.target.value)}
                            value={timeAndPrice.FifthPrice.price}
                            className={`${!editing ? AuthCSS['no-border-bottom'] : ''}`}
                        />
                        <span>CHF</span>
                        {showingFields === 5 ?
                            <button
                                className={AuthCSS['delete-field']}
                                onClick={() => setShowingFields(4)}
                            >
                                <FontAwesomeIcon icon={faXmark} color='red'/>
                            </button> : null}
                    </div>
                </li>:null}
            </ul>
        </div>
    )
}

export default EditPrices