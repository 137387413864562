import ContentSectionCSS from '../../ContentSectionProvider/ContentSection.module.css';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../common/utils';
import { useRef, useState, useEffect } from 'react';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import { selectPremiumPostById } from '../../../features/premiumPosts/premiumPostsApiSlice';
import { useCheckPurchaseStatusQuery, useCreatePurchaseMutation } from '../../../features/premiumPosts/premiumPostsApiSlice';
import ImageIcon from '@mui/icons-material/Image'
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'

const FeedPremiumPost = ({ postId, isImage, isVideo, loggedUser }) => {
    const videoRef = useRef();
    const [showPlayIcon, setShowPlayIcon] = useState(false);
    const [showPauseIcon, setShowPauseIcon] = useState(false);
    const [isPurchased, setIsPurchased] = useState(false);

    const post = useSelector((state) => selectPremiumPostById(state, postId));
    const userId = loggedUser?._id;

    const { data: purchaseStatus } = useCheckPurchaseStatusQuery({ userId, premiumPostId: postId });
    const [purchasePremiumPost] = useCreatePurchaseMutation();

    useEffect(() => {
        if (purchaseStatus?.isPurchased) {
            setIsPurchased(true);
        }
    }, [purchaseStatus]);

    const handleVideoPlay = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setShowPlayIcon(true);
                setTimeout(() => setShowPlayIcon(false), 300);
            } else {
                videoRef.current.pause();
                setShowPauseIcon(true);
                setTimeout(() => setShowPauseIcon(false), 300);
            }
        }
    };

    const handlePurchase = async () => {
        try {
            await purchasePremiumPost({ userId, premiumPostId: postId }).unwrap();
            setIsPurchased(true);
        } catch (error) {
            console.error('Error during purchase:', error);
        }
    };

    if (post && ((post.type === 'image' && !isVideo) || (post.type === 'video' && !isImage))) {
        return (
            <div className={ContentSectionCSS['single-post']}>
                <div className={ContentSectionCSS['media-container']}>
                    {isPurchased ? (
                        post.type === 'image' ? (
                            <img src={post?.url} alt="gallery-placeholder" />
                        ) : (
                            <video ref={videoRef} onClick={handleVideoPlay}>
                                <source src={post.url} />
                                Your browser does not support the video tag.
                            </video>
                        )
                    ) : (
                        <>
                            {/* Blurred content overlay when not purchased */}
                            <img src={post.blurredUrl} alt='blurred-content' className={ContentSectionCSS['blurred-image']} />
                            <div className={ContentSectionCSS['post-blur']}>
                                <h2>{post.title}</h2>
                                <span>{post.type === 'image' ? <ImageIcon /> : <SmartDisplayIcon/>} {post.duration ? `${post.duration} sec` : ""}</span>
                                <button onClick={handlePurchase} className={ContentSectionCSS['buy-button']}>
                                    Access now: ${post.price || 'Price'}
                                </button>
                            </div>
                        </>
                    )}
                    {post.type === 'video' && (
                        <>
                            <PlayArrowRoundedIcon
                                className={`${ContentSectionCSS['play-icon']} ${showPlayIcon ? ContentSectionCSS['show'] : ContentSectionCSS['hide']}`}
                            />
                            <PauseRoundedIcon
                                className={`${ContentSectionCSS['pause-icon']} ${showPauseIcon ? ContentSectionCSS['show'] : ContentSectionCSS['hide']}`}
                            />
                        </>
                    )}
                </div>
                <div className={ContentSectionCSS['text-content']}>
                    <div>
                        <p>{post?.description ? post.description : '.'}</p>
                        <span>{formatDate(post?.createdAt)}</span>
                    </div>
                    <div>
                        <LocalFireDepartmentOutlinedIcon />
                        <span>{post?.likes?.length}</span>
                    </div>
                </div>
            </div>
        );
    } else return null;
};

export default FeedPremiumPost;
