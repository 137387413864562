import React, {useEffect, useState} from 'react'
import AuthCSS from './Auth.module.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faArrowRight,
    faCopy,
    faEye,
    faEyeSlash,
    faLocationDot,
    faArrowLeft,
    faXmark,
    faPlus
} from '@fortawesome/free-solid-svg-icons'
import SignupFirstStep from './SignupSteps/SignupFirstStep'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import logo from '../../assets/img/watermark-pink.png'
import {Link, useNavigate} from 'react-router-dom'
import {useAddNewUserMutation} from '../user/usersApiSlice'
import SignupLocation from './SignupLocation'
import PulseLoader from "react-spinners/PulseLoader";
import {DatePicker} from "@mui/x-date-pickers";
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import RequestManagingAccountModal from './RequestManagingAccountModal'

const theme = createTheme({
    palette: {
        primary: {
            main: '#F92E85FF',
        },
    },
})
const acc_types ={'Content Creator': 'provider','User': 'user'}
const USERNAME_REGEX = /^[a-zA-Z0-9_-]{3,15}$/
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const PASSWORD_REGEX = /^.{6,}$/
const PHONE_REGEX = /^\+?\d{7,14}$/
const DESC_REGEX = /^.{10,}$/

const Signup = ({setShowSuccessMessage}) => {

    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation()

    //region UseState
    const [requestOpened, setRequestOpened] = useState(false)
    const navigate = useNavigate()
    const [step, setStep] = useState(0)
    const [username, setUsername] = useState('')
    const [validUsername, setValidUsername] = useState(false)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [confirmedPassword, setConfirmedPassword] = useState('')
    const [validConfirmedPassword, setValidConfirmedPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [age, setAge] = useState('')
    const [validAge, setValidAge] = useState(false)
    const [gender, setGender] = useState('')
    const [accountType, setAccountType] = useState([])
    const [accountTypes, setAccountTypes] = useState(['Content Creator','User'])
    const [phoneNumber, setPhoneNumber] = useState('')
    const [validPhoneNumber, setValidPhoneNumber] = useState(false)
    const [partnerName, setPartnerName] = useState('')
    const [validPartnerName, setValidPartnerName] = useState(false)
    const [website, setWebsite] = useState('')
    const [validWebsite, setValidWebsite] = useState(false)
    const [isPartnerActive, setIsPartnerActive] = useState(false)
    const [meetings, setMeetings] = useState(false)
    const [location, setLocation] = useState(null)
    const [description, setDescription] = useState('')
    const [validDescription, setValidDescription] = useState(false)
    const [telefon, setTelefon] = useState('')
    const [validTelefon, setValidTelefon] = useState(false)
    const [whatsApp, setWhatsApp] = useState('')
    //endregion


    const canGoNext = [validUsername, validEmail, validPassword, validConfirmedPassword, accountType.length > 0].every(Boolean)
    const [canNext, setCanNext] = useState(false)
    const canFinish = true

    //region ValidationClasses
    const validUsernameClass = !validUsername && username !== '' ? `${AuthCSS['incomplete-input']}` : ''
    const validEmailClass = !validEmail && email !== '' ? `${AuthCSS['incomplete-input']}` : ''
    const validPasswordClass = !validPassword && password !== '' ? `${AuthCSS['incomplete-input']}` : ''
    const validConfirmedPasswordClass = !validConfirmedPassword && confirmedPassword !== '' ? `${AuthCSS['incomplete-input']}` : ''
    const validAgeClass = !validAge && age !== '' ? `${AuthCSS['incomplete-input']}` : ''
    const validPhoneNumberClass = !validPhoneNumber && phoneNumber !== '' ? `${AuthCSS['incomplete-input']}` : ''
    const validWebsiteClass = !validWebsite && website !== '' ? `${AuthCSS['incomplete-input']}` : ''
    const validPartnerNameClass = !validPartnerName && partnerName !== '' ? `${AuthCSS['incomplete-input']}` : ''
    const validTelefonClass = !validTelefon && telefon !== '' ? `${AuthCSS['incomplete-input']}` : ''
    const validDescriptionClass = !validDescription && description !== '' ? `${AuthCSS['incomplete-input']}` : ''
    //endregion

    //region OnChangeFunctions
    const onUsernameChanged = (e) => setUsername(e.target.value)
    const onEmailChanged = (e) => setEmail(e.target.value)
    const onPasswordChanged = (e) => setPassword(e.target.value)
    const onConfirmedPasswordChanged = (e) => setConfirmedPassword(e.target.value)
    const onAgeChanged = (e) => setAge(e.target.value)
    const onGenderChanged = (e) => {
        setGender(e.target.value)
        if (e.target.value === '') {
            e.target.className = `${AuthCSS['select-default']}`
        } else {
            e.target.className = `${AuthCSS['select-active']}`
        }
    }
    const onAccountTypeChanged = (e) => {
        setAccountType([e.target.value])
        if (e.target.value === '') {
            e.target.className = `${AuthCSS['select-default']}`
        } else {
            e.target.className = `${AuthCSS['select-active']}`
        }
    }
    const onPhoneNumberChanged = (e) => setPhoneNumber(e.target.value)
    const onWebsiteChanged = (e) => setWebsite(e.target.value)
    const onDescriptionChanged = (e) => setDescription(e.target.value)
    const onTelefonChanged = (e) => setTelefon(e.target.value)
    const onWhatsappChanged = (e) => setWhatsApp(e.target.value)
    const onPartnerNameChanged = (e) => setPartnerName(e.target.value)
    //endregion

    //region ArraysWithValues
    const [isToggled, setIsToggled] = useState(false)
    const [languages, setLanguages] = useState(['english', 'german', 'spanish', 'french', 'italian', 'russian', 'serbian', 'albanian', 'romanian', 'other'])
    const [profileType, setProfileType] = useState(['Independent', 'Escort', 'Club', 'Sauna Club', 'Studio'])
    const [genders,setGenders] = useState(['Male','Female','Trans','Other'])
    const [sexualOrientation, setSexualOrientation] = useState(['Straight', 'Gay', 'Bisexual', 'Other'])
    const [attributes, setAttributes] = useState(['Smoking', 'Implants', 'Alcohol', 'Shaved', 'Tattoos'])
    const [services, setServices] = useState(['Anal', 'Deep Throat', 'Dinner Date', 'Dirtytalk', 'Domination (hard)', 'Domination (soft)', 'Escort', 'Facesitting', 'Fisting', 'Footjob', 'French', 'Gangbang', 'Girlfriend Sex', 'Handjob', 'Kamasutra', 'Kissing', 'Licking (active)', 'Licking (passive)', 'Massage', 'Masturbation', 'Open for relationsship', 'Pissing (active)', 'Pissing (passive)', 'Position 69', 'Prostatamassage', 'Roleplay', 'Sell clothing', 'Sell photos / videos', 'Sex in different positions', 'Sexchat', 'Strapon', 'Striptease', 'Tantra', 'Toys (active)', 'Toys (passive)', 'Webcam'])
    const [selectedLanguages, setSelectedLanguages] = useState([])
    const [selectedProfileType, setSelectedProfileType] = useState([])
    const [selectedSexualOrientation, setSelectedSexualOrientation] = useState([])
    const [selectedAttributes, setSelectedAttributes] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [isMeetingToggled, setIsMeetingToggled] = useState(false)
    const [openTimePicker, setOpenTimePicker] = useState(false)
    const [openDatePicker, setOpenDatePicker] = useState(false)
    const [selectedTime, setSelectedTime] = useState(dayjs().format('HH:mm'))
    const [selectedDay, setSelectedDay] = useState('')
    const [selectedDate, setSelectedDate] = useState(dayjs())
    const [openClose, setOpenClose] = useState(0) //1 mean opening hours, 2 means closing hours
    const [times, setTimes] = useState({
        Monday: {opening: null, closing: null},
        Tuesday: {opening: null, closing: null},
        Wednesday: {opening: null, closing: null},
        Thursday: {opening: null, closing: null},
        Friday: {opening: null, closing: null},
        Saturday: {opening: null, closing: null},
        Sunday: {opening: null, closing: null}
    })
    const [copyState, setCopyState] = useState(true)
    const [coppiedTime, setCoppiedTime] = useState({opening: null, closing: null})
    const [prices, setPrices] = useState({
        thirtyMins: '',
        oneHour: '',
        twoHours: ''
    })
    const [timeAndPrice, setTimeAndPrice] = useState({
        FirstPrice: {duration: '15', unit: 'min', price: ''},
        SecondPrice: {duration: '30', unit: 'min', price: ''},
        ThirdPrice: {duration: '1', unit: 'h', price: ''},
        FourthPrice: {duration: '', unit: 'h', price: ''},
        FifthPrice: {duration: '', unit: 'h', price: ''}
    })
    const [showingFields, setShowingFields] = useState(3)
    //endregion

    useEffect(() => {
        console.log(location)
    }, [step])
    useEffect(() => {
        if (step === 1) setCanNext([validAge, gender !== '', selectedLanguages.length > 0].every(Boolean))
        if (step === 2) setCanNext([(!isToggled || location !== null), selectedProfileType.length > 0].every(Boolean))
        if (step === 3) setCanNext([selectedSexualOrientation.length > 0 && selectedAttributes.length > 0].every(Boolean))
        if (step === 4) setCanNext([selectedServices.length > 0].every(Boolean))
        if (step === 5) setCanNext([description.length > 9].every(Boolean))
    }, [step, validAge, gender, selectedLanguages, validPhoneNumber, partnerName, selectedProfileType, selectedSexualOrientation, selectedAttributes, selectedServices, description, isPartnerActive, isToggled, location])
    useEffect(() => {
        setValidUsername(USERNAME_REGEX.test(username))
    }, [username])
    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])
    useEffect(() => {
        setValidPassword(PASSWORD_REGEX.test(password))
    }, [password])
    useEffect(() => {
        setValidConfirmedPassword(validPassword && password === confirmedPassword)
    }, [confirmedPassword])
    useEffect(() => {
        setValidAge(age >= 18 && age <= 99)
    }, [age])
    useEffect(() => {
        setValidPhoneNumber(PHONE_REGEX.test(phoneNumber))
    }, [phoneNumber])
    useEffect(() => {
        setValidPartnerName(partnerName?.length > 0)
    }, [partnerName])
    useEffect(() => {
        setValidTelefon(PHONE_REGEX.test(telefon))
    }, [telefon])
    useEffect(() => {
        setValidDescription(DESC_REGEX.test(description))
    }, [description])
    useEffect(() => {
        if (selectedProfileType.includes('Independent')) setIsPartnerActive(false)
        else setIsPartnerActive(true)
    }, [selectedProfileType])

    const handleTimeChange = (newTime) => {
        if (newTime && typeof newTime.format === 'function') {
            const formattedTime = newTime.format('HH:mm');
            setSelectedTime(formattedTime);
        }
    }

    const handleDateChange = (newDate) => {
        if (newDate && typeof newDate.format === 'function') {
            setSelectedDate(newDate);

            // Calculate age
            const today = dayjs();
            const birthDate = dayjs(newDate);
            let tempAge = today.year() - birthDate.year();

            // Adjust the age if the current date is before the birthday in the current year
            if (today.month() < birthDate.month() || (today.month() === birthDate.month() && today.date() < birthDate.date())) {
                tempAge--;
            }

            setAge(tempAge);
        }
    }
    const handleTimeSelected = (newTime) => {
        const formattedTime = newTime.format('HH:mm')

        switch (selectedDay) {
            case 'Monday':
                if (openClose === 1) {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Monday: {
                            ...prevTimes.Monday,
                            opening: formattedTime
                        }
                    }))
                } else {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Monday: {
                            ...prevTimes.Monday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Tuesday':
                if (openClose === 1) {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Tuesday: {
                            ...prevTimes.Tuesday,
                            opening: formattedTime
                        }
                    }))
                } else {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Tuesday: {
                            ...prevTimes.Tuesday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Wednesday':
                if (openClose === 1) {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Wednesday: {
                            ...prevTimes.Wednesday,
                            opening: formattedTime
                        }
                    }))
                } else {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Wednesday: {
                            ...prevTimes.Wednesday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Thursday':
                if (openClose === 1) {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Thursday: {
                            ...prevTimes.Thursday,
                            opening: formattedTime
                        }
                    }))
                } else {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Thursday: {
                            ...prevTimes.Thursday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Friday':
                if (openClose === 1) {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Friday: {
                            ...prevTimes.Friday,
                            opening: formattedTime
                        }
                    }))
                } else {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Friday: {
                            ...prevTimes.Friday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Saturday':
                if (openClose === 1) {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Saturday: {
                            ...prevTimes.Saturday,
                            opening: formattedTime
                        }
                    }))
                } else {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Saturday: {
                            ...prevTimes.Saturday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Sunday':
                if (openClose === 1) {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Sunday: {
                            ...prevTimes.Sunday,
                            opening: formattedTime
                        }
                    }))
                } else {
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Sunday: {
                            ...prevTimes.Sunday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            default :
                break
        }
    }

    const handleAcceptDate = (newDate) => {
        if (newDate) {
            setSelectedDate(newDate)
        }
    }


    const handleMeetingToggle = () => {
        if (!isToggled) setLocation(null)
        setIsToggled(!isToggled)
        setMeetings(!meetings)
    }
    const handleItemClick = (clickedItem, number) => {
        switch (number) {
            case 1:
                if
                (!selectedProfileType.includes(clickedItem)) {
                    setSelectedProfileType([clickedItem])
                }
                break
            case 2:
                if
                (!selectedSexualOrientation.includes(clickedItem)) {
                    setSelectedSexualOrientation([clickedItem])
                }
                break
            case 3:
                if
                (selectedAttributes.includes(clickedItem)) {
                    const newTypes = selectedAttributes.filter(item => item !== clickedItem)
                    setSelectedAttributes(newTypes)
                } else {
                    setSelectedAttributes([...selectedAttributes, clickedItem])
                }
                break
            case 4:
                if
                (selectedServices.includes(clickedItem)) {
                    const newTypes = selectedServices.filter(item => item !== clickedItem)
                    setSelectedServices(newTypes)
                } else {
                    setSelectedServices([...selectedServices, clickedItem])
                }
                break
            case 5:
                if (selectedLanguages.includes(clickedItem)) {
                    const newLangs = selectedLanguages.filter(item => item !== clickedItem)
                    setSelectedLanguages(newLangs)
                } else {
                    setSelectedLanguages([...selectedLanguages, clickedItem])
                }
                break
            case 6:
                if (gender !== clickedItem){
                    setGender(clickedItem)
                }
                break
            case 7:
                if
                (!accountType.includes(clickedItem)) {
                    setAccountType([clickedItem])
                }
                break
            default:
                break
        }
    }
    const handleNextStep = () => {
        if(step === 5 && !meetings) setStep(step + 2)
        else setStep(step + 1)
    }
    const handleSignupButton = async () => {
        if (accountType[0] === 'provider') {
            setStep(step + 1);
        } else {
            try {
                await handleCreateUser();
                console.log('User created successfully');
            } catch (error) {
                console.error('Error creating user:', error);
            }
        }
    }

    const handlePrevStep = () => {
        if(step === 7 && !meetings) setStep(step - 2)
        else setStep(step - 1)
    }

    const handleOpenTimePicker = (day, time) => {
        setOpenTimePicker(true)
        setSelectedDay(day)
        setOpenClose(time)
    }
    const handleOpenDatePicker = (day, time) => {
        setOpenDatePicker(true)
    }
    const handleCopyTime = (day) => {
        setCoppiedTime(times[day])
        setCopyState(false)
    }
    const handlePasteTime = (day) => {
        setTimes(prevTimes => ({...prevTimes, [day]: coppiedTime}))
        setCopyState(true)
    }
    const handleDeleteTime = (day) => {
        setTimes(prevTimes => ({...prevTimes, [day]: {opening: null, closing: null}}))
    }
    const handleTimeDurationChange = (key, newValue) => {
        setTimeAndPrice((prevTimeAndPrice) => ({
            ...prevTimeAndPrice,
            [key]: {
                ...prevTimeAndPrice[key],
                duration: newValue
            }
        }))
    }
    const handleTimePriceChange = (key, newValue) => {
        setTimeAndPrice((prevTimeAndPrice) => ({
            ...prevTimeAndPrice,
            [key]: {
                ...prevTimeAndPrice[key],
                price: newValue
            }
        }))
    }
    const handleTimeUnitChange = (key, newValue) => {
        setTimeAndPrice((prevTimeAndPrice) => ({
            ...prevTimeAndPrice,
            [key]: {
                ...prevTimeAndPrice[key],
                unit: newValue
            }
        }))
    }

    const handleCreateUser = async () => {

        const emptyLocation = {
            address: '',
            city: '',
            state: '',
            country: '',
            zipCode: '',
            coordinates: {
                type: 'Point',
                coordinates: [0, 0]
            }
        };

        try {
            const userData = {
                username,
                email,
                password,
                roles: accountType,
                age,
                birthDate: selectedDate,
                gender,
                language: selectedLanguages,
                location,
                profileType: selectedProfileType,
                phoneNumber,
                website,
                club: partnerName,
                meetings,
                sexualOrientation: selectedSexualOrientation,
                attributes: selectedAttributes,
                services: selectedServices,
                description,
                availability: times,
                prices: timeAndPrice,
                whatsApp
            }

            await addNewUser(userData)
            navigate('/dash/login')
            setShowSuccessMessage(true)
        } catch (error) {
            console.error('Error in onSaveUserClicked:', error)
        }
    }

    const renderProgressBar = () => {
        const steps = 7
        const progress = (step / steps) * 100

        return (
            <div className={AuthCSS['progress-wrapper']}>
                <h4>Create your Profile</h4>
                <div className={AuthCSS['progress-bar-container']}>
                    <div
                        className={AuthCSS['progress-bar']}
                        style={{width: `${progress}%`}}
                    ></div>
                </div>
            </div>
        )
    }

    const renderStepContent = () => {
        switch (step) {
            case 0:
                return (
                    <div
                        className={`container ${AuthCSS['login-container']} ${AuthCSS['step-zero-container']} ${AuthCSS['signup-container']}`}>
                        <div className={AuthCSS['logo-container']}>
                            <img src={logo} alt="CamNextDoor" onClick={() => navigate('/')}/>
                        </div>
                        <div className={AuthCSS['input-fields']}>
                            <ul className={AuthCSS['select-list']}>
                                {accountTypes.map((item, index) => {
                                    return (<li
                                        onClick={() => handleItemClick(acc_types[item], 7)}
                                        key={index}
                                        className={accountType.includes(acc_types[item]) ? `${AuthCSS['active-item']}` : ``}
                                    >{item}</li>)
                                })}
                            </ul>
                            <input
                                className={validUsernameClass}
                                type="text"
                                id="username_id"
                                placeholder="Username"
                                autoComplete="off"
                                value={username}
                                onChange={onUsernameChanged}
                                required
                            />
                            <input
                                className={validEmailClass}
                                type="email"
                                id="email"
                                placeholder="E-Mail"
                                autoComplete="off"
                                value={email}
                                onChange={onEmailChanged}
                                required
                            />
                            <div className={AuthCSS['pwd-container']}>
                                <input
                                    className={validPasswordClass}
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    placeholder="Password"
                                    autoComplete="off"
                                    value={password}
                                    onChange={onPasswordChanged}
                                    required
                                />
                                {showPassword
                                    ? <FontAwesomeIcon icon={faEye} onClick={() => setShowPassword(!showPassword)}/>
                                    :
                                    <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShowPassword(!showPassword)}/>
                                }
                            </div>
                            <div className={AuthCSS['pwd-container']}>
                                <input
                                    className={validConfirmedPasswordClass}
                                    type={showPassword ? 'text' : 'password'}
                                    id="confirmPassword"
                                    placeholder="Confirm Password"
                                    autoComplete="off"
                                    value={confirmedPassword}
                                    onChange={onConfirmedPasswordChanged}
                                    required
                                />
                                {showPassword
                                    ? <FontAwesomeIcon icon={faEye} onClick={() => setShowPassword(!showPassword)}/>
                                    :
                                    <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShowPassword(!showPassword)}/>
                                }
                            </div>
                        </div>
                        <div className={AuthCSS['login-buttons']}>
                            <button disabled={!canGoNext} onClick={handleSignupButton}><FontAwesomeIcon
                                icon={faArrowRight}/>Sign Up
                            </button>
                            <p className={AuthCSS['separator']}>or</p>
                            <Link to="/dash/login">Log In</Link>
                            <div className={AuthCSS['request-links']}>
                                <p className={AuthCSS['request-account']}
                                   onClick={() => navigate('/dash/request-manager-account')}>Request managing
                                    account. <OpenInNewIcon/>
                                </p>
                                <p className={AuthCSS['separator']}>or</p>
                                <p className={AuthCSS['request-account']}
                                   onClick={() => navigate('/dash/request-manager-account')}>Request advertisement
                                    account. <OpenInNewIcon/>
                                </p>
                            </div>
                        </div>
                    </div>
                )
            case 1:
                return (
                    <div className={AuthCSS['input-fields']}>
                        <h3>Age (Birthday)</h3>
                        <input
                            type="text"
                            id="birthDate"
                            className={validAgeClass}
                            placeholder="Date of Birth"
                            name="birthDate"
                            autoComplete="off"
                            onClick={() => setOpenDatePicker(true)}
                            value={selectedDate.format('DD-MM-YYYY')}
                            required
                            readOnly
                        />
                        {!validAge && age !== '' && step === 1 ?
                            <p className={AuthCSS['age-disclaimer']}>You have to be over 18 to use our services!</p> : null}
                        <h3 className={AuthCSS['lang-heading']}>Gender</h3>
                        <ul className={AuthCSS['select-list']}>
                            {genders.map((item, index) => {
                                return (<li
                                    onClick={() => handleItemClick(item.toLowerCase(), 6)}
                                    key={index}
                                    className={gender === item.toLowerCase() ? `${AuthCSS['active-item']}` : ``}
                                >{item}</li>)
                            })}
                        </ul>
                        <h3 className={AuthCSS['lang-heading']}>Select languages</h3>
                        <ul className={AuthCSS['select-list']}>
                            {languages.map((item, index) => {
                                return (<li
                                    style={{textTransform: "capitalize"}}
                                    onClick={() => handleItemClick(item, 5)}
                                    key={index}
                                    className={selectedLanguages.includes(item) ? `${AuthCSS['active-item']}` : ``}
                                >{item}</li>)
                            })}
                        </ul>
                        <ThemeProvider theme={theme}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    className={AuthCSS['date-picker']}
                                    open={openDatePicker}
                                    onOpen={() => setOpenDatePicker(true)}
                                    onClose={() => setOpenDatePicker(false)}
                                    onAccept={handleAcceptDate}
                                    onChange={handleDateChange}
                                    value={selectedDate}
                                    label='Choose Your Birth Data'
                                    renderInput={(params) => (
                                        <input
                                            onClick={params.inputProps.onClick} // Link input props provided by MUI
                                            value={selectedDate.format('DD-MM-YYYY')}
                                            readOnly
                                            className={AuthCSS['input-field']}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </ThemeProvider>

                    </div>
                )
            case 2:
                return (
                    <div className={AuthCSS['input-fields']}>
                        <h3>Profile Type</h3>
                        <ul className={AuthCSS['select-list']}>
                            {profileType.map((item, index) => {
                                return (<li
                                    onClick={() => handleItemClick(item, 1)}
                                    key={index}
                                    className={selectedProfileType.includes(item) ? `${AuthCSS['active-item']}` : ``}
                                >{item}</li>)
                            })}
                        </ul>
                        {(selectedProfileType.includes('Club') || selectedProfileType.includes('Sauna Club') || selectedProfileType.includes('Studio') || selectedProfileType.includes('Escort')) ?
                            <>
                                <input
                                    type='text'
                                    id='partnerName'
                                    className={validPartnerNameClass}
                                    placeholder='Partner name (optional)'
                                    autoComplete='off'
                                    value={partnerName}
                                    onChange={onPartnerNameChanged}
                                    required
                                />
                                <input
                                    type='text'
                                    id='partnerWebsite'
                                    placeholder='Partner website (optional)'
                                    autoComplete='off'
                                    value={website}
                                    onChange={onWebsiteChanged}
                                    required
                                />
                            </>
                            : null
                        }
                        <div className={AuthCSS['meetings']}>
                            <h4>Open for meetings</h4>
                            <label className={AuthCSS['toggle-switch']}>
                                <input type='checkbox' checked={isToggled} onChange={handleMeetingToggle}/>
                                <span className={AuthCSS['switch']}/>
                            </label>
                        </div>
                        {isToggled ?
                            <SignupLocation location={location} setLocation={setLocation} isToggled={isToggled}/>
                            : null
                        }
                    </div>
                )
            case 3:
                return (
                    <div className={`${AuthCSS['step-three']} ${AuthCSS['input-fields']}`}>
                        <h3>Sexual Orientation</h3>
                        <ul className={AuthCSS['select-list']}>
                            {sexualOrientation.map((item, index) => {
                                return (<li
                                    onClick={() => handleItemClick(item, 2)}
                                    key={index}
                                    className={selectedSexualOrientation.includes(item) ? `${AuthCSS['active-item']}` : ``}
                                >{item}</li>)
                            })}
                        </ul>
                        <h3>My Attributes</h3>
                        <ul className={AuthCSS['select-list']}>
                            {attributes.map((item, index) => {
                                return (<li
                                    onClick={() => handleItemClick(item, 3)}
                                    key={index}
                                    className={selectedAttributes.includes(item) ? `${AuthCSS['active-item']}` : ``}
                                >{item}</li>)
                            })}
                        </ul>
                    </div>
                )
            case 4:
                return (
                    <div className={`${AuthCSS['step-four']} ${AuthCSS['input-fields']}`}>
                        <h3>My Services</h3>
                        <ul className={AuthCSS['select-list']}>
                            {services.map((item, index) => {
                                return (<li
                                    onClick={() => handleItemClick(item, 4)}
                                    key={index}
                                    className={selectedServices.includes(item) ? `${AuthCSS['active-item']}` : ``}
                                >{item}</li>)
                            })}
                        </ul>
                    </div>
                )
            case 5:
                return (
                    <div className={`${AuthCSS['step-five']} ${AuthCSS['input-fields']}`}>
                        <textarea
                            id='aboutMe'
                            className={validDescriptionClass}
                            name='aboutMe'
                            placeholder='Describe yourself / your service'
                            value={description}
                            onChange={onDescriptionChanged}
                            rows='15'
                        />
                    </div>
                )
            case 6:
                return (
                    <div className={`${AuthCSS['step-six']} ${AuthCSS['input-fields']}`}>
                        <h3>I am available</h3>
                        <div className={AuthCSS['schedule-meeting']}>
                            <ul className={AuthCSS['days-list']}>
                                <li>Monday</li>
                                <li>Tuesday</li>
                                <li>Wednesday</li>
                                <li>Thursday</li>
                                <li>Friday</li>
                                <li>Saturday</li>
                                <li>Sunday</li>
                            </ul>
                            <ul className={AuthCSS['hours-values']}>
                                {Object.keys(times).map((day) => (
                                    <li key={day}>
                                        <div>
                                        <span onClick={() => handleOpenTimePicker(day, 1)}>
                                            {times[day] && times[day].opening ? times[day].opening : '__:__'}
                                        </span>
                                            <span>-</span>
                                            <span onClick={() => handleOpenTimePicker(day, 2)}>
                                            {times[day] && times[day].closing ? times[day].closing : '__:__'}
                                        </span>
                                            {copyState
                                                ? <>
                                                    <button className={AuthCSS['x-button']}
                                                            onClick={() => handleDeleteTime(day)}><FontAwesomeIcon
                                                        icon={faXmark}/></button>
                                                    <button className={AuthCSS['copy-button']}
                                                            onClick={() => handleCopyTime(day)}><FontAwesomeIcon
                                                        icon={faCopy}/></button>
                                                </>
                                                : <button className={AuthCSS['paste-button']}
                                                          onClick={() => handlePasteTime(day)}><FontAwesomeIcon
                                                    icon={faArrowLeft}/></button>
                                            }
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <h3 className={AuthCSS['prices-heading']}>My prices</h3>
                        <ul className={AuthCSS['price-list']}>
                            <li>
                                <div>
                                    <input type='number' value={timeAndPrice.FirstPrice.duration}
                                           onChange={(e) => handleTimeDurationChange('FirstPrice', e.target.value)}/>
                                    <select name='fistPrice' value={timeAndPrice.FirstPrice.unit}
                                            onChange={(e) => handleTimeUnitChange('FirstPrice', e.target.value)}>
                                        <option>min</option>
                                        <option>h</option>
                                    </select>
                                </div>
                                <div><input
                                    type='number'
                                    name='thirtyMin'
                                    id='thirtyMin'
                                    onChange={(e) => handleTimePriceChange('FirstPrice', e.target.value)}
                                    value={timeAndPrice.FirstPrice.price}
                                /><span>CHF</span></div>
                            </li>
                            <li>
                                <div>
                                    <input type='number' value={timeAndPrice.SecondPrice.duration}
                                           onChange={(e) => handleTimeDurationChange('SecondPrice', e.target.value)}/>
                                    <select name='secondPrice' value={timeAndPrice.SecondPrice.unit}
                                            onChange={(e) => handleTimeUnitChange('SecondPrice', e.target.value)}>
                                        <option>min</option>
                                        <option>h</option>
                                    </select>
                                </div>
                                <div><input
                                    type='number'
                                    name='oneHour'
                                    id='oneHour'
                                    onChange={(e) => handleTimePriceChange('SecondPrice', e.target.value)}
                                    value={timeAndPrice.SecondPrice.price}
                                /><span>CHF</span></div>
                            </li>
                            <li>
                                <div>
                                    <input type='number' value={timeAndPrice.ThirdPrice.duration}
                                           onChange={(e) => handleTimeDurationChange('ThirdPrice', e.target.value)}/>
                                    <select name='thirdPrice' value={timeAndPrice.ThirdPrice.unit}
                                            onChange={(e) => handleTimeUnitChange('ThirdPrice', e.target.value)}>
                                        <option>min</option>
                                        <option>h</option>
                                    </select>
                                </div>
                                <div><input
                                    type='number'
                                    name='twoHours'
                                    id='twohours'
                                    onChange={(e) => handleTimePriceChange('ThirdPrice', e.target.value)}
                                    value={timeAndPrice.ThirdPrice.price}
                                /><span>CHF</span></div>
                            </li>
                            {showingFields > 3 ?
                                <li>
                                    <div>
                                        <input type='number' value={timeAndPrice.FourthPrice.duration}
                                               onChange={(e) => handleTimeDurationChange('FourthPrice', e.target.value)}/>
                                        <select name='fourthPrice' value={timeAndPrice.FourthPrice.unit}
                                                onChange={(e) => handleTimeUnitChange('FourthPrice', e.target.value)}>
                                            <option>min</option>
                                            <option>h</option>
                                        </select>
                                    </div>
                                    <div><input
                                        type='number'
                                        name='twoHours'
                                        id='twohours'
                                        onChange={(e) => handleTimePriceChange('FourthPrice', e.target.value)}
                                        value={timeAndPrice.FourthPrice.price}
                                    /><span>CHF</span></div>
                                    {showingFields === 4 ?
                                        <button
                                            className={AuthCSS['delete-field']}
                                            onClick={() => setShowingFields(3)}
                                        >
                                            <FontAwesomeIcon icon={faXmark} color='red'/>
                                        </button> : null}
                                </li>
                                : null}
                            {showingFields > 4 ?
                                <li>
                                    <div>
                                        <input type='number' value={timeAndPrice.FifthPrice.duration}
                                               onChange={(e) => handleTimeDurationChange('FifthPrice', e.target.value)}/>
                                        <select name='fifthPrice' value={timeAndPrice.FifthPrice.unit}
                                                onChange={(e) => handleTimeUnitChange('FifthPrice', e.target.value)}>
                                            <option>min</option>
                                            <option>h</option>
                                        </select>
                                    </div>
                                    <div>
                                        <input
                                            type='number'
                                            name='twoHours'
                                            id='twohours'
                                            onChange={(e) => handleTimePriceChange('FifthPrice', e.target.value)}
                                            value={timeAndPrice.FifthPrice.price}
                                        />
                                        <span>CHF</span>
                                        {showingFields === 5 ?
                                            <button
                                                className={AuthCSS['delete-field']}
                                                onClick={() => setShowingFields(4)}
                                            >
                                                <FontAwesomeIcon icon={faXmark} color='red'/>
                                            </button> : null}
                                    </div>
                                </li>
                                : null}

                            {showingFields !== 5 ?
                                <li className={AuthCSS['add-price-field']}>
                                    <button
                                        onClick={() => {
                                            if (showingFields < 5) setShowingFields(showingFields + 1)
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus}/></button>
                                </li>
                                : null
                            }
                        </ul>
                        <ThemeProvider theme={theme}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    className={AuthCSS['time-picker']}
                                    open={openTimePicker}
                                    onOpen={() => setOpenTimePicker(true)}
                                    onClose={() => setOpenTimePicker(false)}
                                    onAccept={handleTimeSelected}
                                    onChange={handleTimeChange}
                                    ampm={false}
                                    value={selectedTime}
                                    label='Choose Your Time'
                                    minutesStep={10}
                                />
                            </LocalizationProvider>
                        </ThemeProvider>
                    </div>
                )
            case 7:
                return (
                    <div className={`${AuthCSS['step-seven']} ${AuthCSS['input-fields']}`}>
                        <h3>Contact Information</h3>
                        <p className={AuthCSS['heading-label']}>If you want to be contacted by other users, please fill out your contact information:</p>
                        <input
                            className={validPhoneNumberClass}
                            type='text'
                            id='phoneNumber'
                            placeholder='Phone Number (optional)'
                            autoComplete='off'
                            value={phoneNumber}
                            onChange={onPhoneNumberChanged}
                            required
                        />
                        <input
                            type='text'
                            id='whatsapp'
                            onChange={onWhatsappChanged}
                            placeholder='Whatsapp (optional)'
                            autoComplete='off'
                            required
                        />
                    </div>
                )
            default:
                return null
        }
    }
    // Retrieve the item from local storage
    const isEighteen = localStorage.getItem('isEighteen');

    // Define the height based on the value of isEighteen
    const divStyle = {
        height: isEighteen === 'true' ? 'calc(100vh - 64px)' : '100%'
    }

    return (
        <div className={AuthCSS['step-container']} style={divStyle}>
            {step !== 0 ? renderProgressBar() : null}
            {renderStepContent()}
            {step > 0 && <div className={`${AuthCSS['step-buttons']}`}>
                {
                    step > 0 && (
                        <>
                            {step > 0 && (
                                <button className={AuthCSS['back-button']} onClick={handlePrevStep}>
                                    Back
                                </button>
                            )}
                            {step < 7 ? (
                                <button onClick={handleNextStep} disabled={!canNext}>
                                    Next
                                </button>
                            ) : (
                                !isLoading ?
                                    <button
                                        disabled={!canFinish}
                                        onClick={handleCreateUser}
                                    >
                                        Finish
                                    </button>
                                    : <PulseLoader className={AuthCSS['loader']} color='#F92E85FF'/>
                            )}
                        </>
                    )
                }
            </div>
            }
            <RequestManagingAccountModal
                requestOpened={requestOpened}
                setRequestOpened={setRequestOpened}
                closeModal={() => setRequestOpened(false)}
            />
        </div>
    )
}

export default Signup
