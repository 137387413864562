import UserStoriesCSS from '../UserStories/UserStories.module.css'
import React, {useEffect, useState} from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import {useSelector} from 'react-redux'
import {selectStoryById, useDeleteStoryMutation, useUpdateStoryTextMutation} from '../../features/story/storiesApiSlice'
import DeleteStoryConfirmation from '../UserStories/DeleteStoryConfirmation'
import {useUpdateRemoveUserStoriesMutation} from '../../features/user/usersApiSlice'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faTrash,
    faVolumeMute,
    faVolumeUp,
    faXmark
} from '@fortawesome/free-solid-svg-icons'
import fireIcon from '../../assets/img/fire-icon.png'

const Story = ({currentStory, videoRefs, handleVideoEnd, storyCount, index, userId, currentSlide, isActive, textRefs}) => {

    const [UseUpdateRemoveUserStory,{
        isUploadLoading,
        isUploadError,
        isUploadSuccess
    }] = useUpdateRemoveUserStoriesMutation()

    const [deleteStory, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteStoryMutation()

    const [updateStoryText, {
        isSuccess: isUpdSuccess,
        isError: isUpdError,
        error: upderror
    }] = useUpdateStoryTextMutation()

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
    const [storyText,setStoryText] = useState('')
    const [initialStoryText,setInitialStoryText] = useState('')
    const story = useSelector(state => selectStoryById(state, currentStory))
    const [isEditing, setIsEditing] = useState(false)
    const [progress, setProgress] = useState(0)
    const [left, setLeft] = useState(0)
    const [isMuted, setIsMuted] = useState(false)

    useEffect(() => {
        setInitialStoryText(story?.storyText)
        setStoryText(story?.storyText)
        setIsEditing(false)
    }, [isActive])

    useEffect(() => {
        if(isActive !== index && textRefs.current[currentSlide]){
            textRefs.current[currentSlide].disabled = true
            textRefs.current[currentSlide].blur()
            setStoryText(initialStoryText)
            adjustTextareaHeight()
        }
    }, [isActive, index, currentSlide, textRefs])

    useEffect(() => {
        if(storyText !== '') adjustTextareaHeight()
    }, [storyText])

    useEffect(() => {
        setLeft((index / storyCount) * 100)
    }, [currentSlide])

    const handlePlayVideo = () => {
        const currentVideo = videoRefs.current[currentSlide]
        if (currentVideo) {
            currentVideo.play()
        }
    }

    const toggleMute = () => {
        setIsMuted(!isMuted)
        videoRefs.current.forEach(video => {
            if (video) {
                video.muted = !isMuted
            }
        })
    }

    const pauseVideoOnDelete = () => {
        const currentVideo = videoRefs.current[currentSlide]
        if (currentVideo) {
            currentVideo.pause()
        }
    }
    const handleDeleteStory = async () => {
        await deleteStory({ id: currentStory })
        await UseUpdateRemoveUserStory({ id: userId, storyId: currentStory })
        handlePlayVideo()
    }

    const adjustTextareaHeight = () => {
        if(textRefs.current[currentSlide]) {
            textRefs.current[currentSlide].style.height = '48px' // Reset height to recalculate
            textRefs.current[currentSlide].style.height = `${textRefs.current[currentSlide].scrollHeight}px` // Set new height
            console.log(`${textRefs.current[currentSlide].scrollHeight}px`)
        }
    }

    const handleTextChange = (e) => {
        if(textRefs.current[currentSlide]?.scrollHeight < 170) {
            setStoryText(e.target.value)
            adjustTextareaHeight()
        }
    }

    const handleEditText = async (e) => {
        e.preventDefault()
        setIsEditing(true)
        videoRefs.current[currentSlide].pause()
        textRefs.current[currentSlide].disabled = false
        textRefs.current[currentSlide].focus()
    }

    const handleSaveText = async () => {
        console.log('click')
        handlePlayVideo()
        setIsEditing(false)
        if(initialStoryText !== storyText) await updateStoryText({id: currentStory, newText: storyText})
    }

    return(
        <div className={`${UserStoriesCSS['story-wrapper']} ${UserStoriesCSS['my-story-wrapper']}`} key={index}>
            {story?.url ? (
                <>
                    <video
                        loop={storyCount === 1}
                        ref={el => videoRefs.current[index] = el}
                        onEnded={() => handleVideoEnd()}
                        onError={(e) => console.error('Video loading error:', e)}
                        playsInline
                        autoPlay={false}
                        onTimeUpdate={(e) => {
                            setProgress(e.target.currentTime / e.target.duration);
                        }}
                        muted={isMuted}
                    >
                        <source src={story?.url} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    <textarea
                        value={storyText}
                        ref={el => textRefs.current[index] = el}
                        onChange={handleTextChange}
                        className={storyText !== '' ? `${UserStoriesCSS['black-background']}` : ''}
                        type="text"
                        disabled={!isEditing}
                    />
                </>
            ) : <PulseLoader color={'#F92E85FF'} className="pulse-loader"/>
            }
            <div className={`${UserStoriesCSS['top-section-buttons']} ${UserStoriesCSS['top-section-button-left']}`}>
                <div className="blur-button blur-button-small blur-button-active">
                    <img src={fireIcon} alt="Custom Fire Icon"/>
                    <span>{story?.likes?.length}</span>
                </div>
            </div>
            <div className={UserStoriesCSS['top-section-buttons']}>
                <button
                    onClick={(e) => {
                        e.preventDefault()
                        pauseVideoOnDelete()
                        setIsConfirmationOpen(true)
                    }}
                    className={UserStoriesCSS['trash-button']}
                >
                    <FontAwesomeIcon icon={faTrash}/>
                </button>
                {isEditing ? (
                    <button
                        onClick={handleSaveText}
                        className={`${UserStoriesCSS['edit-text-button']} ${initialStoryText !== storyText ? UserStoriesCSS['highlight-button'] : ''}`}
                    >
                        {initialStoryText !== storyText ? <FontAwesomeIcon icon={faCheck}/> :
                            <FontAwesomeIcon icon={faXmark}/>}
                    </button>
                ) : (
                    <button
                        onClick={(e) => handleEditText(e)}
                        className={`${UserStoriesCSS['edit-text-button']}`}
                    >
                        Aa
                    </button>
                )}
                <button className="blur-button blur-button-small" onClick={toggleMute}>
                    <FontAwesomeIcon
                        icon={isMuted ? faVolumeMute : faVolumeUp}
                    />
                </button>
            </div>

            <DeleteStoryConfirmation
                isConfirmationOpen={isConfirmationOpen}
                closeModal={() => {
                    setIsConfirmationOpen(false)
                    handlePlayVideo()
                }}
                handleDeleteStory={handleDeleteStory}
            />
            <div className={UserStoriesCSS['loader-container']} style={{
                width: `calc(${100 / storyCount}% - ${storyCount}px)`,
                left: `calc(${left}% + 1px + ${index * 1}px)`
            }}>
                <div className={UserStoriesCSS['loader']} style={{width: `${progress * 100}%`}}>

                </div>
            </div>
        </div>
    )
}

export default Story