import React, {useEffect, useState} from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import {useSelector} from 'react-redux'
import MyStories from '../../MyStories/MyStories'
import CameraRecording from '../../CameraRecording/CameraRecording'
import {selectUserById, useGetUsersQuery} from '../../../features/user/usersApiSlice'

const MyStoriesParent = ({userId, permissionLevel}) => {

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [showLoader, setShowLoader] = useState(true)
    const [videoSrc, setVideoSrc] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const [currentStories, setCurrentStories] = useState([])

    const loggedUser = useSelector((state) => selectUserById(state, userId))

    useEffect(() => {
        if(loggedUser) {
            setCurrentStories(loggedUser.stories)
            setShowLoader(false)
        }
    }, [loggedUser])

    useEffect(() => {
        console.log(userId)
    }, [userId])

    let content

    if(showLoader){content = <PulseLoader color={'#F92E85FF'} className='pulse-loader'/>
    } else if(videoSrc && permissionLevel>=2) {
        content =
            <CameraRecording
                loggedUser={loggedUser}
                selectedFile={selectedFile}
                videoSrc={videoSrc}
                setVideoSrc={setVideoSrc}
            />
    } else {
        content =
            <MyStories
                loggedUser={loggedUser}
                setVideoSrc={setVideoSrc}
                setSelectedFile={setSelectedFile}
                currentStories={currentStories}
                premmisionLevel={permissionLevel}
            />
    }

    return content
}

export default MyStoriesParent