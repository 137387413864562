import Modal from 'react-modal'
import FileInputModalCSS from './FileInputModal.module.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFolderPlus, faXmark} from '@fortawesome/free-solid-svg-icons'
import {useEffect, useRef, useState} from 'react'
import {useUploadImageForVerificationMutation} from "../../features/story/imageApiSLice"
import {useVerifyImageMutation} from "../../features/user/usersApiSlice"
import PulseLoader from "react-spinners/PulseLoader";
import {useNavigate} from 'react-router-dom'

Modal.setAppElement('#root')

const FileInputModal = ({fileInputOpened, closeModal, user}) => {

    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedImageUrl, setSelectedImageUrl] = useState(null)
    const [imageSent, setImageSent] = useState(false)
    const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png']
    const [uploadError, setUploadError] = useState('')
    const [fileUrl, setFileUrl] = useState(null)
    const fileInputRef = useRef(null)

    const navigate = useNavigate()

    const [uploadImageForVerification,{
        isSuccess: isUploadSuccess,
        isLoading: isUploadLoading,
        isError: isUploadError,
        error: uploadingError
    }] = useUploadImageForVerificationMutation()

    const [verifyImage,{
        isSuccess: isVerifySuccess,
        isLoading: isVerifyLoading,
        isError: iseVerifyError,
        error: verifyError
    }] = useVerifyImageMutation()

    const handleFileInputClick = () => {
        if(!imageSent) fileInputRef?.current?.click()
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file)
            const imageUrl = URL.createObjectURL(file)
            setSelectedImageUrl(imageUrl)
        }
    }

    const handleImageUpload = async () => {
        if (!selectedImage) {
            console.error('No file selected')
            return Promise.reject(new Error('No file selected'))
        }

        if (!validFileTypes.includes(selectedImage.type)) {
            setUploadError('File must be in JPG/PNG format')
            return Promise.reject(new Error('File must be in JPG/PNG format'))
        }

        const formData = new FormData()
        formData.append('file', selectedImage)
        formData.append('email', user.email)
        formData.append('userId', user._id)

        try {
            const result = await uploadImageForVerification(formData)
            setFileUrl(result.data.fileUrl)
            return result.data.fileUrl
        } catch (error) {
            setUploadError('Error uploading image: ' + error.message)
            throw error
        }
    }

    const handleSendVerification = async () => {
        try {
            const imageUrl = await handleImageUpload()
            await verifyImage({ userId: user?._id, imageUrl: imageUrl })
            console.log('Report submitted successfully')
        } catch (error) {
            console.error('Failed to submit report:', error)
        }
    }

    useEffect(() => {
        if(isVerifySuccess && isUploadSuccess){
            setSelectedImage(null)
            setSelectedImageUrl(null)
            closeModal()
        }
    }, [isVerifySuccess, isUploadSuccess])

    return(
        <Modal
            isOpen={fileInputOpened}
            onRequestClose={closeModal}
            className={
                {
                    base: `modal-content ${FileInputModalCSS['modal-content']}`,
                    afterOpen: 'modal-content--after-open',
                    beforeClose: 'modal-content--before-close'
                }
            }
            overlayClassName={
                {
                    base: 'modal-overlay',
                    afterOpen: 'modal-overlay--after-open',
                    beforeClose: 'modal-overlay--before-close'
                }
            }
            closeTimeoutMS={300}
        >
            <div className={`modal-close ${FileInputModalCSS['modal-close']}`}>
                <button
                    onClick={closeModal}
                    className='close-button'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path
                            d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/>
                    </svg>
                </button>
            </div>
            <div className={FileInputModalCSS['input-container']}>
                {user?.verified ?
                <div className={FileInputModalCSS['input-area']} onClick={handleFileInputClick}>
                    {selectedImageUrl ? (
                        <div className={FileInputModalCSS['img-container']}>
                            <img src={selectedImageUrl} alt='Selected profile'/>
                            <FontAwesomeIcon className={FileInputModalCSS['x-mark']} icon={faXmark}
                                             onClick={() => {
                                                 setSelectedImageUrl(null)
                                                 setSelectedImage(null)
                                             }}/>
                        </div>
                    ) : (
                        <>
                            <p>Choose your profile picture</p>
                            <FontAwesomeIcon icon={faFolderPlus}/>
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                style={{display: 'none'}}
                                accept="image/*"
                            />
                        </>
                    )}
                </div>
                    :
                    <>
                        <p>In order to change your profile picture you need to be verified!</p>
                        <button className={FileInputModalCSS['verify-button']} onClick={() => navigate('/dash/verify')}>Verify</button>
                    </>
                }
                {imageSent ? <p className={FileInputModalCSS['p-sent']}>Image is sent for verification!</p> : null}
                {selectedImageUrl ? (
                    (isUploadLoading || isVerifyLoading) ? (
                        <button
                            className={FileInputModalCSS['send-button']}
                            disabled='true'
                        >
                            <PulseLoader color={'#fff'} />
                        </button>
                    ) : (
                        <button
                            className={FileInputModalCSS['send-button']}
                            disabled={imageSent}
                            onClick={handleSendVerification}
                        >
                            Send
                        </button>
                    )
                ) : null}
            </div>
        </Modal>
    )
}

export default FileInputModal