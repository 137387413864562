import React, {useState} from 'react'
import {useSendManagingRequestMutation} from '../../features/managingRequest/managingRequestApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import logo from '../../assets/img/watermark-pink.png'
import {useNavigate} from 'react-router-dom'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ManagingAccountCSS from './ManagingAccount.module.css'

const RequestManagingAccountFrom = () => {

    const [sendManagingRequest, {isLoading, isSuccess}] = useSendManagingRequestMutation()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [clubName, setClubName] = useState('')
    const [address, setAddress] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const sendRequest = async () => {
        setError('')
        try {
            const result = await sendManagingRequest({ email, clubName, address, message }).unwrap()
            setSuccess('Request sent successfully.')
            setError('')
            setMessage('')
            setClubName('')
            setAddress('')
            setEmail('')
            console.log('Request successful:', result)
            setTimeout(() => {
                setSuccess('')
                navigate('/dash/login')
            }, 3000)
        } catch (err) {
            console.error('Request failed:', err)
            setError(err.data?.message || 'An unexpected error occurred')
        }
    }

    return (
        <div className='form-container'>
            <div className='logo-container'>
                <img
                    src={logo}
                    style={{cursor: 'pointer'}}
                    alt='CamNextDoor'
                    onClick={() => navigate('/')}
                />
            </div>
            <h4>Request managing account</h4>
            <div className='modal-container'>
                <input
                    className='input-field'
                    placeholder='E-Mail'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    className='input-field'
                    placeholder='Club Name'
                    value={clubName}
                    onChange={(e) => setClubName(e.target.value)}
                />
                <input
                    className='input-field'
                    placeholder='Address'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <textarea
                    className='text-field'
                    placeholder='Message'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button onClick={sendRequest} disabled={isLoading || isSuccess}
                        className='login-button'>{!isLoading ? 'Send' : <PulseLoader color={'#fff'}/>}</button>
                {error && <p className='errmsg'>{error}</p>}
                {success && <p className='successmsg'>{success}</p>}
            </div>
            <p className={ManagingAccountCSS['back-link']} onClick={() => navigate('/dash/login')}>Back to login. <OpenInNewIcon /></p>
        </div>
    )
}

export default RequestManagingAccountFrom