import BroadcasterSettingsCSS from "./BroadcasterSettings.module.css"
import React from "react"
import Follower from "./Follower"
import {useSelector} from "react-redux";
import {selectAllUsers} from "../../features/user/usersApiSlice";

const MyFollowers = ({userId}) => {

    const users = useSelector(selectAllUsers)

    let listContent = users.length
        ? users
            .filter(user => user?.following.includes(userId))
            .map(user => <Follower key={user.id} user={user} />)
        : null

    let content

    content = (
        <div className={`component-container ${BroadcasterSettingsCSS['followers-container']}`}>
            <h1>My followers</h1>
            <div className={BroadcasterSettingsCSS['followers-wrapper']}>
                {listContent}
            </div>
        </div>
    )

    return content
}

export default MyFollowers