// availabilityUtils.js

import dayjs from 'dayjs'

export const isProviderAvailable = (availability) => {
    const currentDay = new Date().toLocaleDateString('en', { weekday: 'long' })
    let currentTime = new Date().toLocaleTimeString('en', {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23'
    })

    // Check if the provider has availability for the current day
    if (availability?.hasOwnProperty(currentDay)) {
        const { opening, closing } = availability[currentDay]

        // Check if the provider has opening and closing times for the current day
        if (opening && closing) {
            // Compare the current time with the opening and closing times
            if (currentTime >= opening && currentTime <= closing) {
                return true // Provider is available
            }
        } else if (opening) {
            // Check if the provider has only an opening time
            if (currentTime >= opening) {
                return true // Provider is available
            }
        } else if (closing) {
            // Check if the provider has only a closing time
            if (currentTime <= closing) {
                return true // Provider is available
            }
        }
    }

    return false // Provider is not available
}

export const base64ToFile = (base64String, fileName) => {
    let arr = base64String.split(',')
    let mime = arr[0].match(/:(.*?)/)[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], fileName, { type: mime })
}

export const calculateAge = (birthDate) => {
    const today = dayjs()
    const birthDateDayjs = dayjs(birthDate)
    let tempAge = today.year() - birthDateDayjs.year()

    // Adjust the age if the current date is before the birthday in the current year
    if (
        today.month() < birthDateDayjs.month() ||
        (today.month() === birthDateDayjs.month() && today.date() < birthDateDayjs.date())
    ) {
        tempAge--
    }

    return tempAge
}

export const haversineDistance = (coord1, coord2) => {
    if (coord1 && coord2) {
        const R = 6371000 // Radius of the Earth in meters

        const lat1 = coord1[1] * Math.PI / 180
        const lon1 = coord1[0] * Math.PI / 180
        const lat2 = coord2[1] * Math.PI / 180
        const lon2 = coord2[0] * Math.PI / 180

        const dlat = lat2 - lat1
        const dlon = lon2 - lon1

        const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

        let distance = R * c

        if (distance > 951) {
            return `${Math.round(distance / 1000)} km`
        } else if (distance < 100) {
            return `100 m`
        } else {
            distance = Math.ceil(distance / 100) * 100
            return `${distance} m`
        }
    }
}

export const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
}
