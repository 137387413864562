import logo from '../assets/img/watermark.png'

const DesktopDetected = () => {
    return(
        <div id="desktop-detected-screen">
            <img src={logo} alt="CamNextDoor" />
            <h4>SWITZERLAND+</h4>
            <p>To use this website please change to a mobile device in portrait mode</p>
        </div>
    )
}

export default DesktopDetected