import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

// Set up an adapter for Advertisers to handle normalized state
const advertiserAdapter = createEntityAdapter({});
const initialState = advertiserAdapter.getInitialState();

// Define the apiSlice for Advertisers with CRUD operations and specific mutations
export const advertiserApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAdvertisers: builder.query({
            query: () => ({
                url: '/advertisers',
                validateStatus: (response, result) => response.status === 200 && !result.isError,
            }),
            transformResponse: responseData => {
                const loadedAds = responseData.map(ad => {
                    ad.id = ad._id; // Normalize _id to id
                    return ad;
                });
                return advertiserAdapter.setAll(initialState, loadedAds);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Ad', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Ad', id })),
                    ];
                } else return [{ type: 'Ad', id: 'LIST' }];
            }
        }),
        addNewAdvertiser: builder.mutation({
            query: initialAdvertiserData => ({
                url: '/advertisers',
                method: 'POST',
                body: { ...initialAdvertiserData }
            }),
            invalidatesTags: [{ type: 'Advertiser', id: 'LIST' }]
        }),
        getAdsByManager: builder.query({
            query: (managerId) => ({
                url: '/advertisers/manager',
                method: 'POST',
                body: { managerId }
            }),
            providesTags: (result, error, arg) =>
                result ? [
                    ...result.map(({ id }) => ({ type: 'Advertiser', id })),
                    { type: 'Advertiser', id: 'LIST' },
                ] : [{ type: 'Advertiser', id: 'LIST' }],
        }),


        updateAdvertiser: builder.mutation({
            query: initialAdvertiserData => ({
                url: `/advertisers/${initialAdvertiserData.id}`,
                method: 'PATCH',
                body: { ...initialAdvertiserData }
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg.id }]
        }),
        deleteAdvertiser: builder.mutation({
            query: ({ id }) => ({
                url: `/advertisers/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg.id }]
        }),
        updateAdvertiserLike: builder.mutation({
            query: ({ id }) => ({
                url: `/advertisers/${id}/like`,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg.id }]
        }),
        updateAdvertiserUnLike: builder.mutation({
            query: ({ id }) => ({
                url: `/advertisers/${id}/unlike`,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg.id }]
        }),
        updateAdvertiserText: builder.mutation({
            query: ({ id, text }) => ({
                url: `/advertisers/${id}/text`,
                method: 'PATCH',
                body: { text }
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg.id }]
        }),
    }),
});

// Export hooks for each query and mutation
export const {
    useGetAdvertisersQuery,
    useAddNewAdvertiserMutation,
    useGetAdsByManagerQuery,
    useUpdateAdvertiserMutation,
    useDeleteAdvertiserMutation,
    useUpdateAdvertiserLikeMutation,
    useUpdateAdvertiserUnLikeMutation,
    useUpdateAdvertiserTextMutation,
} = advertiserApiSlice;

// Selector for query result
export const selectAdvertisersResult = advertiserApiSlice.endpoints.getAdvertisers.select();

// Memoized selector for normalized advertiser data
const selectAdvertisersData = createSelector(
    selectAdvertisersResult,
    advertisersResult => advertisersResult.data ?? initialState
);

// Destructure adapter selectors with aliases
export const {
    selectAll: selectAllAdvertisers,
    selectById: selectAdvertiserById,
    selectIds: selectAdvertiserIds
} = advertiserAdapter.getSelectors(state => selectAdvertisersData(state));
