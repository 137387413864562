import React, { useState, useRef, useEffect } from 'react';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import PulseLoader from 'react-spinners/PulseLoader';
import AuthCSS from './Auth.module.css';

const libraries = ['places'];

const SignupLocation = ({ location, setLocation, isToggled }) => {

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const [address, setAddress] = useState('');
    const autocompleteRef = useRef(null);
    const [isLocationLoading, setIsLocationLoading] = useState(false);

    useEffect(() => {
        if (location && location.address) {
            setAddress(location.address)
        }
    }, [location])

    useEffect(() => {
        if (!isToggled) {
            setAddress('');
        }
    }, [isToggled]);

    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading maps';

    const handlePlaceSelected = () => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();
            console.log(place); // Add this line to log the place object for debugging
            if (place) {
                const address = place.formatted_address || '';
                const addressComponents = place.address_components || [];

                const city = addressComponents.find(component =>
                    component.types.includes("locality"))?.long_name || '';
                const state = addressComponents.find(component =>
                    component.types.includes("administrative_area_level_1"))?.long_name || '';
                const country = addressComponents.find(component =>
                    component.types.includes("country"))?.long_name || '';
                const zipCode = addressComponents.find(component =>
                    component.types.includes("postal_code"))?.long_name || '';
                const coordinates = place.geometry?.location
                    ? [place.geometry.location.lng(), place.geometry.location.lat()]
                    : [0, 0];  // Defaulting to [0, 0] if no location found

                const temp_location = {
                    address,
                    city,
                    state,
                    country,
                    zipCode,
                    coordinates: {
                        type: 'Point',
                        coordinates
                    }
                };

                setLocation(temp_location);
                setAddress(address);
            } else {
                console.error('No place details available');
            }
        }
    };

    const handleInputChange = (e) => {
        setAddress(e.target.value);
    }

    const reverseGeocode = ({ lat, lng }) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const result = results[0];
                const address = result.formatted_address || '';
                const city = result.address_components.find(component =>
                    component.types.includes("locality"))?.long_name || '';
                const state = result.address_components.find(component =>
                    component.types.includes("administrative_area_level_1"))?.long_name || '';
                const country = result.address_components.find(component =>
                    component.types.includes("country"))?.long_name || '';
                const zipCode = result.address_components.find(component =>
                    component.types.includes("postal_code"))?.long_name || '';
                const coordinates = [lng, lat];  // [longitude, latitude]

                const temp_location = {
                    address,
                    city,
                    state,
                    country,
                    zipCode,
                    coordinates: {
                        type: 'Point',
                        coordinates
                    }
                };

                setLocation(temp_location);
                setAddress(address);
            } else {
                console.error('Geocoder failed due to: ' + status);
            }
        });
    };

    const handleLocationClick = () => {
        setIsLocationLoading(true);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                reverseGeocode({ lat: latitude, lng: longitude });
                setIsLocationLoading(false);
            },
            (error) => {
                console.error(error);
                setIsLocationLoading(false);
            },
            { enableHighAccuracy: true }
        );
    }

    const content = (
        <div className={AuthCSS['location-container']}>
            <div className={AuthCSS['location-input-container']}>
                <div className={AuthCSS['location-input']}>
                    <Autocomplete
                        onPlaceChanged={handlePlaceSelected}
                        onLoad={(autocomplete) => { autocompleteRef.current = autocomplete }}
                    >
                        <input
                            placeholder='Location'
                            value={address}
                            onChange={handleInputChange}
                        />
                    </Autocomplete>
                </div>
                <div
                    className={AuthCSS['location-pin-container']}
                    onClick={handleLocationClick}
                >
                    {isLocationLoading ? (
                        <PulseLoader color={'#F92E85FF'} className={AuthCSS['location-pulse-loader']} size={10} />
                    ) : (
                        <div className={AuthCSS['location-pin']}>
                            <FontAwesomeIcon icon={faLocationDot} />
                        </div>
                    )}
                </div>
            </div>
            <p className={AuthCSS['location-hint']}>
                Your location is not displayed! We just use it to show the distance between other users and you.
            </p>
        </div>
    );
    return content;
}

export default SignupLocation;
