import Modal from 'react-modal'
import AuthCSS from "./Auth.module.css"
import {useState} from 'react'
import {useSendManagingRequestMutation} from '../managingRequest/managingRequestApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'

Modal.setAppElement('#root')

const ForgotPassword = ({forgotPasswordOpened, closeModal}) => {

    const [sendManagingRequest, {isLoading, isSuccess}] = useSendManagingRequestMutation()

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [clubName, setClubName] = useState('')
    const [address, setAddress] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const sendRequest = async () => {
        setError('')
        try {
            const result = await sendManagingRequest({ email, clubName, address, message }).unwrap()
            setSuccess('Request sent successfully.')
            setError('')
            setMessage('')
            setClubName('')
            setAddress('')
            setEmail('')
            console.log('Request successful:', result)
            setTimeout(() => {
                setSuccess('')
                closeModal()
            }, 3000)
        } catch (err) {
            console.error('Request failed:', err)
            setError(err.data?.message || 'An unexpected error occurred')
        }
    }

    return(
        <Modal
            isOpen={forgotPasswordOpened}
            onRequestClose={closeModal}
            className={
                {
                    base: `modal-content ${AuthCSS['request-modal-content']}`,
                    afterOpen: 'modal-content--after-open',
                    beforeClose: 'modal-content--before-close'
                }
            }
            overlayClassName={
                {
                    base: 'modal-overlay',
                    afterOpen: 'modal-overlay--after-open',
                    beforeClose: 'modal-overlay--before-close'
                }
            }
            closeTimeoutMS={300}
        >
            <div className={`modal-close ${AuthCSS['modal-close']}`}>
                <button
                    onClick={closeModal}
                    className='close-button'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path
                            d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/>
                    </svg>
                </button>
            </div>
            <h4>Please enter your email</h4>
            <div className='modal-container'>
                <input
                    className='input-field'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button onClick={sendRequest} disabled={isLoading || isSuccess} className='submit-button'>{!isLoading ? 'I forgot password' : <PulseLoader color={'#fff'}/>}</button>
                {error && <p className='errmsg'>{error}</p>}
                {success && <p className='successmsg'>{success}</p>}
            </div>
        </Modal>
    )
}

export default ForgotPassword