import React, { useState, useRef, useEffect } from 'react';

const FlexibleTextarea = ({value, setValue}) => {
    const textareaRef = useRef(null);

    const handleChange = (event) => {
        setValue(event.target.value);
        resizeTextarea();
    };

    const resizeTextarea = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto'; // Reset height to allow shrink if text is removed
            textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scroll height to expand as needed
        }
    };

    // Adjust the height initially if there is default text
    useEffect(() => {
        resizeTextarea();
    }, []);

    return (
        <textarea
            placeholder='Description'
            id='description'
            autoComplete='off'
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            style={{ overflow: 'hidden', resize: 'none' }}
        />
    );
};

export default FlexibleTextarea;