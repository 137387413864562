import AuthCSS from './Auth.module.css'
import {useNavigate} from 'react-router-dom'
const UnauthorizedPage = () => {

    const navigate = useNavigate()

    return(
        <div className={AuthCSS['unauthorized-container']}>
            <h2>My Subscriptions</h2>
            <p>Please log in to see your subscriptions.</p>
            <button onClick={() => navigate('/dash/login')}>Log in</button>
        </div>
    )
}

export default UnauthorizedPage