import React, { useState, useRef } from 'react'
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api'
import HomePageCSS from './HomePage.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import PulseLoader from 'react-spinners/PulseLoader'

const libraries = ['places']

const SearchLocation = ({ setIsDisabled, setLocation }) => {

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    })

    const [address, setAddress] = useState('')
    const autocompleteRef = useRef(null)
    const [isLocationLoading, setIsLocationLoading] = useState(false)

    if (loadError) return 'Error loading maps'
    if (!isLoaded) return 'Loading maps'

    const handlePlaceSelected = () => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace()
            if (place) {
                const address = place.formatted_address || ''
                const addressComponents = place.address_components || []

                const city = addressComponents.find(component =>
                    component.types.includes("locality"))?.long_name || ''
                const state = addressComponents.find(component =>
                    component.types.includes("administrative_area_level_1"))?.long_name || ''
                const country = addressComponents.find(component =>
                    component.types.includes("country"))?.long_name || ''
                const zipCode = addressComponents.find(component =>
                    component.types.includes("postal_code"))?.long_name || ''
                const coordinates = place.geometry?.location
                    ? [place.geometry.location.lng(), place.geometry.location.lat()]
                    : [0, 0]  // Defaulting to [0, 0] if no location found

                const location = {
                    address,
                    city,
                    state,
                    country,
                    zipCode,
                    coordinates: {
                        type: 'Point',
                        coordinates
                    }
                }

                setLocation(location)
                setAddress(address)
                setIsDisabled(false)
            } else {
                console.error('No place details available')
            }
        }
    }

    const handleInputChange = (e) => {
        setAddress(e.target.value)
    }

    const reverseGeocode = ({ lat, lng }) => {
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const result = results[0]
                const address = result.formatted_address || ''
                const city = result.address_components.find(component =>
                    component.types.includes("locality"))?.long_name || ''
                const state = result.address_components.find(component =>
                    component.types.includes("administrative_area_level_1"))?.long_name || ''
                const country = result.address_components.find(component =>
                    component.types.includes("country"))?.long_name || ''
                const zipCode = result.address_components.find(component =>
                    component.types.includes("postal_code"))?.long_name || ''
                const coordinates = [lng, lat]  // [longitude, latitude]

                const location = {
                    address,
                    city,
                    state,
                    country,
                    zipCode,
                    coordinates: {
                        type: 'Point',
                        coordinates
                    }
                }

                setLocation(location)
                setAddress(address)
                setIsDisabled(false)
            } else {
                console.error('Geocoder failed due to: ' + status)
            }
        })
    }

    const handleLocationClick = () => {
        setIsLocationLoading(true)
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords
                reverseGeocode({ lat: latitude, lng: longitude })
                setIsLocationLoading(false)
            },
            (error) => {
                console.error(error)
                setIsLocationLoading(false)
            },
            { enableHighAccuracy: true }
        )
    }

    const content = (
        <div className={HomePageCSS['location-container']}>
            <div className={HomePageCSS['location-input']}>
                <Autocomplete
                    onPlaceChanged={handlePlaceSelected}
                    onLoad={(autocomplete) => { autocompleteRef.current = autocomplete }}
                >
                    <input
                        placeholder='Enter your Location'
                        value={address}
                        onChange={handleInputChange}
                    />
                </Autocomplete>
            </div>
            <div
                className={HomePageCSS['location-pin-container']}
                onClick={handleLocationClick}
            >
                {isLocationLoading ? (
                    <PulseLoader color={'#F92E85FF'} className={HomePageCSS['location-pulse-loader']} size={10}/>
                ) : (
                    <div className={HomePageCSS['location-pin']}>
                        <FontAwesomeIcon icon={faLocationDot} />
                        <p>find</p>
                    </div>
                )}
            </div>
        </div>
    )
    return content
}

export default SearchLocation
