import logo from '../assets/img/watermark-pink.png'
import { useSendLogoutMutation } from '../features/auth/authApiSlice'
import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'
import useAuth from "../hooks/useAuth";
import LiveCamSection from './ProfileShow/LiveCamSection'

const ComingSoon = () => {

    const navigate = useNavigate()

    const content = (
        <div className='coming-soon'>
            <LiveCamSection />
        </div>
    )

    return content
}

export default ComingSoon