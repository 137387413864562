import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const usersAdapter = createEntityAdapter({})

const initialState = usersAdapter.getInitialState()

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: () => ({
                url: '/users',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedUsers = responseData.map(user => {
                    user.id = user._id
                    return user
                });
                return usersAdapter.setAll(initialState, loadedUsers)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'User', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'User', id }))
                    ]
                } else return [{ type: 'User', id: 'LIST' }]
            }
        }),
        getUsersByFilter: builder.mutation({
            query: initialUserData => ({
                url: '/users/filter',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        findUserByEmail: builder.query({
            query: (email) => ({
                url: `/users/email/${email}`,
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [
                { type: 'User', id: result?.id }
            ]
        }),
        checkToken: builder.mutation({
            query: initialUserData => ({
                url: '/users/verify',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        addNewUser: builder.mutation({
            query: initialUserData => ({
                url: '/users',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        changePassword: builder.mutation({
            query: initialUserData => ({
                url: '/users/password-reset',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateUser: builder.mutation({
            query: initialUserData => ({
                url: '/users',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateUserStories: builder.mutation({
            query: initialUserData => ({
                url: '/users/stories',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateUserPosts: builder.mutation({
            query: initialUserData => ({
                url: '/users/posts',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateUserPremiumPosts: builder.mutation({
            query: initialUserData => ({
                url: '/users/premium-post',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateUserFollowing: builder.mutation({
            query: initialUserData => ({
                url: '/users/following',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateUserImpressions: builder.mutation({
            query: initialUserData => ({
                url: '/users/impressions',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateUserClicks: builder.mutation({
            query: initialUserData => ({
                url: '/users/clicks',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        deactivateUser: builder.mutation({
            query: initialUserData => ({
                url: '/users/deactivate',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateRemoveUserFollowing: builder.mutation({
            query: initialUserData => ({
                url: '/users/unfollowing',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateRemoveUserStories: builder.mutation({
            query: initialUserData => ({
                url: '/users/delete-story',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateRemoveUserPosts: builder.mutation({
            query: initialUserData => ({
                url: '/users/delete-posts',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateRemoveUserPremiumContent: builder.mutation({
            query: initialUserData => ({
                url: '/users/delete-premiumContent',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        reportUser: builder.mutation({
            query: initialUserData => ({
                url: '/users/report',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        verifyImage: builder.mutation({
            query: initialUserData => ({
                url: '/users/email-profile-image',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        emailDocuments: builder.mutation({
            query: initialUserData => ({
                url: '/users/email-documents',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        verifyProfileImage: builder.mutation({
            query: initialUserData => ({
                url: '/users/verify-profile-image',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        verifyDocuments: builder.mutation({
            query: initialUserData => ({
                url: '/users/verify-documents',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        supportUser: builder.mutation({
            query: initialUserData => ({
                url: '/users/support',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        removeFollowers: builder.mutation({
            query: initialUserData => ({
                url: `/users/remove-followers`,
                method: 'DELETE',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        removeFollowing: builder.mutation({
            query: initialUserData => ({
                url: `/users/remove-following`,
                method: 'DELETE',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        deleteUser: builder.mutation({
            query: ({ id }) => ({
                url: `/users`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetUsersQuery,
    useGetUsersByFilterMutation,
    useFindUserByEmailQuery,
    useCheckTokenMutation,
    useAddNewUserMutation,
    useChangePasswordMutation,
    useUpdateUserMutation,
    useUpdateUserFollowingMutation,
    useUpdateUserStoriesMutation,
    useUpdateUserPostsMutation,
    useUpdateUserPremiumPostsMutation,
    useUpdateRemoveUserFollowingMutation,
    useUpdateUserImpressionsMutation,
    useUpdateUserClicksMutation,
    useDeactivateUserMutation,
    useUpdateRemoveUserStoriesMutation,
    useReportUserMutation,
    useVerifyImageMutation,
    useEmailDocumentsMutation,
    useVerifyProfileImageMutation,
    useVerifyDocumentsMutation,
    useSupportUserMutation,
    useUpdateRemoveUserPostsMutation,
    useUpdateRemoveUserPremiumContentMutation,
    useDeleteUserMutation,
    useRemoveFollowersMutation,
    useRemoveFollowingMutation
} = usersApiSlice

// returns the query result object
export const selectUsersResult = usersApiSlice.endpoints.getUsers.select()

// creates memoized selector
const selectUsersData = createSelector(
    selectUsersResult,
    usersResult => usersResult.data // normalized state object with ids & entities
)
export const selectUserByUsername = createSelector(
    [selectUsersData, (state, username) => username],
    (usersData, username) => {
        if (usersData && usersData.entities) {
            return Object.values(usersData.entities).find(user => user.username === username);
        }
        return undefined;
    }
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds
    // Pass in a selector that returns the users slice of state
} = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState)