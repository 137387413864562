import UserFollowingCSS from '../UserProfile/UserFollowing.module.css'
import Provider from './Provider'

const Following = ({loggedUser}) => {

    return (
        <div className={`component-container ${UserFollowingCSS['following-container']}`}>
            <h1>Following</h1>
            <div className={UserFollowingCSS['providers-section']}>
                {loggedUser?.following && loggedUser.following.length > 0 ? (
                    loggedUser.following.map((provider, index) => (
                        <Provider key={index} providerId={provider} loggedUser={loggedUser} />
                    ))
                ) : (
                    <p className={UserFollowingCSS['no-followers']}>You're not following anyone yet.</p>
                )}
            </div>
        </div>
    )

}

export default Following