import Modal from 'react-modal'
import BroadcasterSettingsCSS from './BroadcasterSettings.module.css'
import {
    useDeactivateUserMutation,
    useRemoveFollowersMutation,
    useRemoveFollowingMutation
} from "../../features/user/usersApiSlice";
import {useNavigate} from "react-router-dom";

Modal.setAppElement('#root')

const DeleteStoryConfirmation = ({isConfirmationOpen, closeModal, userId ,sendLogout}) => {

    const [deactivateUser,{
        isSuccess,
        isLoading,
        isError,
        error
    }] = useDeactivateUserMutation()
    const [removeFollowing
    ] = useRemoveFollowingMutation()
    const [removeFollowers
    ] = useRemoveFollowersMutation()

    const navigate = useNavigate()

    const handleUserDeactivate = async () => {
        try {

            await deactivateUser({id: userId, deactivated: true})
            await removeFollowers({userId: userId})
            await removeFollowing({userId: userId})
            console.log('User deactivated successfully');
        } catch (error) {
            console.error('Error creating user:', error);
        }
        sendLogout()
        closeModal()
        navigate('/dash/login')
    }

    return(
        <Modal
            isOpen={isConfirmationOpen}
            onRequestClose={closeModal}
            className={
                {
                    base: `modal-content ${BroadcasterSettingsCSS['confirmation-modal-content']}`,
                    afterOpen: 'modal-content--after-open',
                    beforeClose: 'modal-content--before-close'
                }
            }
            overlayClassName={
                {
                    base: 'modal-overlay',
                    afterOpen: 'modal-overlay--after-open',
                    beforeClose: 'modal-overlay--before-close'
                }
            }
            closeTimeoutMS={300}
        >
            <div className='modal-close'>
                <button
                    onClick={closeModal}
                    className='close-button'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path
                            d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/>
                    </svg>
                </button>
            </div>
            <h4>Are you sure you want to delete your account?</h4>
            <div className={BroadcasterSettingsCSS['confirmation-buttons']}>
                <button onClick={handleUserDeactivate}>Yes</button>
                <button onClick={() => closeModal()}>No</button>
            </div>
        </Modal>
    )
}

export default DeleteStoryConfirmation