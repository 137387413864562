import React, {useEffect} from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const Ad = ({ videoUrl }) => {

    const handleClick = () => {
        window.location.href = 'https://www.google.com';
    }

    useEffect(() => {
        console.log(videoUrl)
    }, [videoUrl])

    return (
        <div className="ad-container">
            <video loop autoPlay muted playsInline>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <button className="ad-button" onClick={handleClick}>
                <span>Visit site</span>
                <KeyboardArrowRightIcon />
            </button>
        </div>
    );
};

export default Ad;
