import Modal from 'react-modal'
import BroadcasterSettingsCSS from "./BroadcasterSettings.module.css"
import {useNavigate} from 'react-router-dom'
import {useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import {useSupportUserMutation} from "../../features/user/usersApiSlice";
import PulseLoader from "react-spinners/PulseLoader";

Modal.setAppElement('#root')

const ReportUser = ({userId, supportOpened, closeModal}) => {

    const [sendSupport, {
        isSuccess: isSupportSuccess,
        isLoading: isSupportLoading,
        isError: iseSupportError,
        error: supportError
    }] = useSupportUserMutation()

    const navigate = useNavigate()

    const [supportText, setSupportText] = useState('')
    const [canSend, setCanSend] = useState(false)
    const [isSent, setIsSent] = useState(false)

    useEffect(() => {
        if(supportText.length > 5) setCanSend(true)
        else setCanSend(false)
    }, [supportText])

    useEffect(() => {
        if (isSent) {
            const timer = setTimeout(() => {
                closeModal()

                const resetTimer = setTimeout(() => {
                    setIsSent(false)
                    setSupportText('')
                }, 500)

                return () => clearTimeout(resetTimer)
            }, 3000)

            return () => clearTimeout(timer)
        }
    }, [isSent])

    const handleSupportUser = async () => {
        try {
            await sendSupport({userId, reason: supportText});
            setIsSent(true)
            console.log('Report submitted successfully');
        } catch (error) {
            console.error('Failed to submit support:', error);
        }
    }


    return(
        <Modal
            isOpen={supportOpened}
            onRequestClose={closeModal}
            className={
                {
                    base: `modal-content ${BroadcasterSettingsCSS['modal-content']}`,
                    afterOpen: 'modal-content--after-open',
                    beforeClose: 'modal-content--before-close'
                }
            }
            overlayClassName={
                {
                    base: 'modal-overlay',
                    afterOpen: 'modal-overlay--after-open',
                    beforeClose: 'modal-overlay--before-close'
                }
            }
            closeTimeoutMS={300}
        >
            <div className={`modal-close ${BroadcasterSettingsCSS['modal-close']}`}>
                <button
                    onClick={closeModal}
                    className='close-button'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path
                            d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/>
                    </svg>
                </button>
            </div>
            <h4>Please let us know how we can help you:</h4>
            <textarea
                disabled={isSent}
                rows='5'
                value={supportText}
                onChange={(e) => setSupportText(e.target.value)}
            />
            <div className={BroadcasterSettingsCSS['support-button']}>
                {!isSent ?
                    <button disabled={!canSend} onClick={handleSupportUser}>Send</button>
                    : isSupportLoading ? <PulseLoader color={'#F92E85FF'} />
                    : <button className={BroadcasterSettingsCSS['sent']}>Email sent <FontAwesomeIcon icon={faCheck} /></button>
                }
            </div>
            {isSent ? <p className={BroadcasterSettingsCSS['get-back']}>We will get back to you ass soon as possible!</p> : null}
        </Modal>
    )
}

export default ReportUser