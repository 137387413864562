import AuthCSS from '../../features/auth/Auth.module.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowLeft, faCopy, faXmark} from '@fortawesome/free-solid-svg-icons'
import React, {useState} from 'react'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import {createTheme, ThemeProvider} from '@mui/material/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: '#F92E85FF',
        },
    },
})

const EditTimes = ({times, setTimes, editing = true}) => {

    const [openTimePicker, setOpenTimePicker] = useState(false)
    const [selectedTime, setSelectedTime] = useState(null)
    const [selectedDay, setSelectedDay] = useState('')
    const [openClose, setOpenClose] = useState(0)
    const [copyState, setCopyState] = useState(true)
    const [coppiedTime, setCoppiedTime] = useState({opening: null, closing: null})

    const handleOpenTimePicker = (day, time) => {
        setOpenTimePicker(true)
        setSelectedDay(day)
        setOpenClose(time)
    }
    const handleCopyTime = (day) => {
        setCoppiedTime(times[day])
        setCopyState(false)
    }
    const handlePasteTime = (day) => {
        setTimes(prevTimes => ({...prevTimes, [day]: coppiedTime}))
        setCopyState(true)
    }
    const handleDeleteTime = (day) => {
        setTimes(prevTimes => ({...prevTimes, [day]: { opening: null, closing: null}}))
    }

    const handleTimeChange = (newTime) => {
        if (newTime && typeof newTime.format === 'function') {
            const formattedTime = newTime.format('HH:mm');
            setSelectedTime(formattedTime);
        }
    }

    const handleTimeSelected = (newTime) => {
        const formattedTime = newTime.format('HH:mm')

        switch (selectedDay){
            case 'Monday':
                if(openClose === 1){
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Monday: {
                            ...prevTimes.Monday,
                            opening: formattedTime
                        }
                    }))
                }else{
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Monday: {
                            ...prevTimes.Monday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Tuesday':
                if(openClose === 1){
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Tuesday: {
                            ...prevTimes.Tuesday,
                            opening: formattedTime
                        }
                    }))
                }else{
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Tuesday: {
                            ...prevTimes.Tuesday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Wednesday':
                if(openClose === 1){
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Wednesday: {
                            ...prevTimes.Wednesday,
                            opening: formattedTime
                        }
                    }))
                }else{
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Wednesday: {
                            ...prevTimes.Wednesday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Thursday':
                if(openClose === 1){
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Thursday: {
                            ...prevTimes.Thursday,
                            opening: formattedTime
                        }
                    }))
                }else{
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Thursday: {
                            ...prevTimes.Thursday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Friday':
                if(openClose === 1){
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Friday: {
                            ...prevTimes.Friday,
                            opening: formattedTime
                        }
                    }))
                }else{
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Friday: {
                            ...prevTimes.Friday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Saturday':
                if(openClose === 1){
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Saturday: {
                            ...prevTimes.Saturday,
                            opening: formattedTime
                        }
                    }))
                }else{
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Saturday: {
                            ...prevTimes.Saturday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            case 'Sunday':
                if(openClose === 1){
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Sunday: {
                            ...prevTimes.Sunday,
                            opening: formattedTime
                        }
                    }))
                }else{
                    setTimes(prevTimes => ({
                        ...prevTimes,
                        Sunday: {
                            ...prevTimes.Sunday,
                            closing: formattedTime
                        }
                    }))
                }
                break
            default :
                break
        }
    }

    if(!times) return <p>No times available.</p>

    return (
        <div className={`${AuthCSS['step-six']} ${AuthCSS['input-fields']} ${AuthCSS['edit-meetings']}`}>
            <div className={AuthCSS['schedule-meeting']}>
                <ul className={AuthCSS['days-list']}>
                    <li>Monday</li>
                    <li>Tuesday</li>
                    <li>Wednesday</li>
                    <li>Thursday</li>
                    <li>Friday</li>
                    <li>Saturday</li>
                    <li>Sunday</li>
                </ul>
                <ul className={`${AuthCSS['hours-values']} ${!editing ? AuthCSS['days-list-non-editable'] : ''}`}>
                    {Object.keys(times).map((day) => (
                        <li key={day}>
                            <div>
                                        <span onClick={() => {
                                            if(editing) handleOpenTimePicker(day, 1)
                                        }}>
                                            {times[day] && times[day].opening ? times[day].opening : '__:__'}
                                        </span>
                                <span>-</span>
                                <span onClick={() => {
                                    if(editing) handleOpenTimePicker(day, 2)
                                }}>
                                            {times[day] && times[day].closing ? times[day].closing : '__:__'}
                                        </span>
                                {
                                    editing &&
                                    (copyState ? (
                                        <>
                                            <button className={AuthCSS['x-button']} onClick={() => handleDeleteTime(day)}>
                                                <FontAwesomeIcon icon={faXmark}/>
                                            </button>
                                            <button className={AuthCSS['copy-button']} onClick={() => handleCopyTime(day)}>
                                                <FontAwesomeIcon icon={faCopy}/>
                                            </button>
                                        </>
                                    ) : (
                                        <button className={AuthCSS['paste-button']} onClick={() => handlePasteTime(day)}>
                                            <FontAwesomeIcon icon={faArrowLeft}/>
                                        </button>
                                    ))
                                }

                            </div>
                        </li>
                    ))}
                </ul>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            className={AuthCSS['time-picker']}
                            open={openTimePicker}
                            onOpen={() => setOpenTimePicker(true)}
                            onClose={() => setOpenTimePicker(false)}
                            onAccept={handleTimeSelected}
                            onChange={handleTimeChange}
                            ampm={false}
                            value={selectedTime}
                            label='Choose Your Time'
                            minutesStep={10}
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </div>
        </div>
    )
}

export default EditTimes