import React, {useEffect, useRef, useState} from 'react'
import UserStoriesCSS from '../UserStories/UserStories.module.css'
import Slider from 'react-slick'
import Story from './Story'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'

const MyStories = ({ loggedUser, setVideoSrc, setSelectedFile, currentStories,premmisionLevel=2 }) => {

    const [currentSlide, setCurrentSlide] = useState(0)

    const videoRefs = useRef([])
    const galleryInputRef = useRef(null)
    const cameraInputRef = useRef(null)
    const textRefs = useRef([])

    useEffect(() => {
        return () => {
            videoRefs.current.forEach(video => {
                if (video) {
                    video.pause()
                    video.currentTime = 0
                    video.removeAttribute('src') // This will stop downloading the video
                }
            });
        };
    }, [currentSlide])

    useEffect(() => {
        if (currentStories && currentStories[currentSlide]) {
            const currentVideo = videoRefs.current[currentSlide]
            if (currentVideo) {
                const playPromise = currentVideo.play()
                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        // Automatic playback started!
                    }).catch(error => {
                        // Auto-play was prevented
                        console.error('Auto-play was prevented:', error)
                    });
                }
            }
        }
    }, [currentSlide, currentStories])

    const handleBeforeChange = (oldIndex, newIndex) => {
        setCurrentSlide(newIndex)
    }

    const handleAfterChange = () => {

    }

    const handleVideoEnd = () => {
        // Move to the next slide when the video ends
        if (sliderRef.current) {
            sliderRef.current.slickNext()
        }
    }

    const sliderRef = useRef()

    const settings = {
        dots: true,
        dotsClass: 'custom-dots',
        infinite: true,
        fade:true,
        speed: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        beforeChange: handleBeforeChange,
        afterChange: handleAfterChange,
    }

    let content

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const url = URL.createObjectURL(file)
            setVideoSrc(url) // Set videoSrc for displaying the downloaded video
        }
    }

    content = (
        <div className={`my-stories ${UserStoriesCSS['story-container']} ${UserStoriesCSS['my-story-container']}`}>
            {currentStories?.length ? (
                <Slider ref={sliderRef} {...settings}>
                    {currentStories?.map((story, index) => (
                        <Story
                            currentStory={story}
                            currentSlide={currentSlide}
                            key={index}
                            index={index}
                            videoRefs={videoRefs}
                            textRefs={textRefs}
                            handleVideoEnd={handleVideoEnd}
                            storyCount={currentStories?.length}
                            userId={loggedUser.id}
                            isActive={currentSlide}
                            galleryInputRef={galleryInputRef}
                            cameraInputRef={cameraInputRef}
                        />
                    ))}
                </Slider>
            ) : <h3 className={UserStoriesCSS['no-stories']}>You have no content uploaded yet.</h3>}
            {premmisionLevel >=2 &&
            <div className={UserStoriesCSS['upper-button-section']}>
                <button onClick={() => cameraInputRef.current.click()} className={UserStoriesCSS['first-button']}>
                    <CameraAltOutlinedIcon/></button>
                <button onClick={() => galleryInputRef.current.click()} className={UserStoriesCSS['first-button']}>
                    <CollectionsOutlinedIcon/></button>
            </div>}
            <input
                type="file"
                accept="video/*"
                onChange={handleFileUpload}
                style={{display: 'none'}}
                ref={cameraInputRef}
                capture="camcorder"
            />
            <input
                type="file"
                accept="video/*"
                onChange={handleFileUpload}
                style={{display: 'none'}}
                ref={galleryInputRef}
            />
        </div>
    )

    return content
}

export default MyStories