import { createSelector, createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const tokensAdapter = createEntityAdapter({})

const initialState = tokensAdapter.getInitialState()

export const tokensApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createToken: builder.mutation({
            query: initialState => ({
                url: '/managing-request-token',
                method: 'POST',
                body: {
                    ...initialState,
                }
            }),
            invalidatesTags: [{ type: 'ManagingToken', id: 'LIST' }]
        }),
        verifyToken: builder.query({
            query: ({ email, tokenId }) => ({
                url: `/managing-request-token/verify?email=${email}&tokenId=${tokenId}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [
                { type: 'ManagingToken', id: arg }
            ]
        }),
        invalidateToken: builder.mutation({
            query: email => ({
                url: `/managing-request-token`,
                method: 'DELETE',
                body: { email }
            }),
            invalidatesTags: [{ type: 'Token', id: 'LIST' }]
        }),
        sendManagerRequestResponse: builder.mutation({
            query: responseDetails => ({
                url: '/managing-request-token/response',
                method: 'POST',
                body: responseDetails
            }),
            invalidatesTags: [{ type: 'Token', id: 'LIST' }]
        })
    })
})

export const {
    useCreateTokenMutation,
    useVerifyTokenQuery,
    useInvalidateTokenMutation,
    useSendManagerRequestResponseMutation
} = tokensApiSlice

// Selectors for handling the token data
export const selectTokensResult = tokensApiSlice.endpoints.verifyToken.select()

const selectTokensData = createSelector(
    selectTokensResult,
    tokensResult => tokensResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllTokens,
    selectById: selectTokenById,
    selectIds: selectTokenIds
} = tokensAdapter.getSelectors(state => selectTokensData(state) ?? initialState)