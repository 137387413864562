import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContentSectionCSS from '../ContentSection.module.css';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useUpdatePremiumPostMutation, selectPremiumPostById } from '../../../features/premiumPosts/premiumPostsApiSlice';
import PulseLoader from 'react-spinners/PulseLoader';

const EditPremiumPost = () => {
    const { premiumPostId } = useParams();
    const navigate = useNavigate();
    const post = useSelector((state) => selectPremiumPostById(state, premiumPostId));

    const [title, setTitle] = useState(post?.title || '');
    const [description, setDescription] = useState(post?.description || '');
    const [price, setPrice] = useState(post?.price || '');
    const [validPrice, setValidPrice] = useState(true);
    const [updatePremiumPost, { isLoading }] = useUpdatePremiumPostMutation();

    useEffect(() => {
        if (post) {
            setTitle(post.title || '');
            setDescription(post.description || '');
            setPrice(post.price || 5);
        }
    }, [post]);

    useEffect(() => {
        if (price >= 5 && price <= 5000) setValidPrice(true);
        else setValidPrice(false);
    }, [price]);

    const onTitleChange = (e) => {
        if (e.target.value.length <= 40) {
            setTitle(e.target.value);
        }
    };

    const onPriceChange = (e) => {
        setPrice(e.target.value);
    };

    const handlePublish = async () => {
        if (!validPrice) return;

        try {
            const postData = {
                id: post.id,
                title,
                description,
                price,
            };

            await updatePremiumPost(postData).unwrap();
            console.log("sfafsa")
            navigate(`/dash/user/content-section`);
        } catch (error) {
            console.error('Failed to update post:', error);
        }
    };

    return (
        <div className={ContentSectionCSS['edit-container']}>
            <CloseOutlinedIcon />
            <div className={ContentSectionCSS['description-section']}>
                <h3>Title</h3>
                <input value="mikac" type='text' placeholder='Title' onChange={onTitleChange} />
            </div>
            <div className={ContentSectionCSS['description-section']}>
                <h3>Description</h3>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder='Description'
                    maxLength='300'
                    rows='6'
                />
            </div>
            <div className={ContentSectionCSS['description-section']}>
                <h3>Price</h3>
                <input value={price} type='number' placeholder='CHF' onChange={onPriceChange} />
            </div>
            <button
                className={ContentSectionCSS['publish']}
                onClick={handlePublish}
                disabled={!validPrice || isLoading}
            >
                {isLoading ? (
                    <PulseLoader color={'#ffffff'} />
                ) : (
                    'Update Content'
                )}
            </button>
        </div>
    );
};

export default EditPremiumPost;
