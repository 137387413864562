import EditUserProfileCSS from './EditUserProfile.module.css'
import profileImage from '../../assets/img/profile-placeholder1.jpg'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faLocationDot,
    faPen,
    faUser,
    faList,
    faTag,
    faCakeCandles,
    faMobile,
    faMoneyBill, faGlobe, faEllipsis, faCommentDots, faBolt, faCheck, faHeart, faClock
} from '@fortawesome/free-solid-svg-icons'
import React, {useEffect, useState} from 'react'
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import SignupLocation from '../auth/SignupLocation'
import {selectUserById, useUpdateUserMutation} from './usersApiSlice'
import FlexibleTextarea from './FlexibleTextarea'
import { isEqual } from 'lodash'
import EditTimes from '../../components/EditTimes/EditTimes'
import EditPrices from '../../components/EditTimes/EditPrices'
import FileInputModal from '../../components/FileInputModal/FileInputModal'
import dayjs from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers'
import AuthCSS from '../auth/Auth.module.css'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import {calculateAge} from '../../common/utils'
import {useSelector} from 'react-redux'

const theme = createTheme({
    palette: {
        primary: {
            main: '#F92E85FF',
        },
    },
})

const EditUserProfile = ({ userId, permissionLevel }) => {

    const genders = [{value: 'Male', key: 'male'}, {value: 'Female', key: 'female'}, {value: 'Trans', key: 'trans'}, {value: 'Other', key: 'other'}]
    const profileTypes = [{value: 'Independent', key: 'Independent'} , {value: 'Studio', key: 'Studio'}, {value: 'Escort', key: 'Escort'}, {value: 'Club', key: 'Club'}, {value: 'Saunaclub', key: 'Saunaclub'}]
    const languages = [
        { value: 'English', key: 'english' },
        { value: 'German', key: 'german' },
        { value: 'Spanish', key: 'spanish' },
        { value: 'French', key: 'french' },
        { value: 'Italian', key: 'italian' },
        { value: 'Russian', key: 'russian' },
        { value: 'Serbian', key: 'serbian' },
        { value: 'Albanian', key: 'albanian' },
        { value: 'Romanian', key: 'romanian' },
        { value: 'Other', key: 'other' }
    ]
    const orientations = [
        {value: 'Straight', key: 'Straight'},
        {value: 'Gay', key: 'Gay'},
        {value: 'Bisexual', key: 'Bisexual'},
        {value: 'Other', key: 'Other'}
    ]
    const attributes = [
        {value: 'Smoking', key: 'Smoking'},
        {value: 'Implants', key: 'Implants'},
        {value: 'Alcohol', key: 'Alcohol'},
        {value: 'Shaved', key: 'Shaved'},
        {value: 'Tattoos', key: 'Tattoos'}
    ]
    const services = [
        { value: 'Anal', key: 'Anal' },
        { value: 'Deep Throat', key: 'Deep Throat' },
        { value: 'Dinner Date', key: 'Dinner Date' },
        { value: 'Dirtytalk', key: 'Dirtytalk' },
        { value: 'Domination (hard)', key: 'Domination (hard)' },
        { value: 'Domination (soft)', key: 'Domination (soft)' },
        { value: 'Escort', key: 'Escort' },
        { value: 'Facesitting', key: 'Facesitting' },
        { value: 'Fisting', key: 'Fisting' },
        { value: 'Footjob', key: 'Footjob' },
        { value: 'French', key: 'French' },
        { value: 'Gangbang', key: 'Gangbang' },
        { value: 'Girlfriend Sex', key: 'Girlfriend Sex' },
        { value: 'Handjob', key: 'Handjob' },
        { value: 'Kamasutra', key: 'Kamasutra' },
        { value: 'Kissing', key: 'Kissing' },
        { value: 'Licking (active)', key: 'Licking (active)' },
        { value: 'Licking (passive)', key: 'Licking (passive)' },
        { value: 'Massage', key: 'Massage' },
        { value: 'Masturbation', key: 'Masturbation' },
        { value: 'Open for relationship', key: 'Open for relationship' },
        { value: 'Pissing (active)', key: 'Pissing (active)' },
        { value: 'Pissing (passive)', key: 'Pissing (passive)' },
        { value: 'Position 69', key: 'Position 69' },
        { value: 'Prostatamassage', key: 'Prostatamassage' },
        { value: 'Roleplay', key: 'Roleplay' },
        { value: 'Sell clothing', key: 'Sell clothing' },
        { value: 'Sell photos / videos', key: 'Sell photos / videos' },
        { value: 'Sex in different positions', key: 'Sex in different positions' },
        { value: 'Sexchat', key: 'Sexchat' },
        { value: 'Strapon', key: 'Strapon' },
        { value: 'Striptease', key: 'Striptease' },
        { value: 'Tantra', key: 'Tantra' },
        { value: 'Toys (active)', key: 'Toys (active)' },
        { value: 'Toys (passive)', key: 'Toys (passive)' },
        { value: 'Webcam', key: 'Webcam' }
    ]

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const loggedUser = useSelector(state => selectUserById(state, userId))

    const [changesSuccess, setChangesSuccess] = useState(false)

    useEffect(() => {
        if(isSuccess) setChangesSuccess(true)
    }, [isSuccess]);

    // Reset changesSuccess to false after 3 seconds when it becomes true
    useEffect(() => {
        if (changesSuccess) {
            const timer = setTimeout(() => {
                setChangesSuccess(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [changesSuccess]);

    const [initialName, setInitialName] = useState('')
    const [name, setName] = useState('')
    const [initialPartnerName, setInitialPartnerName] = useState('')
    const [partnerName, setPartnerName] = useState('')
    const [initialPartnerWebsite, setInitialPartnerWebsite] = useState('')
    const [partnerWebsite, setPartnerWebsite] = useState('')
    const [initialAge, setInitialAge] = useState('')
    const [age, setAge] = useState('')
    const [initialPhoneNumber, setInitialPhoneNumber] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [initialWhatsapp, setInitialWhatsapp] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [initialDescription, setInitialDescription] = useState('')
    const [description, setDescription] = useState('')
    const [initialLocation, setInitialLocation] = useState(null)
    const [location, setLocation] = useState(null)
    const [initialGender, setInitialGender] = useState([])
    const [gender, setGender] = useState([])
    const [initialProfileType, setInitialProfileType] = useState([])
    const [profileType, setProfileType] = useState([])
    const [initialLanguage, setInitialLanguage] = useState([])
    const [language, setLanguage] = useState([])
    const [initialOrientation, setInitialOrientation] = useState([])
    const [orientation, setOrientation] = useState([])
    const [initialAttribute, setInitialAttribute] = useState([])
    const [attribute, setAttribute] = useState([])
    const [initialService, setInitialService] = useState([])
    const [service, setService] = useState([])
    const [initialBirthDate, setInitialBirthDate] = useState(dayjs())
    const [birthDate, setBirthDate] = useState(dayjs())
    const [initialAvailability, setInitialAvailability] = useState(null)
    const [availability, setAvailability] = useState({
        Monday: { opening: null, closing: null },
        Tuesday: { opening: null, closing: null },
        Wednesday: { opening: null, closing: null },
        Thursday: { opening: null, closing: null },
        Friday: { opening: null, closing: null },
        Saturday: { opening: null, closing: null },
        Sunday: { opening: null, closing: null }
    })
    const [initialTimeAndPrice, setInitialTimeAndPrice] = useState(null)
    const [timeAndPrice, setTimeAndPrice] = useState({
        FirstPrice: {duration: '15', unit: 'min', price: ''},
        SecondPrice: {duration: '30', unit: 'min', price: ''},
        ThirdPrice: {duration: '1', unit: 'h', price: ''},
        FourthPrice: {duration: '', unit: 'h', price: ''},
        FifthPrice: {duration: '', unit: 'h', price: ''}
    })
    const [meetings, setMeetings] = useState(false)
    const [initialMeetings, setInitialMeetings] = useState(false)

    const [canSave, setCanSave] = useState(false)
    const [isProvider, setIsProvider] = useState(false)

    const [fileInputOpened, setFileInputOpened] = useState(false)
    const [openDatePicker, setOpenDatePicker] = useState(false)

    const arraysEqual = (a, b) => {
        const setA = new Set(a)
        const setB = new Set(b)
        return setA.size === setB.size && [...setA].every(element => setB.has(element))
    }

    useEffect(() => {
        if(loggedUser) setIsProvider(loggedUser.roles.includes('provider'))
        console.log(loggedUser)
    }, [loggedUser])

    useEffect(() => {
        const genderChanged = !arraysEqual(initialGender, gender)
        const profileTypeChanged = !arraysEqual(initialProfileType, profileType)
        const languageChanged = !arraysEqual(initialLanguage, language)
        const orientationChanged = !arraysEqual(initialOrientation, orientation)
        const attributeChanged = !arraysEqual(initialAttribute, attribute)
        const serviceChanged = !arraysEqual(initialService, service)
        const nameChanged = !(initialName === name)
        const ageChanged = !(initialAge === age)
        const phoneNumberChanged = !(initialPhoneNumber === phoneNumber)
        const whatsappChanged = !(initialWhatsapp === whatsapp)
        const descriptionChanged = !(initialDescription === description)
        const locationChanged = !isEqual(initialLocation, location);
        const availabilityChanged = !isEqual(initialAvailability, availability)
        const timeAndPriceChanged = !isEqual(initialTimeAndPrice, timeAndPrice)
        const partnerNameChanged = !isEqual(initialPartnerName, partnerName)
        const partnerWebsiteChanged = !isEqual(initialPartnerWebsite, partnerWebsite)
        const birthDateChanged = !isEqual(initialBirthDate, birthDate)
        const meetingsChanged = !isEqual(initialMeetings,meetings)

        if(!(calculateAge(birthDate) > 17)) setCanSave(false)
        else setCanSave(genderChanged || profileTypeChanged || languageChanged || orientationChanged || attributeChanged || serviceChanged || nameChanged || ageChanged || phoneNumberChanged || whatsappChanged || descriptionChanged || locationChanged || availabilityChanged || timeAndPriceChanged || partnerNameChanged || partnerWebsiteChanged || birthDateChanged || meetingsChanged)
    }, [gender, initialGender, profileType, initialProfileType, language, initialLanguage, orientation, initialOrientation, attribute, initialAttribute, service, initialService, name, initialName, age, initialAge, phoneNumber, initialPhoneNumber, whatsapp, initialWhatsapp, initialDescription, description, location, initialLocation, availability, initialAvailability, timeAndPrice, initialTimeAndPrice, initialPartnerName, partnerName, initialPartnerWebsite, partnerWebsite, initialBirthDate, birthDate,initialMeetings,meetings])

    const handleItemClick = (clickedItem, number) => {
        switch(number) {
            case 1:
                if(!gender.includes(clickedItem))
                {
                    setGender([clickedItem])
                }
                break
            case 2:
                if(!profileType.includes(clickedItem))
                {
                    setProfileType([clickedItem])
                }
                break
            case 3:
                if(language.includes(clickedItem))
                {
                    const newLang = language.filter(item => item !== clickedItem)
                    setLanguage(newLang)
                }
                else
                {
                    setLanguage([...language, clickedItem])
                }
                break
            case 4:
                if(!orientation.includes(clickedItem))
                {
                    setOrientation([clickedItem])
                }
                break
            case 5:
                if(attribute.includes(clickedItem))
                {
                    const newAtt = attribute.filter(item => item !== clickedItem)
                    setAttribute(newAtt)
                }
                else
                {
                    setAttribute([...attribute, clickedItem])
                }
                break
            case 6:
                if(service.includes(clickedItem))
                {
                    const newService = service.filter(item => item !== clickedItem)
                    setService(newService)
                }
                else
                {
                    setService([...service, clickedItem])
                }
                break
            default:
                break
        }
    }

    const handleAcceptDate = (newDate) => {
        if (newDate) {
            setBirthDate(newDate)
        }
    }

    const handleDateChange = (newDate) => {
        if (newDate && typeof newDate.format === 'function') {
            setBirthDate(newDate)

            // Calculate age
            const today = dayjs()
            const birthDate = dayjs(newDate)
            let tempAge = today.year() - birthDate.year()

            // Adjust the age if the current date is before the birthday in the current year
            if (today.month() < birthDate.month() || (today.month() === birthDate.month() && today.date() < birthDate.date())) {
                tempAge--
            }

            setAge(tempAge)
        }
    }

    const handleToggle = () => {
        setMeetings(!meetings)
    }

    useEffect(() => {
        setName(loggedUser?.username)
        setInitialName(loggedUser?.username)
        setPartnerName(loggedUser?.club)
        setInitialPartnerName(loggedUser?.club)
        setPartnerWebsite(loggedUser?.website)
        setInitialPartnerWebsite(loggedUser?.website)
        setAge(loggedUser?.age)
        setInitialAge(loggedUser?.age)
        setPhoneNumber(loggedUser?.phoneNumber)
        setInitialPhoneNumber(loggedUser?.phoneNumber)
        setWhatsapp(loggedUser?.whatsApp)
        setInitialWhatsapp(loggedUser?.whatsApp)
        setDescription(loggedUser?.description)
        setInitialDescription(loggedUser?.description)
        setInitialLocation(loggedUser?.location)
        setLocation(loggedUser?.location)
        setGender(loggedUser?.gender ? [loggedUser?.gender] : [])
        setInitialGender(loggedUser?.gender ? [loggedUser?.gender] : [])
        setProfileType(loggedUser?.profileType ? [...loggedUser?.profileType] : [])
        setInitialProfileType(loggedUser?.profileType ? [...loggedUser?.profileType] : [])
        setLanguage(loggedUser?.language ? [...loggedUser?.language] : [])
        setInitialLanguage(loggedUser?.language ? [...loggedUser?.language] : [])
        setOrientation(loggedUser?.sexualOrientation ? [...loggedUser?.sexualOrientation] : [])
        setInitialOrientation(loggedUser?.sexualOrientation ? [...loggedUser?.sexualOrientation] : [])
        setAttribute(loggedUser?.attributes ? [...loggedUser?.attributes] : [])
        setInitialAttribute(loggedUser?.attributes ? [...loggedUser?.attributes] : [])
        setService(loggedUser?.services ? [...loggedUser?.services] : [])
        setInitialService(loggedUser?.services ? [...loggedUser?.services] : [])
        setInitialMeetings(loggedUser?.meetings)
        setMeetings(loggedUser?.meetings)
        if(loggedUser?.birthDate){
            setBirthDate(dayjs(loggedUser?.birthDate))
            setInitialBirthDate(dayjs(loggedUser?.birthDate))
        }
        if(loggedUser?.availability){
            setAvailability(loggedUser?.availability)
            setInitialAvailability(loggedUser?.availability)
        } else {
            setAvailability({
                Monday: { opening: null, closing: null },
                Tuesday: { opening: null, closing: null },
                Wednesday: { opening: null, closing: null },
                Thursday: { opening: null, closing: null },
                Friday: { opening: null, closing: null },
                Saturday: { opening: null, closing: null },
                Sunday: { opening: null, closing: null }
            })
            setInitialAvailability({
                Monday: { opening: null, closing: null },
                Tuesday: { opening: null, closing: null },
                Wednesday: { opening: null, closing: null },
                Thursday: { opening: null, closing: null },
                Friday: { opening: null, closing: null },
                Saturday: { opening: null, closing: null },
                Sunday: { opening: null, closing: null }
            })
        }
        if(loggedUser?.prices){
            setTimeAndPrice(loggedUser?.prices)
            setInitialTimeAndPrice(loggedUser?.prices)
        } else {
            setTimeAndPrice({
                FirstPrice: {duration: '15', unit: 'min', price: ''},
                SecondPrice: {duration: '30', unit: 'min', price: ''},
                ThirdPrice: {duration: '1', unit: 'h', price: ''},
                FourthPrice: {duration: '', unit: 'h', price: ''},
                FifthPrice: {duration: '', unit: 'h', price: ''}
            })
            setInitialTimeAndPrice({
                FirstPrice: {duration: '15', unit: 'min', price: ''},
                SecondPrice: {duration: '30', unit: 'min', price: ''},
                ThirdPrice: {duration: '1', unit: 'h', price: ''},
                FourthPrice: {duration: '', unit: 'h', price: ''},
                FifthPrice: {duration: '', unit: 'h', price: ''}
            })
        }
    }, [loggedUser])

    const handleSaveChanges = async () => {

        const userDataToUpdate = {
            id: loggedUser?._id,
            username: name,
            gender: gender[0],
            profileType: profileType[0],
            language,
            orientation: orientation[0],
            attributes: attribute,
            services: service,
            description,
            location,
            availability,
            prices: timeAndPrice,
            age,
            phoneNumber,
            meetings,
            whatsApp: whatsapp,
            club: partnerName,
            website: partnerWebsite,
            birthDate: birthDate
        }

        try {
            await updateUser(userDataToUpdate);
        } catch (error) {
            console.error('Error updating user:', error);
        }
    }

    if(isSuccess) console.log('SUCCESS !')

    const content = (
        <div className={`component-container ${EditUserProfileCSS['profile-container']} ${canSave && EditUserProfileCSS['container-padding']}`}>
            {isProvider ? <div className={EditUserProfileCSS['main-section']}>
                <div className={EditUserProfileCSS['image-section']}>
                    <img
                        src={loggedUser?.profileImage?.includes('camnextdoor') ? loggedUser?.profileImage : profileImage}
                        alt='profile_image'/>
                    <FontAwesomeIcon icon={faPen} onClick={() => setFileInputOpened(true)}/>
                </div>
                <div className={EditUserProfileCSS['name']}>
                    <h3>Profile Picture</h3>
                    <p>You can change your Profile Picture once every 14 days. It will be verified by our team. </p>
                </div>
            </div> : null}
            <div className={`${EditUserProfileCSS['item-section']} ${EditUserProfileCSS['item-section-location']}`}>
                <div className={EditUserProfileCSS['item-section-heading']}>
                    <FontAwesomeIcon icon={faLocationDot}/>
                    <h4>Location</h4>
                </div>
                <SignupLocation setLocation={setLocation} isToggled={true} location={location}/>
            </div>
            <div className={EditUserProfileCSS['item-section']}>
                <div className={EditUserProfileCSS['item-section-heading']}>
                    <FontAwesomeIcon icon={faUser}/>
                    <h4>Gender</h4>
                </div>
                <ul className={EditUserProfileCSS['select-list']}>
                    {genders.map((item, index) => (
                        <li
                            key={index}
                            className={gender?.includes(item.key) ? EditUserProfileCSS['active'] : ''}
                            onClick={() => handleItemClick(item.key, 1)}
                        >
                            {item.value}
                        </li>
                    ))}
                </ul>
            </div>
            {isProvider ? <div className={EditUserProfileCSS['item-section']}>
                <div className={EditUserProfileCSS['item-section-heading']}>
                    <FontAwesomeIcon icon={faList}/>
                    <h4>Profile type</h4>
                </div>
                <ul className={EditUserProfileCSS['select-list']}>
                    {profileTypes.map((item, index) => (
                        <li
                            key={index}
                            className={profileType?.includes(item.key) ? EditUserProfileCSS['active'] : ''}
                            onClick={() => handleItemClick(item.key, 2)}
                        >
                            {item.value}
                        </li>
                    ))}
                </ul>
            </div> : null}
            { isProvider && profileType[0] !== 'Independent' ? <div className={EditUserProfileCSS['item-section']}>
                <div className={EditUserProfileCSS['item-section-heading']}>
                    <FontAwesomeIcon icon={faGlobe}/>
                    <h4>Partner</h4>
                </div>
                <input
                    type="text"
                    placeholder="Partner Club (optional)"
                    id="partner-club"
                    autoComplete="off"
                    value={partnerName}
                    onChange={(e) => setPartnerName(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Partner Website URL (optional)"
                    id="partner-website"
                    autoComplete="off"
                    value={partnerWebsite}
                    onChange={(e) => setPartnerWebsite(e.target.value)}
                />
            </div> : null}
            <div className={EditUserProfileCSS['item-section']}>
                <div className={EditUserProfileCSS['item-section-heading']}>
                    <FontAwesomeIcon icon={faTag}/>
                    <h4>Username</h4>
                </div>
                <input
                    type='text'
                    placeholder='Name'
                    id='name'
                    autoComplete='off'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className={EditUserProfileCSS['item-section']}>
                <div className={EditUserProfileCSS['item-section-heading']}>
                    <FontAwesomeIcon icon={faCakeCandles}/>
                    <h4>Birth date</h4>
                </div>
                <input
                    type="text"
                    id="birthDate"
                    placeholder="Date of Birth"
                    name="birthDate"
                    autoComplete="off"
                    onClick={() => setOpenDatePicker(true)}
                    value={birthDate.format('DD-MM-YYYY')}
                    required
                    readOnly
                />
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            className={AuthCSS['date-picker']}
                            open={openDatePicker}
                            onOpen={() => setOpenDatePicker(true)}
                            onClose={() => setOpenDatePicker(false)}
                            onAccept={handleAcceptDate}
                            onChange={handleDateChange}
                            value={birthDate}
                            label='Choose Your Birth Data'
                            renderInput={(params) => (
                                <input
                                    onClick={params.inputProps.onClick} // Link input props provided by MUI
                                    value={birthDate.format('DD-MM-YYYY')}
                                    readOnly
                                    className={AuthCSS['input-field']}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </div>
            {isProvider ? <>
                <div className={EditUserProfileCSS['item-section']}>
                    <div className={EditUserProfileCSS['item-section-heading']}>
                        <FontAwesomeIcon icon={faMobile}/>
                        <h4>Phone Number</h4>
                    </div>
                    <input
                        type="text"
                        placeholder="Phone"
                        id="phone"
                        autoComplete="off"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </div>
                <div className={EditUserProfileCSS['item-section']}>
                    <div className={EditUserProfileCSS['item-section-heading']}>
                        <FontAwesomeIcon icon={faWhatsapp}/>
                        <h4>WhatsApp</h4>
                    </div>
                    <input
                        type='text'
                        placeholder='WhatsApp'
                        id='whatsapp'
                        autoComplete='off'
                        value={whatsapp}
                        onChange={(e) => setWhatsapp(e.target.value)}
                    />
                </div>

                <div className={EditUserProfileCSS['item-section']}>
                    <div className={EditUserProfileCSS['item-section-heading']}>
                        <FontAwesomeIcon icon={faEllipsis}/>
                        <h4>Description</h4>
                    </div>
                    <FlexibleTextarea value={description} setValue={setDescription}/>
                </div>
                <div className={EditUserProfileCSS['item-section']}>
                    <div className={EditUserProfileCSS['item-section-heading']}>
                        <FontAwesomeIcon icon={faCommentDots}/>
                        <h4>Languages</h4>
                    </div>
                    <ul className={EditUserProfileCSS['select-list']}>
                        {languages.map((item, index) => (
                            <li
                                key={index}
                                className={language?.includes(item.key) ? EditUserProfileCSS['active'] : ''}
                                onClick={() => handleItemClick(item.key, 3)}
                            >
                                {item.value}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={EditUserProfileCSS['item-section']}>
                    <div className={EditUserProfileCSS['item-section-heading']}>
                        <FontAwesomeIcon icon={faBolt}/>
                        <h4>Sexual Orientation</h4>
                    </div>
                    <ul className={EditUserProfileCSS['select-list']}>
                        {orientations.map((item, index) => (
                            <li
                                key={index}
                                className={orientation?.includes(item.key) ? EditUserProfileCSS['active'] : ''}
                                onClick={() => handleItemClick(item.key, 4)}
                            >
                                {item.value}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={EditUserProfileCSS['item-section']}>
                    <div className={EditUserProfileCSS['item-section-heading']}>
                        <FontAwesomeIcon icon={faCheck}/>
                        <h4>My Attributes</h4>
                    </div>
                    <ul className={EditUserProfileCSS['select-list']}>
                        {attributes.map((item, index) => (
                            <li
                                key={index}
                                className={attribute?.includes(item.key) ? EditUserProfileCSS['active'] : ''}
                                onClick={() => handleItemClick(item.key, 5)}
                            >
                                {item.value}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={EditUserProfileCSS['item-section']}>
                    <div className={EditUserProfileCSS['item-section-heading']}>
                        <FontAwesomeIcon icon={faHeart}/>
                        <h4>My Services</h4>
                    </div>
                    <ul className={EditUserProfileCSS['select-list']}>
                        {services.map((item, index) => (
                            <li
                                key={index}
                                className={service?.includes(item.key) ? EditUserProfileCSS['active'] : ''}
                                onClick={() => handleItemClick(item.key, 6)}
                            >
                                {item.value}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={EditUserProfileCSS['meetings-section']}>
                    <h4>Open for Meetings</h4>
                    <label className={EditUserProfileCSS['toggle-switch']}>
                        <input type='checkbox' checked={meetings} onChange={handleToggle}/>
                        <span className={EditUserProfileCSS['switch']}/>
                    </label>
                </div>
                {meetings ?
                <div className={`${EditUserProfileCSS['item-section']} ${EditUserProfileCSS['times-section']}`}>
                    <div className={EditUserProfileCSS['item-section-heading']}>
                        <FontAwesomeIcon icon={faClock}/>
                        <h4>I am available</h4>
                    </div>
                    <EditTimes times={availability} setTimes={setAvailability}/>
                </div>:null}
                {meetings ?
                <div className={`${EditUserProfileCSS['item-section']} ${EditUserProfileCSS['times-section']}`}>
                    <div className={EditUserProfileCSS['item-section-heading']}>
                        <FontAwesomeIcon icon={faMoneyBill}/>
                        <h4>My Prices</h4>
                    </div>
                    <EditPrices timeAndPrice={timeAndPrice} setTimeAndPrice={setTimeAndPrice}/>
                </div>
                    :null}
            </> : null}
            {canSave ?
                <button
                    className={EditUserProfileCSS['save-button']}
                    onClick={handleSaveChanges}
                    disabled={isLoading}
                >Save changes</button> : null}
            {changesSuccess ?
                <button
                    className={`${EditUserProfileCSS['save-button']} ${EditUserProfileCSS['success-button']}`}
                    onClick={handleSaveChanges}
                    disabled={true}
                >Changes saved <FontAwesomeIcon icon={faCheck}/></button> : null}
            <FileInputModal fileInputOpened={fileInputOpened} closeModal={() => setFileInputOpened(false)}
                            user={loggedUser}/>
        </div>
    )

    return content
}

export default EditUserProfile