import React, { useEffect, useRef, useState } from 'react'
import ContentSectionCSS from '../ContentSection.module.css'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import {useAddNewPremiumPostMutation} from '../../../features/premiumPosts/premiumPostsApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import {useUploadPostBlurMutation, useUploadPostMutation} from "../../../features/story/imageApiSLice"
import {useUpdateUserPremiumPostsMutation} from "../../../features/user/usersApiSlice"

const imageTypes = ['image/jpeg', 'image/jpg', 'image/png']
const videoTypes = ['video/mp4', 'video/webm', 'video/ogg']

const AddNewPremiumPost = ({ setAddNewActive, loggedUser }) => {
    const [fileSrc, setFileSrc] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const [fileType, setFileType] = useState('')
    const [canPublish, setCanPublish] = useState(false)
    const [description, setDescription] = useState('')
    const [title, setTitle] = useState('')
    const [price, setPrice] = useState(5)
    const [validPrice, setValidPrice] = useState(false)
    const cameraInputRef = useRef(null)
    const galleryInputRef = useRef(null)
    const [addNewPremiumPost, { isLoading: isLoadingPremiumPost }] = useAddNewPremiumPostMutation()
    const [uploadImageToS3, { isLoading: isUploadLoading }] = useUploadPostMutation()
    const [updateUser, { isLoading: isLoadingUpdateUser }] = useUpdateUserPremiumPostsMutation()
    const [uploadImageBlurToS3,{
    }] = useUploadPostBlurMutation()


    const handleFileUpload = (event) => {
        const file = event.target.files[0]
        setFileType(file.type)
        if (file) {
            setSelectedFile(file)
            const url = URL.createObjectURL(file)
            setFileSrc(url)
            setCanPublish(true)
        }
    }

    const removeSelectedFile = () => {
        setFileSrc('')
        setSelectedFile(null)
        setFileType('')
        setCanPublish(false)
    }

    useEffect(() => {
        if (selectedFile && validPrice) setCanPublish(true)
        else setCanPublish(false)
    }, [selectedFile, validPrice])

    useEffect(() => {
        if (price >= 5 && price <= 5000) setValidPrice(true)
        else setValidPrice(false)
    }, [price])

    const onTitleChange = (e) => {
        if (e.target.value.length <= 40) {
            setTitle(e.target.value)
        }
    }

    const onPriceChange = (e) => {
        setPrice(e.target.value)
    }

    const handleImageUpload = async () => {
        if (!selectedFile) {
            console.error('No file selected')
            return
        }

        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('email', loggedUser.email)
        formData.append('userId', loggedUser._id)
        formData.append('description', "premiumContent")

        try {
            const result = await uploadImageToS3(formData).unwrap()
            const blurred_result = await uploadImageBlurToS3(formData).unwrap()

            return {url:
                result.fileUrl,
                blurredUrl : blurred_result.fileUrl}
        } catch (error) {
            console.error('Error uploading image:', error)
            throw error
        }
    }

    const handlePublish = async () => {
        if (!selectedFile || !canPublish) return

        try {
            const fileUrls = await handleImageUpload()
            const postData = {
                userId: loggedUser._id,
                title,
                description,
                url: fileUrls.url,
                blurredUrl : fileUrls.blurredUrl,
                type: fileType.startsWith('image/') ? 'image' : 'video',
                price,
            }

            const response = await addNewPremiumPost(postData).unwrap()
            console.log(response.id)
            await updateUser({ id: loggedUser._id, privateId: response.id })
            setAddNewActive(false)
        } catch (error) {
            console.error('Failed to publish post:', error)
        }
    }

    return (
        <div className={ContentSectionCSS['add-new-container']}>
            <CloseOutlinedIcon onClick={() => setAddNewActive(false)} />
            <div className={ContentSectionCSS['upload-section']}>
                {selectedFile ? (
                    imageTypes.includes(fileType) ? (
                        <img src={fileSrc} alt="Selected" />
                    ) : (
                        <video controls autoPlay loop>
                            <source src={fileSrc} type={fileType} />
                            Your browser does not support the video tag.
                        </video>
                    )
                ) : (
                    <>
                        <CameraAltOutlinedIcon onClick={() => cameraInputRef.current.click()} />
                        <CollectionsOutlinedIcon onClick={() => galleryInputRef.current.click()} />
                    </>
                )}
                {selectedFile && (
                    <RemoveCircleOutlineOutlinedIcon
                        className={ContentSectionCSS['remove-file']}
                        onClick={removeSelectedFile}
                    />
                )}
            </div>
            <div className={ContentSectionCSS['description-section']}>
                <h3>Title</h3>
                <input value={title} type='text' placeholder='' onChange={onTitleChange} />
            </div>
            <div className={ContentSectionCSS['description-section']}>
                <h3>Description</h3>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder='Write here something about your media.'
                    maxLength='300'
                    rows='3'
                />
            </div>
            <div className={ContentSectionCSS['description-section']}>
                <h3>Price</h3>
                <input value={price} type='number' placeholder='CHF' onChange={onPriceChange} />
            </div>
            <button
                className={ContentSectionCSS['publish']}
                onClick={handlePublish}
                disabled={!canPublish || isUploadLoading || isLoadingPremiumPost}
            >
                {isUploadLoading || isLoadingPremiumPost ? (
                    <PulseLoader color={'#ffffff'} />
                ) : (
                    'Publish Content'
                )}
            </button>
            <input
                type="file"
                accept="video/*,image/*"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                ref={cameraInputRef}
                capture="camcorder"
            />
            <input
                type="file"
                accept="video/*,image/*"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                ref={galleryInputRef}
            />
        </div>
    )
}

export default AddNewPremiumPost
