import { apiSlice } from '../../app/api/apiSlice'

const documentsTokenApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDocumentsByUserId: builder.mutation({
            query: initialUserData => ({
                url: '/documentsTokens/filter',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'DocumentsToken', id: "LIST" }
            ]
        }),
    }),
})

export const { useGetDocumentsByUserIdMutation } = documentsTokenApiSlice;
