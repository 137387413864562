import React, {useEffect, useRef, useState} from 'react'
import UserStoriesCSS from './UserStories.module.css'
import {
    selectStoryById,
    useUpdateStoryLikeMutation,
    useUpdateStoryUnLikeMutation
} from '../../features/story/storiesApiSlice'
import {useSelector} from 'react-redux'
import {selectUserById} from '../../features/user/usersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import Slider from 'react-slick'
import {useLocation, useParams} from 'react-router-dom'
import useHeightWindow from '../../hooks/useHeightWindow'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faVolumeMute, faVolumeUp} from '@fortawesome/free-solid-svg-icons'
import fireIcon from '../../assets/img/fire-icon.png'
import LoginRedirection from './LoginRedirection'

const useStoryUrlsAndTypes = (storiesIds) => {
    return useSelector((state) => {
        const stories = storiesIds?.map((storyId) => selectStoryById(state, storyId))
        return stories?.map((story) => ({ id : story?._id, url: story?.url, storyText : story?.storyText ,likes :story?.likes, type : 'video' }))
    })
}

const ProfileStories = ({dots, muted, swipe, loggedUser}) => {

    const {id} = useParams()
    const {pathname} = useLocation()
    const windowHeight = useHeightWindow()
    const [UseUpdateStoryUnLike] = useUpdateStoryUnLikeMutation()
    const [UseUpdateStoryLike] = useUpdateStoryLikeMutation()

    const paramUser = useSelector(state => selectUserById(state, id))

    const [currentSlide, setCurrentSlide] = useState(0)
    const [showLoader, setShowLoader] = useState(true)
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const [progress, setProgress] = useState(0)
    const [left, setLeft] = useState(0)
    const [storyCount, setStoryCount] = useState(0)
    const [redirectOpened, setRedirectOpened] = useState(false)
    const [isMuted, setIsMuted] = useState(false)
    const videoRefs = useRef([])

    const currentStories = useStoryUrlsAndTypes(paramUser?.stories)
    const showIcons = pathname.includes('user-story')

    useEffect(() => {
        setIsMuted(muted)
    }, [muted])

    useEffect(() => {
        if (paramUser && currentStories && showLoader){
            setShowLoader(false)
        }
    }, [paramUser, currentStories])

    useEffect(() => {
        if(paramUser) setStoryCount(paramUser.stories?.length)
    }, [paramUser])

    useEffect(() => {
        const currentVideo = videoRefs.current[currentSlide];
        if (currentVideo) {
            const playPromise = currentVideo.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    // Automatic playback started!
                    // You can handle UI changes here if needed.
                }).catch(error => {
                    // Auto-play was prevented
                    // Handle the error or show paused UI.
                });
            }
        }
        return () => {
            videoRefs.current.forEach(video => {
                if (video) {
                    video.currentTime =0;
                    video.pause();
                    video.removeAttribute('src'); // This will stop downloading the video
                }
            });
        };
    }, [currentSlide]);

    const toggleMute = () => {
        setIsMuted(!isMuted)
        videoRefs.current.forEach(video => {
            if (video) {
                video.muted = !isMuted
            }
        })
    }

    const isLiked = () =>{
        return loggedUser && currentStories && currentStories[currentSlide] && currentStories[currentSlide].likes?.includes(loggedUser._id)
    }

    const handleFireClicked = async () => {
        if (loggedUser && currentStories && currentStories[currentSlide]) {
            if (isLiked()) {
                await UseUpdateStoryUnLike({id: currentStories[currentSlide]?.id, userId: loggedUser._id})
            } else {
                await UseUpdateStoryLike({id: currentStories[currentSlide]?.id, userId: loggedUser._id})
            }
        }
        else{
            setRedirectOpened(true)
        }
    }

    const handleBeforeChange = (oldIndex, newIndex) => {
        setCurrentSlide(newIndex)
    }

    const handleVideoLoad = (event, index) => {
        if (index === 0 && isInitialLoad) {
            videoRefs.current[0].play()
            setIsInitialLoad(false)
        }
    }

    const handleVideoEnd = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext()
        }
    }

    useEffect(() => {
        setLeft((currentSlide / storyCount) * 100)
    }, [currentSlide])

    const videoStyle = {
        height: `calc(${windowHeight}px - 62px)`,
    }

    const sliderRef = useRef()

    const settings = {
        dots: dots,
        dotsClass: 'custom-dots',
        infinite: true,
        fade:true,
        speed: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        swipeToSlide: swipe,
        beforeChange: handleBeforeChange,
    }

    let content

    if(showLoader){content = <PulseLoader color={'#F92E85FF'} className='pulse-loader'/>
    } else {
        content = (
            <div className={UserStoriesCSS['story-container']}>
                {currentStories?.length ? (
                    <Slider ref={sliderRef} {...settings}>
                        {currentStories?.map((story, index) => (
                            <div key={index}>
                                {story?.url ? (
                                        <>
                                            <video
                                                style={videoStyle}
                                                loop={currentStories.length === 1}
                                                ref={el => videoRefs.current[index] = el}
                                                preload='metadata'
                                                onLoadedMetadata={(e) => handleVideoLoad(e, index)}
                                                onEnded={() => handleVideoEnd()}
                                                onError={(e) => console.error('Video loading error:', e)}
                                                onTimeUpdate={(e) => {
                                                    setProgress(e.target.currentTime / e.target.duration);
                                                }}
                                                playsInline
                                                muted={isMuted}
                                            >
                                                <source src={story?.url} type='video/mp4'/>
                                                Your browser does not support the video tag.
                                            </video>
                                            <p className={story?.storyText !== '' ? `${UserStoriesCSS['story-text']}` : ''}
                                            >{story?.storyText}</p>
                                        </>)

                                    : <PulseLoader color={'#F92E85FF'} className='pulse-loader'/>
                                }
                            </div>
                        ))}
                    </Slider>
                ) : <h3 className={UserStoriesCSS['no-stories']}>This user has no stories yet.</h3>}
                {showIcons &&
                <div className={UserStoriesCSS['profile-stories-heading-buttons']}>
                    <button className="blur-button">
                        <FontAwesomeIcon
                            icon={isMuted ? faVolumeMute : faVolumeUp}
                            onClick={toggleMute}
                        />
                    </button>
                </div>
                }
                {showIcons &&
                <div className={`${UserStoriesCSS['profile-stories-heading-buttons']} ${UserStoriesCSS['profile-stories-heading-buttons-top']}`}>
                    <button
                            className={`blur-button ${UserStoriesCSS['reaction-like']} ${isLiked() ? UserStoriesCSS['active-fire'] : UserStoriesCSS['not-active-fire']}`}
                            onClick={() => handleFireClicked()}
                    >
                        <img src={fireIcon} alt="Custom Fire Icon"/>
                    </button>
                </div>}
                <div className={UserStoriesCSS['loader-container']} style={{
                    width: `calc(${100 / storyCount}% - ${storyCount}px)`,
                    left: `calc(${left}% + 1px + ${currentSlide * 1}px)`
                }}>
                    <div className={UserStoriesCSS['loader']} style={{width: `${progress * 100}%`}}>

                    </div>
                </div>
                <LoginRedirection redirectOpened={redirectOpened} closeModal={() => setRedirectOpened(false)}/>
            </div>
        )
    }

    return content
}

export default ProfileStories