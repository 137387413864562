import galleryPlaceholder from '../../assets/img/gallery-placeholder.jpg'
import StandardUserCSS from './StandardUser.module.css'
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined'
const FeedSection = () => {

    const galleryContent = () => {
        const images = [];
        for (let i = 0; i < 8; i++) {
            images.push(
                <div key={i} className={StandardUserCSS['single-post']}>
                    <div>
                        <img src={galleryPlaceholder} alt='gallery-placeholder' className={StandardUserCSS['gallery-item']}/>
                    </div>
                    <div className={StandardUserCSS['text-content']}>
                        <div>
                            <p>Curabitur vestibulum sem a lorem porta bibendum sit amet eu nisl. In varius sodales lorem, et
                                ullamcorper quam euismod sit amet. Donec porttitor velit egestas elit volutpat elementum.
                                Proin sed lectus dignissim, fermentum nibh nec, faucibus orci. Suspendisse venenatis velit
                                iaculis rutrum tincidunt. Suspendisse potenti. Maecenas vel risus fringilla, rhoncus ex
                                congue, sagittis enim. </p>
                            <span>15. April 2024.</span>
                        </div>
                        <div>
                            <LocalFireDepartmentOutlinedIcon />
                            <span>67</span>
                        </div>
                    </div>
                </div>
            );
        }
        return images
    }

    return (
        <div className={StandardUserCSS['feed-container']}>
            <div className={StandardUserCSS['post-feed']}>
                <h4>You have no premium content yet.</h4>
            </div>
        </div>
    )
}

export default FeedSection