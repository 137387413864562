import BroadcasterSettingsCSS from '../BroadcasterSettings/BroadcasterSettings.module.css'
import Modal from 'react-modal'
import {useDeleteAdvertiserMutation} from '../../features/advertiser/advertiserApiSlice'

Modal.setAppElement('#root')

const RemoveAd = ({ isConfirmationOpen, closeModal, adId }) => {

    const [deleteAd, { isLoading }] = useDeleteAdvertiserMutation()

    const confirmSelection = async () => {
        try {
            await deleteAd({ id: adId }).unwrap();
            console.log(adId)
        } catch (err) {
            console.error('Failed to delete ad:', err);
        }
    }

    return (
        <Modal
            isOpen={isConfirmationOpen}
            onRequestClose={closeModal}
            className={
                {
                    base: `modal-content ${BroadcasterSettingsCSS['confirmation-modal-content']}`,
                    afterOpen: 'modal-content--after-open',
                    beforeClose: 'modal-content--before-close'
                }
            }
            overlayClassName={
                {
                    base: 'modal-overlay',
                    afterOpen: 'modal-overlay--after-open',
                    beforeClose: 'modal-overlay--before-close'
                }
            }
            closeTimeoutMS={300}
        >
            <div className='modal-close'>
                <button
                    onClick={closeModal}
                    className='close-button'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path
                            d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/>
                    </svg>
                </button>
            </div>
            <h4>Are you sure you want to stop managing this account?</h4>
            <div className={BroadcasterSettingsCSS['confirmation-buttons']}>
                <button onClick={async () => {
                    await confirmSelection()
                    closeModal()
                }}>Yes</button>
                <button onClick={() => closeModal()}>No</button>
            </div>
        </Modal>
    )
}
export default RemoveAd