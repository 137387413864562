import {Link, useLocation} from 'react-router-dom'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import FavoriteIcon from '@mui/icons-material/Favorite'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import PersonIcon from '@mui/icons-material/Person'
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined'
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import {useEffect, useState} from 'react'

const DashNav = ({email, loggedUser}) => {

    const [userRoute, setUserRoute] = useState('')

    const { pathname, state } = useLocation()

    useEffect(() => {
        if(!email) setUserRoute('/dash/login')
        else {
            if (['user', 'admin'].some(role => loggedUser?.roles.includes(role))) {
                setUserRoute('/dash/user/following')
            }
            else {
                if (loggedUser?.verified){
                    setUserRoute('/dash/user/my-stories')
                }
                else {
                    setUserRoute('/dash/verify')
                }
            }
        }
    }, [email, loggedUser])

    const handleRefresh = () => {
        window.location.reload()
    };

    const content = (
        <header className='dash-nav'>
            <nav className='dash-nav__container'>
                        {pathname === '/dash/user/settings/manager-settings' ? (
                            <Link to={state?.from}>
                                <KeyboardBackspaceRoundedIcon fontSize="large" />
                            </Link>
                        ) : (
                            <Link to={userRoute}>
                                {['/dash/user/following', '/dash/user/my-stories', '/dash/login', '/dash/signup'].includes(pathname)
                                    ? <PersonIcon fontSize="large" />
                                    : <Person2OutlinedIcon fontSize="large" />}
                            </Link>
                        )}
                {pathname === '/dash/stories' ? (
                    <FmdGoodIcon fontSize="large" onClick={handleRefresh}/>
                ) : pathname.startsWith('/dash/user/') && !['impressions', 'settings', 'edit', 'following', 'content-section'].some(part => pathname.includes(part)) && !pathname.startsWith('/dash/user/my-stories') && pathname.split('/').length === 4 && state?.from && !['search', 'following', 'content-section'].some(part => state?.from.includes(part)) ? (
                    <Link to={state?.from} className='nav-pin'>
                        <KeyboardBackspaceRoundedIcon fontSize="large"/>
                    </Link>
                )
                    : pathname.startsWith('/dash/user/') && !['impressions', 'settings', 'edit', 'following', 'content-section'].some(part => pathname.includes(part)) && !pathname.startsWith('/dash/user/my-stories') && pathname.split('/').length === 4 && state?.from && !['following', 'content-section'].some(part => state?.from.includes(part)) ? (
                        <Link to='/dash/search-results' className='nav-pin'>
                            <KeyboardBackspaceRoundedIcon fontSize="large"/>
                        </Link>
                    )
                    : pathname.startsWith('/dash/user/') && !['impressions', 'settings', 'edit', 'following', 'content-section'].some(part => pathname.includes(part)) && !pathname.startsWith('/dash/user/my-stories') && pathname.split('/').length === 4 && state?.from && !['following', 'content-section'].some(part => state?.from.includes(part)) ? (
                    <Link to='/dash/stories' className='nav-pin'>
                        <KeyboardBackspaceRoundedIcon fontSize="large"/>
                    </Link>
                ) : pathname === '/dash/search' ? (
                    <Link to='/dash/stories' className='nav-pin'>
                        <KeyboardBackspaceRoundedIcon fontSize="large"/>
                    </Link>
                ) : pathname === '/dash/search-results' ? (
                    <Link to='/dash/search' className='nav-pin'>
                        <KeyboardBackspaceRoundedIcon fontSize="large"/>
                    </Link>
                ) : pathname === `/dash/user/${state?.id}/user-story` && state?.from && state.from === `/dash/user/${state?.id}` ? (
                    <Link to={`/dash/user/${state?.id}`} className='nav-pin' state={{ from: state?.history }}>
                        <KeyboardBackspaceRoundedIcon fontSize="large"/>
                    </Link>
                ) : (
                    <Link to='/dash/stories' className='nav-pin'>
                        <PlaceOutlinedIcon fontSize="large"/>
                    </Link>
                )}
                {
                    pathname.startsWith('/dash/user/content-section') ? (
                        <FavoriteIcon fontSize="large" />
                    ) : pathname.startsWith('/dash/user/') && pathname.split('/').length === 4 && state?.from === '/dash/user/content-section' ? (
                        <Link to={state?.from} className="nav-pin">
                            <KeyboardBackspaceRoundedIcon fontSize="large" />
                        </Link>
                    ) : (
                        <Link to="/dash/user/content-section">
                            <FavoriteBorderOutlinedIcon fontSize="large" />
                        </Link>
                    )
                }
                <Link to='/dash/coming-soon'>
                    {pathname === '/dash/coming-soon' ? <VideoCameraFrontIcon fontSize="large"/> : <VideoCameraFrontOutlinedIcon fontSize="large"/>}
                </Link>
            </nav>
        </header>
    )

    return content
}
export default DashNav