import PulseLoader from "react-spinners/PulseLoader"
import BroadcasterSettingsCSS from './BroadcasterSettings.module.css'

const Follower = ({user}) => {

    if(!user) return <PulseLoader color={'#F92E85FF'}/>

    return(
        <div className={BroadcasterSettingsCSS['single-follower']}>
            <h3>{user.username}</h3>
        </div>
    )
}

export default Follower