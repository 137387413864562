import GallerySectionCSS from './GallerySection.module.css'

const LiveCamSection = () => {
    return(
        <div className={GallerySectionCSS['livecam-section']}>
            <h2>Live Cam</h2>
            <p>The new Live Cam function will be soon online!</p>
        </div>
    )
}

export default LiveCamSection