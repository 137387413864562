import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

// Initialize the entity adapter
const managerFollowingUserAdapter = createEntityAdapter({});

// Initial state using the entity adapter
const initialState = managerFollowingUserAdapter.getInitialState();

// API Endpoints for ManagerFollowingUser
export const managerFollowingUserApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Mutation for a manager to follow a user
        followUser: builder.mutation({
            query: ({ managerId, userId, permissionLevel }) => ({
                url: '/manager-following-user/follow',
                method: 'POST',
                body: { managerId, userId, permissionLevel }
            }),
            invalidatesTags: [{ type: 'ManagerFollowingUser', id: 'LIST' }],
        }),

        // Mutation for a manager to unfollow a user
        unfollowUser: builder.mutation({
            query: ({ managerId, userId }) => ({
                url: '/manager-following-user/unfollow',
                method: 'DELETE',
                body: { managerId, userId }
            }),
            invalidatesTags: [{ type: 'ManagerFollowingUser', id: 'LIST' }],
        }),

        // Mutation to update permission level for a manager-user relationship
        updatePermission: builder.mutation({
            query: ({ managerId, userId, permissionLevel }) => ({
                url: `/manager-following-user/permission`,
                method: 'PUT',
                body: { managerId, userId, permissionLevel }
            }),
            invalidatesTags: [{ type: 'ManagerFollowingUser', id: 'LIST' }],
        }),

        // Query to get all users followed by a manager
        getFollowedUsers: builder.query({
            query: (managerId) => ({
                url: `/manager-following-user/${managerId}/followed-users`,
                method: 'GET',
            }),
            transformResponse: (responseData) => {
                const normalizedUsers = responseData.map(user => {
                    user.id = user._id; // Ensure each user has an `id` field for normalization
                    return user;
                });

                return managerFollowingUserAdapter.setAll(initialState, normalizedUsers);
            },
            providesTags: (result, error, managerId) => [
                { type: 'ManagerFollowingUser', id: managerId },
                { type: 'ManagerFollowingUser', id: 'LIST' },
            ],
        }),


        // Query to check if a user has a manager and return their permission level
        checkUserManager: builder.query({
            query: (userId) => ({
                url: `/manager-following-user/${userId}/check-manager`,
                method: 'GET',
            }),
            providesTags: (result, error, userId) => [
                { type: 'ManagerFollowingUser', id: userId },
                { type: 'ManagerFollowingUser', id: 'LIST' },
            ],
        }),

        // Query to get the manager following a specific user
        getFollowingManager: builder.query({
            query: (userId) => ({
                url: `/manager-following-user/${userId}/following-manager`,
                method: 'GET',
            }),
            providesTags: (result, error, userId) => [
                { type: 'ManagerFollowingUser', id: userId },
                { type: 'ManagerFollowingUser', id: 'LIST' },
            ],
        }),
    }),
});

// Export hooks for mutations and queries
export const {
    useFollowUserMutation,
    useUnfollowUserMutation,
    useUpdatePermissionMutation,
    useGetFollowedUsersQuery,
    useCheckUserManagerQuery,
    useGetFollowingManagerQuery,
} = managerFollowingUserApiSlice;

// returns the query result object
export const selectManagerFollowingUserResult = managerFollowingUserApiSlice.endpoints.getFollowedUsers.select();

// creates memoized selector for normalized state
const selectManagerFollowingUserData = createSelector(
    selectManagerFollowingUserResult,
    (managerFollowingUserResult) => managerFollowingUserResult?.data ?? initialState
);

// getSelectors creates these selectors, and we rename them with aliases using destructuring
export const {
    selectAll: selectAllFollowedUsers,
    selectById: selectFollowedUserById,
    selectIds: selectFollowedUserIds
} = managerFollowingUserAdapter.getSelectors(state => selectManagerFollowingUserData(state));
