import EmailVerifyCSS from "./EmailVerify.module.css"
import {useVerifyProfileImageMutation} from "../../features/user/usersApiSlice"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEnvelope} from "@fortawesome/free-regular-svg-icons"
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons"
import PulseLoader from "react-spinners/PulseLoader"
import {useNavigate, useParams} from "react-router-dom"
import {useEffect} from "react"

const ProfileImageVerification = () => {

    const [verifyProfileImage, {isSuccess, isLoading, isError, Error}] = useVerifyProfileImageMutation()
    const navigate = useNavigate()
    const { id, token, accepted } = useParams()

    useEffect(() => {

        const verifyToken = async () => {
            if(accepted === 'accept') await verifyProfileImage({userId: id, profileImageToken: token, accepted: true})
            else await verifyProfileImage({userId: id, profileImageToken: token, accepted: false})
        }

        verifyToken()
    }, [])

    return(
        <div className={EmailVerifyCSS['verify-container']}>
            {isSuccess && accepted === 'accept' ? (
                <>
                    <span className={`${EmailVerifyCSS['icon-span']} ${EmailVerifyCSS['accepted']}`}><FontAwesomeIcon icon={faCheck} /></span>
                    <h3>Profile image verified successfully!</h3>
                </>
            ) : isSuccess && accepted === 'decline' ? (
                <>
                    <span className={`${EmailVerifyCSS['icon-span']} ${EmailVerifyCSS['decline']}`}><FontAwesomeIcon icon={faXmark}/></span>
                    <h3>Profile image verification declined!</h3>
                </>
            ) : isLoading ? (
                <div><PulseLoader color={'#F92E85FF'}/></div>
            ) : (
                <h3>Link expired</h3>
            )}
        </div>
    )
}

export default ProfileImageVerification