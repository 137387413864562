import {useSelector} from 'react-redux'
import {selectStoryById} from '../../features/story/storiesApiSlice'
import UserStoriesCSS from './UserStories.module.css'
import PulseLoader from 'react-spinners/PulseLoader'
import React, {useEffect, useRef, useState} from 'react'

const Story = ({ storyId, videoRefs, index, isMuted, isSingle, sliderRef, isActive, currentSlide, storyCount }) => {

    const [progress, setProgress] = useState(0)
    const [left, setLeft] = useState(0)
    const individualVideoRef = useRef(null)

    const story = useSelector(state => selectStoryById(state, storyId))

    const handleVideoEnd = () => {
        // Move to the next slide when the video ends
        if (sliderRef.current) {
            sliderRef.current.slickNext()
        }
    }

    useEffect(() => {
        if (isActive && currentSlide === index) {
            const currentVideo = individualVideoRef.current
            if (currentVideo) {
                const playPromise = currentVideo.play();
                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        // Automatic playback started!
                    }).catch(error => {
                        // Auto-play was prevented
                        console.error('Auto-play was prevented:', error);
                    });
                }
            }
        }
    }, [currentSlide, isActive, index])

    useEffect(() => {
        setLeft((index / storyCount) * 100)
    }, [currentSlide])

    return (
        <div className={UserStoriesCSS['story-wrapper']} key={index}>
            {story?.url ? (
                    <>
                        <video
                            loop={isSingle}
                            ref={el => {
                                videoRefs.current[index] = el
                                individualVideoRef.current = el
                            }}
                            onEnded={() => handleVideoEnd()}
                            onError={(e) => console.error('Video loading error:', e)}
                            playsInline
                            autoPlay={false}
                            muted={isMuted}
                            onTimeUpdate={(e) => {
                                setProgress(e.target.currentTime / e.target.duration);
                            }}
                        >
                            <source src={story?.url} type='video/mp4'/>
                            Your browser does not support the video tag.
                        </video>
                        <p className={story?.storyText !== '' ? `${UserStoriesCSS['story-text']}` : ''}
                        >{story?.storyText}</p>
                    </>
                )
                : <PulseLoader color={'#F92E85FF'} className='pulse-loader'/>
            }
            <div className={UserStoriesCSS['loader-container']} style={{
                width: `calc(${100 / storyCount}% - ${storyCount}px)`,
                left: `calc(${left}% + 1px + ${index * 1}px)`
            }}>
                <div className={UserStoriesCSS['loader']} style={{width: `${progress * 100}%`}}>

                </div>
            </div>
        </div>
    )
}

export default Story