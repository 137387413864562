import {useEffect, useRef, useState} from 'react'
import SearchComponentCSS from './SearchComponent.module.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faLocationDot} from '@fortawesome/free-solid-svg-icons'
import {useSelector} from 'react-redux'
import {selectAllUsers, useGetUsersByFilterMutation} from '../../features/user/usersApiSlice'
import {useNavigate} from 'react-router-dom'
import { Slider } from '@mui/material'
import SignupLocation from '../../features/auth/SignupLocation'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

function valuetext(value) {
    return `${value}°C`
}

const SearchComponent = ({searchFunc, searchFilters}) => {

    const users = useSelector(state => selectAllUsers(state))

    const genders = ['Female', 'Male', 'Trans', 'Other']
    const categories = ['Independent' , 'Studio', 'Escort', 'Club', 'Saunaclub']
    const attributes = ['Shaved', 'Implants', 'Tattos', 'Smoking', 'Alkohol']
    const services = ['Anal', 'Deep Throat', 'Dinner Date', 'Dirtytalk' ,'Domination (hard)' ,'Domination (soft)' ,'Escort' ,'Facesitting' ,'Fisting' ,'Footjob' ,'French' ,'Gangbang' ,'Girlfriend Sex' ,'Handjob' ,'Kamasutra' ,'Kissing' ,'Licking (active)' ,'Licking (passive)' ,'Massage' ,'Masturbation' ,'Open for relationsship' ,'Pissing (active)' ,'Pissing (passive)' ,'Position 69' ,'Prostatamassage' ,'Roleplay' ,'Sell clothing' ,'Sell photos / videos' ,'Sex in different positions'  ,'Sexchat' ,'Strapon' ,'Striptease' ,'Tantra' ,'Toys (active)' ,'Toys (passive)' ,'Webcam']

    const navigate = useNavigate()
    const {
        username,
        setUsername,
        gender,
        setGender,
        selectedGenders,
        setSelectedGenders,
        selectedCategories,
        setSelectedCategories,
        selectedAttributes,
        setSelectedAttributes,
        selectedServices,
        setSelectedServices,
        age,
        setAge,
        minAge,
        setMinAge,
        maxAge,
        setMaxAge,
        price,
        setPrice,
        location,
        setLocation,
        distance,
        setDistance,
        isToggled,
        setIsToggled,
        value,
        setValue
    } = searchFilters

    const userRef = useRef()

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        const isChange = (
            selectedGenders.length > 0 ||
            selectedCategories.length > 0 ||
            selectedAttributes.length > 0 ||
            selectedServices.length > 0 ||
            minAge !== 20 ||
            maxAge !== 80 ||
            price !== 0 ||
            location !== '' ||
            distance !== 0 ||
            isToggled !== false ||
            value[0] !== 20 ||
            value[1] !== 37
        );
        setIsButtonDisabled(isChange);
    }, [searchFilters]);


    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleUserInput = (e) => setUsername(e.target.value)
    const handleLowerInputChange = (event) => {
        const value = Math.min(Number(event.target.value), maxAge - 1)
        setMinAge(value)
    }
    const handleUpperInputChange = (event) => {
        const value = Math.max(Number(event.target.value), minAge + 1)
        setMaxAge(value)
    }
    const handleAgeChange = (e) => {
        setAge(e.target.value)
    }
    const handlePriceChange = (e) => {
        setPrice(e.target.value)
    }
    const handleDistanceChange = (e) => {
        setDistance(e.target.value)
    }
    const handleToggle = () => {
        setIsToggled(!isToggled)
    }

    const resetSearchFilters = () => {
        setGender([]);
        setSelectedGenders([]);
        setSelectedCategories([]);
        setSelectedAttributes([]);
        setSelectedServices([]);
        setAge(0);
        setMinAge(20);
        setMaxAge(80);
        setPrice(0);
        setLocation('');
        setDistance(0);
        setIsToggled(false);
        setValue([20, 37]);
    }


    const [UseGetUsersByFilter,{
        isLoading,
        isError,
        isSuccess
    }] = useGetUsersByFilterMutation()
    const buttonSwitch = (e, item, selectedItems, setSelectedItems) => {
        e.preventDefault()
        if (selectedItems.includes(item)) {
            const newItems = selectedItems.filter((selectedItem) => selectedItem !== item)
            setSelectedItems(newItems)
        } else {
            setSelectedItems([...selectedItems, item])
        }
    }
    const handleSearch = async (e) => {
        e.preventDefault();
        const filters = {
            username,
            gender: selectedGenders.length > 0 ? selectedGenders[0].toLowerCase() : undefined,
            profileType: selectedCategories,
            attributes: selectedAttributes,
            services: selectedServices,
            minAge: value[0],
            maxAge: value[1],
            price,
            openForMeeting: isToggled,
            location : location,
            maxDistance: distance
    };

        try {
            // Execute the mutation to get filtered users
            const response = await UseGetUsersByFilter(filters);

            // Extract the list of user IDs from the response data
            const idList = response.data.map(item => item._id);

            // Use the filtered user IDs as needed (e.g., update component state)
            searchFunc(idList);

            // Navigate to search results page
            navigate('/dash/search-results', { state: { from: '/dash/search' } })
        } catch (error) {
            console.error('Error while fetching filtered users:', error);
            // Handle error if necessary
        }
    };

    const content = (
        <div className={`component-container ${SearchComponentCSS['search-container']}`}>
            <form onSubmit={handleSearch}>
                <h2>Search</h2>
                <input
                    type='text'
                    placeholder='Username'
                    id='username'
                    ref={userRef}
                    value={username}
                    onChange={handleUserInput}
                    autoComplete='off'
                    className={SearchComponentCSS['username-search']}
                />
                <div className={SearchComponentCSS['item-section']}>
                    <h4>Gender</h4>
                    <ul>
                        {genders.map((item, index) => (
                            <li key={index}>
                                <button
                                    className={selectedGenders?.includes(item) ? SearchComponentCSS['active'] : ''}
                                    onClick={(e) => buttonSwitch(e, item, selectedGenders, setSelectedGenders)}
                                >
                                    {item}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={SearchComponentCSS['item-section']}>
                    <h4>Category</h4>
                    <ul>
                        {categories.map((item, index) => (
                            <li key={index}>
                                <button
                                    className={selectedCategories?.includes(item) ? SearchComponentCSS['active'] : ''}
                                    onClick={(e) => buttonSwitch(e, item, selectedCategories, setSelectedCategories)}
                                >
                                    {item}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={SearchComponentCSS['item-section']}>
                    <h4>Attributes</h4>
                    <ul>
                        {attributes.map((item, index) => (
                            <li key={index}>
                                <button
                                    className={selectedAttributes?.includes(item) ? SearchComponentCSS['active'] : ''}
                                    onClick={(e) => buttonSwitch(e, item, selectedAttributes, setSelectedAttributes)}
                                >
                                    {item}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={SearchComponentCSS['item-section']}>
                    <h4>Services</h4>
                    <ul>
                        {services.map((item, index) => (
                            <li key={index}>
                                <button
                                    className={selectedServices?.includes(item) ? SearchComponentCSS['active'] : ''}
                                    onClick={(e) => buttonSwitch(e, item, selectedServices, setSelectedServices)}
                                >
                                    {item}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={SearchComponentCSS['item-section']}>
                    <h4>Age</h4>
                    <div className={SearchComponentCSS['age-slider']}>
                        <Slider
                            getAriaLabel={() => 'Age range'}
                            value={value}
                            onChange={handleChange}
                            valueLabelDisplay='on'
                            getAriaValueText={valuetext}
                            min={18}
                            mac={80}
                            sx={{
                                height: 8,
                                '& .MuiSlider-thumb': {
                                    color: '#fff',
                                    width: '25px',
                                    height: '25px',
                                },
                                '& .MuiSlider-track': {
                                    color: '#F92E85',
                                },
                                '& .MuiSlider-rail': {
                                    color: '#b6b6b6',
                                },
                                '& .MuiSlider-valueLabel': {
                                    bgcolor: 'transparent',
                                    color: '#000',
                                    mt: '16px',
                                    fontSize: '12px',
                                    paddingBottom: '5px'
                                },
                                '& .MuiSlider-thumb.Mui-active': {
                                    boxShadow: '0px 0px 0px 14px rgba(249, 46, 133, 0.16)'
                                },
                            }}
                        />
                    </div>
                </div>
                <div className={SearchComponentCSS['meetings']}>
                    <h4>Open for meetings</h4>
                    <label className={SearchComponentCSS['toggle-switch']}>
                        <input type='checkbox' checked={isToggled} onChange={handleToggle}/>
                        <span className={SearchComponentCSS['switch']}/>
                    </label>
                </div>
                <div className={SearchComponentCSS['item-section']}>
                    <h4>Max Price</h4>
                    <Slider
                        getAriaLabel={() => 'Max price'}
                        value={price}
                        onChange={handlePriceChange}
                        valueLabelDisplay='on'
                        getAriaValueText={valuetext}
                        sx={{
                            height: 8,
                            '& .MuiSlider-thumb': {
                                color: '#fff',
                                width: '25px',
                                height: '25px',
                            },
                            '& .MuiSlider-track': {
                                color: '#F92E85',
                            },
                            '& .MuiSlider-rail': {
                                color: '#b6b6b6',
                            },
                            '& .MuiSlider-valueLabel': {
                                bgcolor: 'transparent',
                                color: '#000',
                                mt: '16px',
                                fontSize: '12px',
                                paddingBottom: '5px'
                            },
                            '& .MuiSlider-thumb.Mui-active': {
                                boxShadow: '0px 0px 0px 14px rgba(249, 46, 133, 0.16)'
                            },
                        }}
                    />
                </div>
                <div className={SearchComponentCSS['item-section']}>
                    <SignupLocation setLocation={setLocation} location={location} isToggled={true}/>
                </div>
                <div className={`${SearchComponentCSS['item-section']} ${SearchComponentCSS['distance']}`}>
                    <h4>Max Distance (km)</h4>
                    <Slider
                        getAriaLabel={() => 'Max price'}
                        value={distance}
                        onChange={handleDistanceChange}
                        valueLabelDisplay='on'
                        getAriaValueText={valuetext}
                        sx={{
                            height: 8,
                            '& .MuiSlider-thumb': {
                                color: '#fff',
                                width: '25px',
                                height: '25px',
                            },
                            '& .MuiSlider-track': {
                                color: '#F92E85',
                            },
                            '& .MuiSlider-rail': {
                                color: '#b6b6b6',
                            },
                            '& .MuiSlider-valueLabel': {
                                bgcolor: 'transparent',
                                color: '#000',
                                mt: '16px',
                                fontSize: '12px',
                                paddingBottom: '5px'
                            },
                            '& .MuiSlider-thumb.Mui-active': {
                                boxShadow: '0px 0px 0px 14px rgba(249, 46, 133, 0.16)'
                            },
                        }}
                    />
                </div>
                    <div className={SearchComponentCSS['button-container']}>
                        <button className={SearchComponentCSS['search-button']} type='submit'>
                            <FontAwesomeIcon icon={faSearch}/>
                        </button>
                        <button
                            className={SearchComponentCSS['filter-button']}
                            disabled={!isButtonDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                resetSearchFilters();
                            }}
                        >
                            <RestartAltIcon/>
                        </button>
                    </div>
            </form>
        </div>
)

return content
}

export default SearchComponent