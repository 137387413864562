import ProfileShowCSS from './ProfileShow.module.css'
import Person2Icon from '@mui/icons-material/Person2'
import EditUserProfileCSS from '../../features/user/EditUserProfile.module.css'
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded'
import {Link} from 'react-router-dom'
import SmsIcon from '@mui/icons-material/Sms'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CheckIcon from '@mui/icons-material/Check'
import FavoriteIcon from '@mui/icons-material/Favorite'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DisplayTimes from '../EditTimes/DisplayTimes'
import PaymentsIcon from '@mui/icons-material/Payments'
import DisplayPrices from '../EditTimes/DisplayPrices'
import LoginRedirection from '../UserStories/LoginRedirection'
import ReportUser from './ReportUser'
import React from 'react'

const ProfileInfoSection = ({loggedUser, paramUser, redirectOpened, setRedirectOpened, reportOpened, setReportOpened, follower}) => {
    return (
        <div className={ProfileShowCSS['profile-container']}>
            <div className={ProfileShowCSS['additional-info']}>
                <div className={ProfileShowCSS['item-section']}>
                    <div className={ProfileShowCSS['item-heading']}>
                        <Person2Icon className={ProfileShowCSS['icon']}/>
                        <p>Profile type</p>
                    </div>
                    <ul className={EditUserProfileCSS['select-list']}>
                        {paramUser?.profileType?.map((item, index) => (
                            <li
                                key={index}
                                className={ProfileShowCSS['colored-items']}
                            >
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
                {(paramUser?.club !== '' || paramUser?.website !== '') && !paramUser?.profileType.includes('Independent') ?
                    <div className={ProfileShowCSS['item-section']}>
                        <div className={ProfileShowCSS['item-heading']}>
                            <RadioButtonCheckedRoundedIcon className={ProfileShowCSS['icon']}/>
                            <p>Partner</p>
                        </div>
                        <span className={ProfileShowCSS['item-text']}>{paramUser?.club}</span>
                        <Link to={paramUser?.website}><span
                            className={`${ProfileShowCSS['item-text']} ${ProfileShowCSS['website-link']}`}>{paramUser?.website}</span></Link>
                    </div> : null}
                <div className={ProfileShowCSS['item-section']}>
                    <div className={ProfileShowCSS['item-heading']}>
                        <Person2Icon className={ProfileShowCSS['icon']}/>
                        <p>Gender</p>
                    </div>
                    <span className={ProfileShowCSS['item-text']}>{paramUser?.gender}</span>
                </div>
                <div className={ProfileShowCSS['item-section']}>
                    <div className={ProfileShowCSS['item-heading']}>
                        <SmsIcon className={ProfileShowCSS['icon']}/>
                        <p>Language</p>
                    </div>
                    <ul className={EditUserProfileCSS['select-list']}>
                        {paramUser?.language?.map((item, index) => (
                            <li
                                key={index}
                                className={ProfileShowCSS['colored-items']}
                            >
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={ProfileShowCSS['item-section']}>
                    <div className={ProfileShowCSS['item-heading']}>
                        <MoreHorizIcon className={ProfileShowCSS['icon']}/>
                        <p>Description</p>
                    </div>
                    <span className={ProfileShowCSS['item-text']}>{paramUser?.description}</span>
                </div>
                <div className={ProfileShowCSS['item-section']}>
                    <div className={ProfileShowCSS['item-heading']}>
                        <CheckIcon className={ProfileShowCSS['icon']}/>
                        <p>Attributes</p>
                    </div>
                    <div className={ProfileShowCSS['preferences-container']}>
                        <ul className={EditUserProfileCSS['select-list']}>
                            {paramUser?.attributes?.map((item, index) => (
                                <li
                                    key={index}
                                    className={ProfileShowCSS['colored-items']}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={ProfileShowCSS['item-section']}>
                    <div className={ProfileShowCSS['item-heading']}>
                        <FavoriteIcon className={ProfileShowCSS['icon']}/>
                        <p>Services</p>
                    </div>
                    <div className={ProfileShowCSS['preferences-container']}>
                        <ul className={EditUserProfileCSS['select-list']}>
                            {paramUser?.services?.map((item, index) => (
                                <li
                                    key={index}
                                    className={ProfileShowCSS['colored-items']}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {paramUser?.meetings ? (
                    <div className={ProfileShowCSS['item-section']}>
                        <div className={ProfileShowCSS['item-heading']}>
                            <AccessTimeIcon className={ProfileShowCSS['icon']}/>
                            <p>Working hours</p>
                        </div>
                        <div className={ProfileShowCSS['opening-times']}>
                            <DisplayTimes times={paramUser?.availability}/>
                        </div>
                    </div>
                ) : null}
                {paramUser?.meetings ? (
                        <div className={ProfileShowCSS['item-section']}>
                            <div className={ProfileShowCSS['item-heading']}>
                                <PaymentsIcon className={ProfileShowCSS['icon']}/>
                                <p>Pricing</p>
                            </div>
                            <div className={ProfileShowCSS['prices']}>
                                <DisplayPrices timeAndPrice={paramUser?.prices}/>
                            </div>
                        </div>
                    ) :
                    <div className={`${ProfileShowCSS['item-section']} ${ProfileShowCSS['user-note']}`}>
                        <span className={ProfileShowCSS['item-text']}>This user is not open for meetings.</span>
                    </div>
                }
            </div>
            <LoginRedirection
                redirectOpened={redirectOpened}
                closeModal={() => setRedirectOpened(false)}
            />
            <ReportUser
                reporterId={loggedUser?.id}
                reportedUserId={follower}
                reportOpened={reportOpened}
                closeModal={() => setReportOpened(false)}
                reporterEmail={loggedUser?.email}
            />
        </div>
    )
}

export default ProfileInfoSection