import Modal from 'react-modal'
import ProfileShowCSS from "./ProfileShow.module.css"
import {useNavigate} from 'react-router-dom'
import React, {useEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faFileCirclePlus, faXmark} from '@fortawesome/free-solid-svg-icons'
import {useReportUserMutation} from "../../features/user/usersApiSlice";
import {useUploadReportMediaMutation} from '../../features/story/imageApiSLice'
import PulseLoader from 'react-spinners/PulseLoader'
import CaptureDocumentCSS from '../CaptureDocument/CaptureDocument.module.css'

Modal.setAppElement('#root')

const ReportUser = ({reporterId, reportedUserId, reportOpened, closeModal, reporterEmail}) => {

    const [uploadReportImage,{
        isSuccess: isUploadSuccess,
        isLoading: isUploadLoading,
        isError: isUploadError,
        error: uploadingError
    }] = useUploadReportMediaMutation()

    const [reportUser, {
        isSuccess: isReportSuccess,
        isLoading: isReportLoading,
        isError: iseReportError,
        error: reportError
    }] = useReportUserMutation()

    const [reportText, setReportText] = useState('')
    const [canSend, setCanSend] = useState(false)
    const [isSent, setIsSent] = useState(false)
    const [images, setImages] = useState([])
    const fileInputRef = useRef()
    const [uploadError, setUploadError] = useState('')

    useEffect(() => {
        if(reportText.length > 5) setCanSend(true)
        else setCanSend(false)
    }, [reportText])

    useEffect(() => {
        if (isSent) {
            const timer = setTimeout(() => {
                closeModal()

                const resetTimer = setTimeout(() => {
                    setIsSent(false)
                    setReportText('')
                    setImages([])
                }, 500)

                return () => clearTimeout(resetTimer)
            }, 1500)

            return () => clearTimeout(timer)
        }
    }, [isSent])

    const handleFileInputClick = () => {
        fileInputRef?.current?.click()
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImages([file, ...images])
        }
    }

    const handleUploadImage = async (img) => {
        const formData = new FormData()
        formData.append('file', images[img])
        formData.append('email', reporterEmail)
        formData.append('userId', reporterId)

        try {
            const result = await uploadReportImage(formData)
            return result.data.fileUrl
        } catch (error) {
            setUploadError('Error uploading image: ' + error.message)
            throw error
        }
    }

    const handleReportUser = async () => {
        try {
            let image1 = null
            let image2 = null
            let image3 = null

            if(images[0]) image1 = await handleUploadImage(0)
            if(images[1]) image2 = await handleUploadImage(1)
            if(images[2]) image3 = await handleUploadImage(2)
            await reportUser({reporterId, reportedUserId, reportReason: reportText, image1, image2, image3});
            setIsSent(true)
            console.log('Report submitted successfully');
        } catch (error) {
            console.error('Failed to submit report:', error);
        }
    }

    const removeScreenshot = (index) => {
        setImages(images.filter((_, i) => i !== index));
    }

    return (
        <Modal
            isOpen={reportOpened}
            onRequestClose={closeModal}
            className={
                {
                    base: `modal-content ${ProfileShowCSS['report-modal']}`,
                    afterOpen: 'modal-content--after-open',
                    beforeClose: 'modal-content--before-close'
                }
            }
            overlayClassName={
                {
                    base: 'modal-overlay',
                    afterOpen: 'modal-overlay--after-open',
                    beforeClose: 'modal-overlay--before-close'
                }
            }
            closeTimeoutMS={300}
        >
            <div className='modal-close'>
                <button
                    onClick={closeModal}
                    className='close-button'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path
                            d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/>
                    </svg>
                </button>
            </div>
            <h4>Please specify the reason for reporting this user:</h4>
            <textarea
                disabled={isSent}
                rows='5'
                value={reportText}
                onChange={(e) => setReportText(e.target.value)}
                maxLength='1000'
            />
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{display: 'none'}}
                accept="image/*"
            />
            {images?.map((image, index) => (
                <div className={ProfileShowCSS['single-screenshot']} key={index}>
                    <p>{image?.name}</p>
                    <FontAwesomeIcon icon={faXmark} onClick={() => removeScreenshot(index)}/>
                </div>
            ))}
            <button
                className={ProfileShowCSS['attachment-button']}
                onClick={handleFileInputClick}
                disabled={images?.length > 2}
            >
                <FontAwesomeIcon icon={faFileCirclePlus}/>Add a screenshoot
            </button>
            <div className={ProfileShowCSS['report-button']}>
                {!isSent ? <button disabled={!canSend || (isReportLoading || isUploadLoading)} onClick={handleReportUser}>{isReportLoading || isUploadLoading ? <PulseLoader color='#fff' /> : 'Report'}</button> :
                    <button className={ProfileShowCSS['sent']}>Report sent <FontAwesomeIcon icon={faCheck}/></button>}
            </div>
            {uploadError && <p>{uploadError}</p>}
        </Modal>
    )
}

export default ReportUser