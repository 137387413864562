import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {selectPostById} from '../../../features/post/postsApiSlice'
import ContentSectionCSS from '../ContentSection.module.css'
import React, {useRef, useState} from 'react'
import {formatDate} from '../../../common/utils'
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDeletePostMutation} from "../../../features/post/postsApiSlice";
import {useUpdateRemoveUserPostsMutation} from "../../../features/user/usersApiSlice";
import fireIcon from '../../../assets/img/fire-icon.png'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'

const SinglePostView = ({ loggedUser }) => {

    const { postId } = useParams()
    const { state } = useLocation()
    const navigate = useNavigate()
    const videoRef = useRef()
    const [showPlayIcon, setShowPlayIcon] = useState(true)
    const [showPauseIcon, setShowPauseIcon] = useState(false)

    const handleGoBack = () => {
        navigate(-1)
    }

    const [deletePost, { isLoading }] = useUpdateRemoveUserPostsMutation()

    const post = useSelector(state => selectPostById(state, postId))
    const handleEdit = () => {
        navigate(`/dash/user/content-section/posts/${postId}/edit`, { state: { from: state.from } })

    }

    const handleVideoPlay = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play()
                setShowPlayIcon(true)
                setTimeout(() => setShowPlayIcon(false), 300) // Hide after 300ms
            } else {
                videoRef.current.pause()
                setShowPauseIcon(true)
                setTimeout(() => setShowPauseIcon(false), 300) // Hide after 300ms
            }
        }
    }

    const handleDelete = async () => {

        await deletePost({id: loggedUser._id,postId: postId}).unwrap()
        navigate(state.from)
    };

    let content

    content = (
        <div className={ContentSectionCSS['single-post']}>
            <div className={`${ContentSectionCSS['header']} ${ContentSectionCSS['single-post-header']}`}>
                <ArrowBackIosRoundedIcon onClick={handleGoBack}/>
                <img src={loggedUser?.profileImage} alt="user"/>
                <p>{loggedUser?.username}</p>
                <div className={ContentSectionCSS['post-actions']}>
                    <EditIcon onClick={handleEdit}/>
                    <DeleteIcon onClick={handleDelete}/>
                </div>
            </div>
            <div className={ContentSectionCSS['media-container']}>
                {post?.type === 'image' ?
                    <img src={post?.url} alt='gallery-placeholder'/>
                    : (
                        <video ref={videoRef} onClick={handleVideoPlay}>
                            <source src={post?.url}/>
                            Your browser does not support the video tag.
                        </video>
                    )}
                {post?.type !== 'image' &&
                    <PlayArrowRoundedIcon
                    className={`${ContentSectionCSS['play-icon']} ${showPlayIcon ? ContentSectionCSS['show'] : ContentSectionCSS['hide']}`}
                />}
                {post?.type !== 'image' &&
                <PauseRoundedIcon
                    className={`${ContentSectionCSS['pause-icon']} ${showPauseIcon ? ContentSectionCSS['show'] : ContentSectionCSS['hide']}`}
                />
                 }
            </div>
            <div className={ContentSectionCSS['text-content']}>
                <div>
                    <p>{post?.description ? post?.description : '.'}</p>
                    <span>{formatDate(post?.createdAt)}</span>
                </div>
                <div className={ContentSectionCSS['like-button']}>
                    <button className='blur-button blur-button-post'>
                        <img src={fireIcon} alt="Custom Fire Icon"/>
                        <span>{post?.likes?.length}</span>
                    </button>
                </div>
            </div>
        </div>
    )

    return content
}

export default SinglePostView