import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import AppsIcon from '@mui/icons-material/Apps'
import DiamondIcon from '@mui/icons-material/Diamond'
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import ProfileInfoSection from './ProfileInfoSection'
import LiveCamSection from './LiveCamSection'
import PremiumContentSection from './PremiumContent/PremiumContentSection'
import Subscription from './Subscription/Subscription'
import SwipeableViews from 'react-swipeable-views'
import {useSearchParams} from 'react-router-dom'

const theme = createTheme({
    palette: {
        primary: {
            main: '#000',
        },
    },
})

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box sx={{paddingBottom: '64px'}}>{children}</Box>
        </div>
    )
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function BasicTabs({loggedUser, paramUser, follower, redirectOpened, reportOpened, setRedirectOpened, setReportOpened}) {

    const [searchParams, setSearchParams] = useSearchParams()
    const initialTab = parseInt(searchParams.get('tab'), 10) || 0 // Default to first tab
    const [value, setValue] = React.useState(initialTab)

    const handleChange = (event, newValue) => {
        setValue(newValue)
        setSearchParams({ tab: newValue })
    }

    const handleChangeIndex = (index) => {
        setValue(index)
        setSearchParams({ tab: index })
    }

    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: '0', backgroundColor: '#ffffff', zIndex: '999' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    centered
                >
                    <Tab sx={{ p: 2 }} icon={<MenuIcon/>} {...a11yProps(0)} />
                    <Tab sx={{ p: 2 }} icon={<AppsIcon/>} {...a11yProps(1)} />
                    <Tab sx={{ p: 2 }} icon={<DiamondIcon/>} {...a11yProps(2)} />
                    <Tab sx={{ p: 2 }} icon={<VideoCameraFrontOutlinedIcon/>} {...a11yProps(3)} />
                </Tabs>
            </Box>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <CustomTabPanel value={value} index={0}>
                    <ProfileInfoSection
                        loggedUser={loggedUser}
                        paramUser={paramUser}
                        redirectOpened={redirectOpened}
                        setRedirectOpened={setRedirectOpened}
                        reportOpened={reportOpened}
                        setReportOpened={setReportOpened}
                        follower={follower}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Subscription paramUser={paramUser} loggedUser={loggedUser}/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <PremiumContentSection paramUser={paramUser} loggedUser={loggedUser}/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <LiveCamSection />
                </CustomTabPanel>
            </SwipeableViews>
        </Box>
        </ThemeProvider>
    )
}