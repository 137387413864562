import AuthCSS from './Auth.module.css'
import logo from '../../assets/img/watermark-pink.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import React, { useRef, useState, useEffect } from 'react'
import {useNavigate, Link, useLocation} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import usePersist from "../../hooks/usePersist"
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import RequestManagingAccountModal from './RequestManagingAccountModal'
import ForgotPassword from './ForgotPassword'

const Login = ({ showSuccessMessage, setShowSuccessMessage}) => {

    const userRef = useRef()
    const errRef = useRef()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()
    const [forgotPasswordOpened, setForgotPasswordOpened] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        if(state?.loggedout === true) {
            window.location.reload()
            navigate('/dash/login', { state: {} })
        }
    }, [state])

    useEffect(() => {

        const timer = setTimeout(() => {
            setShowSuccessMessage(false)
        }, 7000) // 5000ms = 5 seconds

        // Cleanup timer
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        userRef.current.focus()
        setPersist(true)
    }, [])

    useEffect(() => {
        setErrMsg('')
    }, [username, password])


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { accessToken } = await login({ username, password }).unwrap()
            dispatch(setCredentials({ accessToken }))
            setUsername('')
            setPassword('')
            localStorage.setItem('isEighteen', 'true')
            navigate('/dash/stories')
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response')
            } else if (err.status === 400) {
                if (err.data?.message.includes('Check your email for verification link')) {
                    setErrMsg('Check your email for verification link.');
                } else {
                    setErrMsg('Missing Username or Password');
                }
            } else if (err.status === 401) {
                setErrMsg('Unauthorized')
            } else {
                setErrMsg(err.data?.message)
            }
            if (errRef.current) {
                errRef.current.focus()
            }
        }
    }

    const handleUserInput = (e) => setUsername(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)

    const errClass = errMsg ? 'errmsg' : 'offscreen'

    if (isLoading) return <p>Loading...</p>

    const content = (
        <div className={AuthCSS['login-component']}>
            <div className={`container ${AuthCSS['login-container']}`}>
                <div className={AuthCSS['logo-container']}>
                    <img
                        src={logo}
                        style={{cursor: 'pointer'}}
                        alt="CamNextDoor"
                        onClick={() => navigate('/')}
                    />
                </div>
                <div className={AuthCSS['input-fields']}>
                    {showSuccessMessage ?
                        <p className={AuthCSS['successful-signup']}>You will receive verification email
                            soon!</p> : null}
                    <p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>
                    <input
                        type="text"
                        id="username"
                        placeholder="Username or E-Mail"
                        ref={userRef}
                        value={username}
                        onChange={handleUserInput}
                        autoComplete="off"
                        required
                    />
                    <div className={AuthCSS['pwd-container']}>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            placeholder="Password"
                            onChange={handlePwdInput}
                            value={password}
                            required
                        />
                        {showPassword
                            ? <FontAwesomeIcon icon={faEye} onClick={() => setShowPassword(!showPassword)}/>
                            : <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShowPassword(!showPassword)}/>
                        }
                    </div>
                    <a onClick={() => setForgotPasswordOpened(true)}>Forgot password?</a>
                </div>
                <div className={AuthCSS['login-buttons']}>
                    <button onClick={handleSubmit}><FontAwesomeIcon icon={faArrowRight}/>Log in</button>
                    <p className={AuthCSS['separator']}>or</p>
                    <Link to="/dash/signup">Sign up</Link>
                </div>
                <div className={AuthCSS['request-links']}>
                    <p className={AuthCSS['request-account']}
                       onClick={() => navigate('/dash/request-manager-account')}>Request managing account. <OpenInNewIcon/>
                    </p>
                    <p className={AuthCSS['separator']}>or</p>
                    <p className={AuthCSS['request-account']}
                       onClick={() => navigate('/dash/request-manager-account')}>Request advertisement account. <OpenInNewIcon/>
                    </p>
                </div>
            </div>
            <ForgotPassword forgotPasswordOpened={forgotPasswordOpened}
                            closeModal={() => setForgotPasswordOpened(false)}/>
        </div>
    )
    return content
}

export default Login