import UserVerificationCSS from './UserVerification.module.css'
import useAuth from '../../hooks/useAuth'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMobile} from '@fortawesome/free-solid-svg-icons'
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import {useNavigate} from "react-router-dom";

const UserVerification = () => {

    const {email} = useAuth()

    const navigate = useNavigate()

    const editedUsername = email?.split('@')[0]

    return(
        <div className={`component-container ${UserVerificationCSS['verify-container']} ${UserVerificationCSS['verify-me-container']}`}>
            <div className={UserVerificationCSS['main-section']}>
                <h1>Verify my Profile</h1>
                <p>One time setup fee of 100 CHF</p>
                <p>Free introductory meeting</p>
                <p>Free 30 days placement on CamNextDoor</p>
                <p>Our team will contact you within next 24 hours for the video shoot and the verification meeting.</p>
                <p className={UserVerificationCSS['pinked']}>You need to be over 18 years old and have a valid identification card.</p>
                <button onClick={() => navigate('/dash/documents', { state: { from: '/dash/verify' } })}>Verify for 100 CHF</button>
            </div>
            <div className={UserVerificationCSS['button-section']}>
                <h1 className={UserVerificationCSS['pinked']}>Contact us:</h1>
                <div className={UserVerificationCSS['contact-buttons']}>
                    <button><FontAwesomeIcon icon={faMobile}/></button>
                    <button><FontAwesomeIcon icon={faWhatsapp}/></button>
                </div>
                <p className={UserVerificationCSS['pinked']}>Phone: +41 78 700 44 78</p>
                <p className={UserVerificationCSS['pinked']}>Email: verification@camnextdoor.ch</p>
            </div>
        </div>
    )
}

export default UserVerification