import UserFollowingCSS from './UserFollowing.module.css'
import React, {useEffect, useState} from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import Provider from './Provider'

const SearchResults = ({loggedUser, searchResults, setSearchResults}) => {

    const [providers, setProviders] = useState([])

    useEffect(() => {
        console.log(searchResults)
        setProviders(searchResults)
    }, [loggedUser, searchResults])


    let listContent = providers?.length
        ? providers
            .map(providerId => <Provider key={providerId} providerId={providerId} loggedUser={loggedUser} from='/dash/search-results'/>)
        : null

        return (
            <div className={`component-container ${UserFollowingCSS['following-container']}`}>
                <h1>Search Results</h1>
                <div className={UserFollowingCSS['providers-section']}>
                    {listContent}
                </div>
            </div>
        )
        //return <PulseLoader color={'#F92E85FF'} className='pulse-loader'/>

}

export default SearchResults