import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContentSectionCSS from '../ContentSection.module.css';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useUpdatePostMutation, selectPostById } from '../../../features/post/postsApiSlice';
import PulseLoader from 'react-spinners/PulseLoader';

const EditPost = () => {
    const { postId } = useParams();
    const navigate = useNavigate();
    const post = useSelector((state) => selectPostById(state, postId));

    const [description, setDescription] = useState(post?.description || '');
    const [updatePost, { isLoading }] = useUpdatePostMutation();

    useEffect(() => {
        if (post) {
            setDescription(post.description || '');
        }
    }, [post]);

    const handlePublish = async () => {
        try {
            const postData = {
                id: post.id,
                description,
            };

            await updatePost(postData).unwrap();
            navigate(`/dash/user/content-section`);
        } catch (error) {
            console.error('Failed to update description:', error);
        }
    };

    return (
        <div className={ContentSectionCSS['edit-container']}>
            <CloseOutlinedIcon  />
            <div className={ContentSectionCSS['description-section']}>
                <h3>Description</h3>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder='Description'
                    maxLength='300'
                    rows='6'
                />
            </div>
            <button
                className={ContentSectionCSS['publish']}
                onClick={handlePublish}
                disabled={isLoading}
            >
                {isLoading ? (
                    <PulseLoader color={'#ffffff'} />
                ) : (
                    'Update Description'
                )}
            </button>
        </div>
    );
};

export default EditPost;
