import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const subscriptionsAdapter = createEntityAdapter({})

const initialState = subscriptionsAdapter.getInitialState()

export const subscriptionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSubscriptions: builder.query({
            query: () => ({
                url: '/subscriptions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedSubscriptions = responseData.map(subscription => {
                    subscription.id = subscription._id
                    return subscription
                });
                return subscriptionsAdapter.setAll(initialState, loadedSubscriptions)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Subscription', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Subscription', id }))
                    ]
                } else return [{ type: 'Subscription', id: 'LIST' }]
            }
        }),
        addNewSubscription: builder.mutation({
            query: initialSubscriptionData => ({
                url: '/subscriptions',
                method: 'POST',
                body: {
                    ...initialSubscriptionData,
                }
            }),
            invalidatesTags: [
                { type: 'Subscription', id: "LIST" }
            ]
        }),
        checkSubscription: builder.mutation({
            query: initialSubscriptionData => ({
                url: '/subscriptions/check',
                method: 'PATCH',
                body: {
                    ...initialSubscriptionData,
                }
            }),
            invalidatesTags: [
                { type: 'Subscription', id: "LIST" }
            ]
        }),
        deleteExpiredSubscriptions: builder.mutation({
            query: () => ({
                url: '/subscriptions/cleanup',
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'Subscription', id: "LIST" }
            ]
        }),
        getUserSubscriptions: builder.query({
            query: (userId) => ({
                url: `/subscriptions/user/${userId}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedSubscriptions = responseData.map(subscription => {
                    subscription.id = subscription._id
                    return subscription
                });
                return subscriptionsAdapter.setAll(initialState, loadedSubscriptions)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Subscription', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Subscription', id }))
                    ]
                } else return [{ type: 'Subscription', id: 'LIST' }]
            }
        }),
    }),
})

export const {
    useGetSubscriptionsQuery,
    useAddNewSubscriptionMutation,
    useDeleteExpiredSubscriptionsMutation,
    useGetUserSubscriptionsQuery,
    useCheckSubscriptionMutation
} = subscriptionsApiSlice

// returns the query result object
export const selectSubscriptionsResult = subscriptionsApiSlice.endpoints.getSubscriptions.select()

// creates memoized selector
const selectSubscriptionsData = createSelector(
    selectSubscriptionsResult,
    subscriptionsResult => subscriptionsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllSubscriptions,
    selectById: selectSubscriptionById,
    selectIds: selectSubscriptionIds
    // Pass in a selector that returns the subscriptions slice of state
} = subscriptionsAdapter.getSelectors(state => selectSubscriptionsData(state) ?? initialState)
