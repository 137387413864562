import InfoPage from './InfoPage'
import useTitle from '../../hooks/useTitle'

const Public = () => {

    useTitle('CamNextDoor')

    const content = (
        <div className='public'>
            <InfoPage />
        </div>
    )

    return content
}

export default Public