import { Outlet } from 'react-router-dom'
import DashNav from './DashNav'
import DashNavManager from './DashNavManager'
import useAuth from '../hooks/useAuth'
import {useEffect} from 'react'

const DashLayout = ({email, loggedUser}) => {

    const { isManager } = useAuth()

    const isEighteen = localStorage.getItem('isEighteen')
    return (
        <>
                <Outlet />
            {isEighteen === 'true' &&
                !isManager ? <DashNav email={email} loggedUser={loggedUser}/> : <DashNavManager />
            }
        </>
    )
}
export default DashLayout