import React, { useEffect, useRef, useState } from 'react'
import ContentSectionCSS from '../ContentSection.module.css'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { useAddNewPostMutation} from "../../../features/post/postsApiSlice"
import PulseLoader from 'react-spinners/PulseLoader'
import {useUploadPostBlurMutation, useUploadPostMutation} from "../../../features/story/imageApiSLice"
import {useUpdateUserPostsMutation} from "../../../features/user/usersApiSlice"

const imageTypes = ['image/jpeg', 'image/jpg', 'image/png']
const videoTypes = ['video/mp4', 'video/webm', 'video/ogg']

const AddNewPost = ({ setAddNewActive, loggedUser }) => {
    const [fileSrc, setFileSrc] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const [fileType, setFileType] = useState('')
    const [canPublish, setCanPublish] = useState(false)
    const [description, setDescription] = useState('')
    const cameraInputRef = useRef(null)
    const galleryInputRef = useRef(null)
    const [addNewPost, { isLoading: isLoadingNewPost }] = useAddNewPostMutation()
    
    const [uploadImageToS3,{
        isSuccess: isUploadSuccess,
        isLoading: isUploadLoading,
        isError: isUploadError,
        error: uploadingError
    }] = useUploadPostMutation()

    const [uploadImageBlurToS3,{
    }] = useUploadPostBlurMutation()


    const [updateUser, {
        isLoading: isLoadingUpdateUser,
        isSuccess: isSuccessUpdateUser,
        isError: isErrorUpdateUser,
        error: errorUpdateUser
    }] = useUpdateUserPostsMutation()

    const handleFileUpload = (event) => {
        const file = event.target.files[0]
        setFileType(file.type)
        if (file) {
            setSelectedFile(file)
            const url = URL.createObjectURL(file)
            setFileSrc(url)
            setCanPublish(true)
        }
    }

    const removeSelectedFile = () => {
        setFileSrc('')
        setSelectedFile(null)
        setFileType('')
        setCanPublish(false)
    }

    useEffect(() => {
        if (selectedFile) setCanPublish(true)
    }, [selectedFile])

    const handleImageUpload = async () => {
        if (!selectedFile) {
            console.error('No file selected')
            return
        }

        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('email', loggedUser.email)
        formData.append('userId',loggedUser._id)
        formData.append('description','post')

        try {
            const result = await uploadImageToS3(formData).unwrap()
            const blurred_result = await uploadImageBlurToS3(formData).unwrap()

            return {url:
                result.fileUrl,
                blurredUrl : blurred_result.fileUrl}
        } catch (error) {
            console.error('Error uploading image:', error)
            throw error
        }
    }

    const handlePublish = async () => {
        if (!selectedFile || !canPublish) return

        try {
            const fileUrls = await handleImageUpload()
            const postData = {
                userId: loggedUser._id,
                description,
                url: fileUrls.url,
                blurredUrl : fileUrls.blurredUrl,
                type: fileType.startsWith('image/') ? 'image' : 'video',
            }

            const response =await addNewPost(postData).unwrap()
            console.log(response.id)
            await updateUser({id: loggedUser._id, postId: response.id})
            setAddNewActive(false)
        } catch (error) {
            console.error('Failed to publish post:', error)
        }
    }

    return (
        <div className={ContentSectionCSS['add-new-container']}>
            <CloseOutlinedIcon onClick={() => setAddNewActive(false)}/>
            <div
                className={`${ContentSectionCSS['upload-section']} ${selectedFile ? ContentSectionCSS['no-border'] : ''}`}>
                {selectedFile ? (
                    imageTypes.includes(fileType) ? (
                        <img src={fileSrc} alt="Selected" />
                    ) : (
                        <video controls autoPlay loop>
                            <source src={fileSrc} type={fileType} />
                            Your browser does not support the video tag.
                        </video>
                    )
                ) : (
                    <>
                        <CameraAltOutlinedIcon onClick={() => cameraInputRef.current.click()} />
                        <CollectionsOutlinedIcon onClick={() => galleryInputRef.current.click()} />
                    </>
                )}
                {selectedFile && (
                    <AutorenewRoundedIcon
                        className={ContentSectionCSS['remove-file']}
                        onClick={removeSelectedFile}
                    />
                )}
            </div>
            <div className={ContentSectionCSS['description-section']}>
                <h3>Description</h3>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Write here something about your media."
                    maxLength="300"
                    rows="3"
                />
            </div>
            <button className={ContentSectionCSS['publish']} onClick={handlePublish}
                    disabled={!canPublish || isUploadLoading || isLoadingNewPost}>
                {isUploadLoading || isLoadingNewPost ? (
                    <PulseLoader color={'#ffffff'}/>
                ) : (
                    'Publish Content'
                )}
            </button>
            <input
                type="file"
                accept="video/*,image/*"
                onChange={handleFileUpload}
                style={{display: 'none'}}
                ref={cameraInputRef}
                capture="camcorder"
            />
            <input
                type="file"
                accept="video/*,image/*"
                onChange={handleFileUpload}
                style={{display: 'none'}}
                ref={galleryInputRef}
            />
        </div>
    )
}

export default AddNewPost
