import AuthCSS from '../../features/auth/Auth.module.css'
import TimesAndPricesCSS from './TimesAndPrices.module.css'

const DisplayPrices = ({timeAndPrice}) => {

    if(!timeAndPrice) return <p>No prices available.</p>

    return (
        <div className={TimesAndPricesCSS['display-prices']}>
            <ul>
                {timeAndPrice.FirstPrice.duration !== '' ?
                    <li>
                        <div>
                            <h3>{timeAndPrice.FirstPrice.duration}</h3>
                            <p>{timeAndPrice.FirstPrice.unit}</p>
                        </div>
                        <div>
                            <h3>{timeAndPrice.FirstPrice.price}</h3>
                            <p>CHF</p>
                        </div>
                    </li> : null
                }
                {timeAndPrice.SecondPrice.duration !== '' ?
                    <li>
                        <div>
                            <h3>{timeAndPrice.SecondPrice.duration}</h3>
                            <p>{timeAndPrice.SecondPrice.unit}</p>
                        </div>
                        <div>
                            <h3>{timeAndPrice.SecondPrice.price}</h3>
                            <p>CHF</p>
                        </div>
                    </li> : null
                }
                {timeAndPrice.ThirdPrice.duration !== '' ?
                    <li>
                        <div>
                            <h3>{timeAndPrice.ThirdPrice.duration}</h3>
                            <p>{timeAndPrice.ThirdPrice.unit}</p>
                        </div>
                        <div>
                            <h3>{timeAndPrice.ThirdPrice.price}</h3>
                            <p>CHF</p>
                        </div>
                    </li> : null
                }
                {timeAndPrice.FourthPrice.duration !== '' ?
                    <li>
                        <div>
                            <h3>{timeAndPrice.FourthPrice.duration}</h3>
                            <p>{timeAndPrice.FourthPrice.unit}</p>
                        </div>
                        <div>
                            <h3>{timeAndPrice.FourthPrice.price}</h3>
                            <p>CHF</p>
                        </div>
                    </li> : null
                }
                {timeAndPrice.FifthPrice.duration !== '' ?
                    <li>
                        <div>
                            <h3>{timeAndPrice.FifthPrice.duration}</h3>
                            <p>{timeAndPrice.FifthPrice.unit}</p>
                        </div>
                        <div>
                            <h3>{timeAndPrice.FifthPrice.price}</h3>
                            <p>CHF</p>
                        </div>
                    </li> : null
                }
            </ul>
        </div>
    )
}

export default DisplayPrices