import Modal from 'react-modal'
import BroadcasterSettingsCSS from "./BroadcasterSettings.module.css"
import {useNavigate} from 'react-router-dom'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import {useChangePasswordMutation} from "../../features/user/usersApiSlice";
import PulseLoader from "react-spinners/PulseLoader";

Modal.setAppElement('#root')

const PasswordReset = ({userId, passwordResetOpened, closeModal}) => {

    const [changePassword, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useChangePasswordMutation()

    const navigate = useNavigate()

    const errRef = useRef()
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [errMsg, setErrMsg] = useState('')

    const handleResetPassword = async () => {
        try {
            const response = await changePassword({userId, oldPassword: currentPassword, newPassword, confirmNewPassword}).unwrap()
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response')
            } else if (err.status === 400) {
                if (err.data?.message.includes('All fields are required')) {
                    setErrMsg('All fields are required');
                } else if (err.data?.message.includes('New passwords do not match')){
                    setErrMsg('New passwords do not match');
                }
            } else if (err.status === 403) {
                setErrMsg('Old password is incorrect')
                console.log('old pass')
            } else {
                setErrMsg(err.data?.message)
            }
            if (errRef.current) {
                errRef.current.focus()
            }
        }
    }

    useEffect(() => {
        setErrMsg('')
    }, [currentPassword, newPassword, confirmNewPassword])

    const errClass = errMsg ? 'errmsg' : 'offscreen'

    return(
        <Modal
            isOpen={passwordResetOpened}
            onRequestClose={closeModal}
            className={
                {
                    base: `modal-content ${BroadcasterSettingsCSS['modal-content']}`,
                    afterOpen: 'modal-content--after-open',
                    beforeClose: 'modal-content--before-close'
                }
            }
            overlayClassName={
                {
                    base: 'modal-overlay',
                    afterOpen: 'modal-overlay--after-open',
                    beforeClose: 'modal-overlay--before-close'
                }
            }
            closeTimeoutMS={300}
        >
            <div className={`modal-close ${BroadcasterSettingsCSS['modal-close']}`}>
                <button
                    onClick={closeModal}
                    className='close-button'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path
                            d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/>
                    </svg>
                </button>
            </div>
            <h4>Password change</h4>
            <div className={BroadcasterSettingsCSS['password-container']}>
                <p>Enter your old password</p>
                <div className={BroadcasterSettingsCSS['pwd-container']}>
                    <input
                        className={BroadcasterSettingsCSS['password-field']}
                        type={showPassword ? 'text' : 'password'}
                        id='password'
                        placeholder='Password'
                        autoComplete='off'
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                    {showPassword
                        ? <FontAwesomeIcon icon={faEye} onClick={() => setShowPassword(!showPassword)}/>
                        :
                        <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShowPassword(!showPassword)}/>
                    }
                </div>
                <p>Enter your new password</p>
                <div className={BroadcasterSettingsCSS['pwd-container']}>
                    <input
                        className={BroadcasterSettingsCSS['password-field']}
                        type={showNewPassword ? 'text' : 'password'}
                        id='password'
                        placeholder='Password'
                        autoComplete='off'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    {showNewPassword
                        ? <FontAwesomeIcon icon={faEye} onClick={() => setShowNewPassword(!showNewPassword)}/>
                        :
                        <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShowNewPassword(!showNewPassword)}/>
                    }
                </div>
                <div className={BroadcasterSettingsCSS['pwd-container']}>
                    <input
                        className={BroadcasterSettingsCSS['password-field']}
                        type={showNewPassword ? 'text' : 'password'}
                        id='password'
                        placeholder='Confirm password'
                        autoComplete='off'
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required
                    />
                    {showNewPassword
                        ? <FontAwesomeIcon icon={faEye} onClick={() => setShowNewPassword(!showNewPassword)}/>
                        :
                        <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShowNewPassword(!showNewPassword)}/>
                    }
                </div>
            </div>
            <p ref={errRef} className={errClass} aria-live='assertive'>{errMsg}</p>
            {isSuccess ? <p className={BroadcasterSettingsCSS['success-message']}>You've successfully changed your password!</p> : null}
            <div className={BroadcasterSettingsCSS['support-button']}>
                {!isLoading ? <button onClick={() => handleResetPassword()}>Reset my password</button> : <PulseLoader color={'#F92E85FF'} />}
            </div>
        </Modal>
    )
}

export default PasswordReset