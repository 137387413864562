import { apiSlice } from '../../app/api/apiSlice'

const uploadImageToS3 = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        uploadImage: builder.mutation({
            query: (file) => ({
                url: '/upload',
                method: 'POST',
                body: file,
            }),
            transformResponse: (responseData) => {
                return { fileUrl: responseData.fileUrl }
            },
        }),
        uploadImageForVerification: builder.mutation({
            query: (file) => ({
                url: '/upload/verify',
                method: 'POST',
                body: file,
            }),
            transformResponse: (responseData) => {
                return { fileUrl: responseData.fileUrl }
            },
        }),
        uploadPost: builder.mutation({
            query: (file) => ({
                url: '/upload/posts',
                method: 'POST',
                body: file,
            }),
            transformResponse: (responseData) => {
                return { fileUrl: responseData.fileUrl }
            },
        }),
        uploadPostBlur: builder.mutation({
            query: (file) => ({
                url: '/upload/posts-blur',
                method: 'POST',
                body: file,
            }),
            transformResponse: (responseData) => {
                return { fileUrl: responseData.fileUrl }
            },
        }),
        uploadDocumentsForVerification: builder.mutation({
            query: (file) => ({
                url: '/upload/documents',
                method: 'POST',
                body: file,
            }),
            transformResponse: (responseData) => {
                return { fileUrl: responseData.fileUrl }
            },
        }),
        uploadReportMedia: builder.mutation({
            query: (file) => ({
                url: '/upload/report-media',
                method: 'POST',
                body: file,
            }),
            transformResponse: (responseData) => {
                return { fileUrl: responseData.fileUrl }
            },
        })
    }),
})

export const { useUploadImageMutation, useUploadImageForVerificationMutation, useUploadDocumentsForVerificationMutation, useUploadReportMediaMutation, useUploadPostMutation, useUploadPostBlurMutation} = uploadImageToS3
