import UserFollowingCSS from '../UserProfile/UserFollowing.module.css'

const Subscriptions = () => {

    // const [providers, setProviders] = useState([])
    // const navigate = useNavigate()
    //
    // useEffect(() => {
    //     setProviders(loggedUser?.following)
    // }, [loggedUser, searchResults])
    //
    //
    // let listContent = providers?.length
    //     ? providers
    //         .map(providerId => <Provider key={providerId} providerId={providerId} loggedUser={loggedUser} from='/dash/user/following'/>)
    //     : <p className={UserFollowingCSS['no-followers']}>You're not following anyone yet.</p>

    return (
        <div className={`component-container ${UserFollowingCSS['following-container']}`}>
            <h1>Subscriptions</h1>
            <div className={UserFollowingCSS['providers-section']}>
                <h4>You are not subscribed to anyone yet.</h4>
            </div>
        </div>
    )

}

export default Subscriptions