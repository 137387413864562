import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserByUsername } from '../../features/user/usersApiSlice'

const UserRedirect = () => {
    const { username } = useParams();
    const navigate = useNavigate();

    const user = useSelector(state => selectUserByUsername(state, username));

    useEffect(() => {
        if (user) {
            navigate(`/dash/user/${user.id}`);
        }
    }, [user, navigate]);

    return null;
};

export default UserRedirect;
