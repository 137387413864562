import AgeConfirmationCSS from './AgeConfirmation.module.css'
import SearchLocation from '../HomePage/SearchLocation'
import {useLocation, useNavigate} from 'react-router-dom'
import React, {useState} from 'react'
import HomePageCSS from '../HomePage/HomePage.module.css'
import logo from '../../assets/img/watermark-pink.png'

const AgeConfirmation = () => {

    const [isDisabled, setIsDisabled] = useState(true)
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const onConfirmSubmit = (e) => {
        e.preventDefault()

        localStorage.setItem('isEighteen', 'true');
        navigate(pathname)
    }

    const setLocation = (location) => {
        localStorage.setItem('location', JSON.stringify(location))
    }

    return(
        <div className={AgeConfirmationCSS['container']}>
            <div className={HomePageCSS['logo-container']}>
                <img src={logo} alt='CamNextDoor Logo'/>
                <p>Makes your fantasies real</p>
            </div>
            <div className={AgeConfirmationCSS['section']}>
                <SearchLocation setIsDisabled={setIsDisabled} setLocation={setLocation}/>
                <button disabled={isDisabled} onClick={onConfirmSubmit}>I am over
                    18 <span>& accept terms of service</span></button>
            </div>
        </div>
    )
}

export default AgeConfirmation